import { setupBrandNameProperties, setupBrandScopeProperties, targetBrandConfig } from 'AppBranding';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getActivePlaylistShowButtonLocaleKey } from 'StudentDashboard';

import backCaret from 'images/back_caret.png';
import share from 'images/share.png';
import bookmarkChecked from 'images/bookmark_checked.png';
import bookmarkUnchecked from 'images/bookmark_unchecked.png';
import smartlyHeaderLogoDarkBlue from 'vectors/smartly_header_logo_dark_blue.svg';
import wordmarkWhite from 'vectors/wordmark_white.svg';
import playerExit from 'vectors/player_exit.svg';
import playerBackSmall from 'vectors/player_back_small.svg';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { MessagesButton } from 'Messaging';
import template from '../views/app_header.html';
import angularModule from './navigation_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.component('messagesButton', react2Angular(MessagesButton, [], '', true));

angularModule.directive('appHeader', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');
        const offlineModeManager = $injector.get('offlineModeManager');
        const ConfigFactory = $injector.get('ConfigFactory');
        const Cohort = $injector.get('Cohort');
        const $location = $injector.get('$location');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const isMobileMixin = $injector.get('isMobileMixin');

        return {
            restrict: 'E',
            scope: {
                currentUser: '<',
            },
            templateUrl,
            link(scope) {
                // having isMobile values on scope trigger re-render of the header
                isMobileMixin.onLink(scope);
                NavigationHelperMixin.onLink(scope);

                scope.backCaret = backCaret;
                scope.share = share;
                scope.bookmarkChecked = bookmarkChecked;
                scope.bookmarkUnchecked = bookmarkUnchecked;
                scope.smartlyHeaderLogoDarkBlue = smartlyHeaderLogoDarkBlue;
                scope.wordmarkWhite = wordmarkWhite;
                scope.playerExit = playerExit;
                scope.playerBackSmall = playerBackSmall;
                scope.$location = $location;

                scope.$watch('currentUser', () => {
                    scope.showButtonKey = getActivePlaylistShowButtonLocaleKey(scope.currentUser, Cohort);
                });

                const config = ConfigFactory.getSync();
                scope.targetBrandConfig = targetBrandConfig;
                setupBrandNameProperties($injector, scope, { config });
                setupBrandScopeProperties(
                    $injector,
                    scope,
                    [
                        'appHeaderStyleClasses',
                        'appHeaderLearnerLayoutLogo',
                        'appHeaderWordMarkSrc',
                        'appHeaderWordMarkStyles',
                        'appHeaderLessonLayoutLogo',
                    ],
                    { config },
                );

                scope.viewModel = AppHeaderViewModel;
                scope.offlineModeManager = offlineModeManager;
                scope.isMobileApp = $window.CORDOVA;

                // See setupBrandNameProperties for where brandNameAbbr gets added to the scope
                Object.defineProperty(scope, 'logoAlt', {
                    get() {
                        return `(${scope.brandNameAbbr})`;
                    },
                });

                scope.shareStream = () => {
                    ShareService.shareMobileApp(
                        SiteMetadata.contentDefaultShareInfo(scope.currentUser, scope.viewModel.viewedStream),
                    );
                };

                scope.toggleBookmark = stream => {
                    if (scope.currentUser) {
                        scope.currentUser.toggleBookmark(stream);
                    }
                };

                Object.defineProperty(scope, 'titlePrefix', {
                    get() {
                        if (scope.viewModel?.playerViewModel?.lesson?.test) return 'EXAM: ';
                        if (scope.viewModel?.playerViewModel?.lesson?.assessment) return 'SMARTCASE: ';

                        return '';
                    },
                });

                scope.appHeaderClasses = () => {
                    const classes = ['app-header', scope.viewModel.layout, ...scope.appHeaderStyleClasses];

                    // try/catch so we don't need a string of null checks
                    let hasUneditableLesson = false;
                    try {
                        hasUneditableLesson = scope.viewModel.playerViewModel.lesson.editable === false;
                        // eslint-disable-next-line no-empty
                    } catch (e) {}

                    if (hasUneditableLesson) {
                        classes.push('showing-uneditable-lesson');
                    }

                    if (scope.viewModel.hasTopMessage) {
                        classes.push('has-top-message');
                    }

                    return classes;
                };

                scope.toggleLanguageSwitcher = val => {
                    if (angular.isUndefined(val)) {
                        val = !scope.showLanguageSwitcher;
                    }

                    const playerViewModel = scope.viewModel.playerViewModel;
                    // Do not show language switcher in editor, as we could
                    // accidentally save the swapped text
                    const canShow =
                        scope.currentUser &&
                        scope.currentUser.canViewLanguageSwitcher &&
                        playerViewModel &&
                        playerViewModel.lesson.locale !== 'en' &&
                        !playerViewModel.editorMode &&
                        !playerViewModel.previewMode;

                    if (!canShow) {
                        scope.showLanguageSwitcher = false;
                    } else {
                        scope.showLanguageSwitcher = val;
                    }
                };

                scope.handleMobileSettingsMenuClick = () => {
                    if ($location.path() === '/settings/mobile') {
                        $rootScope.back(false);
                    } else if (!scope.offlineModeManager.inOfflineMode) {
                        scope.loadRoute('/settings/mobile');
                    }
                };

                // perform scroll cleanup on destroy
                scope.$on('$destroy', scope.cleanupScroll);

                scope.$watch(
                    () => $location.path?.(),
                    () => {
                        scope.shouldShowTopMessage = $location.path?.()?.includes('/dashboard');
                    },
                );
            },
        };
    },
]);
