import { useRequiredCurrentUser } from 'FrontRoyalAngular';
import { useSyncConfig } from 'FrontRoyalConfig';
// FIXME: uninstall livekit-server-sdk. remove unnecessary stuff from app config
import { useCallback, useEffect, useState } from 'react';
import { liveKitApi } from './livekitApi';
import { type TokenAttributes, type ConnectionDetails } from './Livekit.types';

// Right now, we're requesting a new token each time the "Start a Conversation" button is clicked. It's unclear
// to me if that's necessary. I used to reuse the same token, but when I did that the agent didn't start talking
// right away on the second click, so I thought maybe it wasn't creating a new room and we wanted it to. I swtiched
// to requesting a new token each time, but I'm getting the same token again, the same room id. So it didn't seem
// to change anything. But then the agent started talking each time.
export function useConnectionDetails({ tokenAttributes }: { tokenAttributes: TokenAttributes }) {
    const [fetchToken, { isLoading: isLoadingToken, data: token }] = liveKitApi.useGetTokenMutation({});
    const [connectionDetailsRequested, setConnectionDetailsRequested] = useState<boolean>(false);
    const config = useSyncConfig();
    const serverUrl = config.liveKitServerUrl();
    const currentUser = useRequiredCurrentUser();
    const [connectionDetails, setConnectionDetails] = useState<ConnectionDetails | null>(null);

    const unsetConnectionDetails = useCallback(() => {
        setConnectionDetailsRequested(false);
    }, [setConnectionDetailsRequested]);

    const initializeConnectionDetails = useCallback(() => {
        setConnectionDetailsRequested(true);
        if (isLoadingToken) return;
        fetchToken({ tokenAttributes });
    }, [isLoadingToken, fetchToken, tokenAttributes]);

    useEffect(() => {
        if (!token || !connectionDetailsRequested || isLoadingToken) {
            setConnectionDetails(null);
            return;
        }

        setConnectionDetails({
            serverUrl,
            participantName: currentUser.nickname || currentUser.name,
            participantToken: token,
        });
    }, [token, connectionDetailsRequested, serverUrl, currentUser.nickname, currentUser.name, isLoadingToken]);

    return [connectionDetails, unsetConnectionDetails, initializeConnectionDetails] as const;
}
