import { type Cohort } from 'Cohorts';
import { type Nullable } from '@Types';
import { getRegistrationDeadline, inEarlyAdmissionsRegistrationPeriod } from './AdmissionOffer';
import { type AdmissionOffer } from './AdmissionOffer.types';

export function showFastTrackRegistration(admissionOffer: Nullable<AdmissionOffer>, cohort: Nullable<Cohort>) {
    if (!admissionOffer || !cohort) return false;
    const isInEarlyAdmissionsRegistrationPeriod = inEarlyAdmissionsRegistrationPeriod(admissionOffer, cohort);
    const fastTrackRegistrationDeadlineTs = admissionOffer.fastTrackRegistrationDeadline;
    const fastTrackRegistrationDeadline = fastTrackRegistrationDeadlineTs
        ? 1000 * fastTrackRegistrationDeadlineTs
        : null;

    return (
        isInEarlyAdmissionsRegistrationPeriod &&
        fastTrackRegistrationDeadline &&
        (getRegistrationDeadline(admissionOffer, cohort) as number) < fastTrackRegistrationDeadline &&
        Date.now() < fastTrackRegistrationDeadline
    );
}
