import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import { useSelector, useDispatch } from 'react-redux';
import { type AnyObject } from '@Types';
import { type User } from '@sendbird/chat';
import { promiseCacheFactory } from 'PromiseCacheFactory';
import { useCallback } from 'react';
import { HiddenChannelFilter, type GroupChannelListQuery } from '@sendbird/chat/groupChannel';
import { useRequiredCurrentUser } from 'FrontRoyalAngular';
import { getUserChannelMap, getUsersFromUserChannelMap } from '../selectors';
import { setUserChannelMap } from '../actions';

const [promiseCache] = promiseCacheFactory();

const generateCacheKey = (userId: string) => `${userId}:useGetUserChannelMap_promise_key`;

// channelMap is an object where the key is a stringified array of OTHER users in a channel
// and the value is that channels URL we will build a record for each channel a user has
type ChannelMap = AnyObject<string>;

const appendToChannelMap = (
    userId: string,
    channelMap: ChannelMap,
    channels: Awaited<ReturnType<GroupChannelListQuery['next']>>,
) => {
    const updatedChannelMap = channels.reduce(
        (prev, curr) => ({
            ...prev,
            [JSON.stringify(
                curr.members
                    .filter(m => m.userId !== userId)
                    .map(m => m.userId)
                    .sort(),
            )]: curr.url,
        }),
        channelMap,
    );
    const users = channels.flatMap(c => c.members.filter(m => m.userId !== userId));

    return [updatedChannelMap, users] as const;
};

const recursivelyFetchChannels = ({
    userId,
    channelQuery,
    onComplete,
}: {
    userId: string;
    channelQuery: GroupChannelListQuery;
    onComplete: (channelMap: ChannelMap) => void;
}) => {
    const fetchAllChannels = (q: GroupChannelListQuery, u: User[] = [], channelMap: ChannelMap = {}) => {
        if (q.hasNext) {
            q.next()
                .then(channels => {
                    const [updatedChannelMap, users] = appendToChannelMap(userId, channelMap, channels);
                    setTimeout(() => {
                        fetchAllChannels(q, [...users, ...u], updatedChannelMap);
                    }, 75);
                })
                .catch(e => {
                    // catch errors that are do to requests being cancelled
                    if (e?.message && e.message.match?.('Request has been canceled.')) return;

                    throw e;
                });
        } else {
            onComplete(channelMap);
        }
    };
    fetchAllChannels(channelQuery);
};

const useCreateChannelMapPromise = (userId: string) => {
    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);
    const dispatch = useDispatch();

    const createSetUserChannelMapPromise = useCallback(() => {
        const channelQuery = sdk.groupChannel.createMyGroupChannelListQuery({
            hiddenChannelFilter: HiddenChannelFilter.ALL,
        });

        return new Promise(r => {
            recursivelyFetchChannels({
                userId,
                channelQuery,
                onComplete: channelMap => {
                    // Once all the channels are fetched, put them into the store and resolve the promise
                    // to stop suspending and re-render
                    dispatch(setUserChannelMap(channelMap || {}));
                    r(channelMap);
                },
            });
        });
    }, [sdk, dispatch, userId]);

    return createSetUserChannelMapPromise;
};

export const useGetUserChannelMap = () => {
    const { id } = useRequiredCurrentUser();
    const userChannelMap = useSelector(getUserChannelMap);
    const usersFromChannelMap = useSelector(state => getUsersFromUserChannelMap(state, id));
    const createSetUserChannelMapPromise = useCreateChannelMapPromise(id);

    // If we don't already have a userChannelMap in the store
    // start fetching it and trigger suspense until it's fetched.
    if (!userChannelMap) throw promiseCache(generateCacheKey(id), createSetUserChannelMapPromise());

    return [usersFromChannelMap, userChannelMap] as const;
};
