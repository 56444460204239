
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.field_options = window.Smartly.locales.modules.en.back_royal.careers.field_options || {};
window.Smartly.locales.modules.en.back_royal.careers.field_options = {...window.Smartly.locales.modules.en.back_royal.careers.field_options, ...{
            
    "other": "Other",

                                  
    "data_analysis": "Business Analysis / Intelligence",
    "consultant": "Management Consulting / Strategy",
    "data_science": "Data Science",       
    "design": "Design",
    "engineering": "Engineering",
    "finance": "Finance",
    "information_technology": "Information Technology (IT)",       
    "general_management": "Management / Operations",
    "marketing": "Marketing",
    "product_management": "Product Management",
    "research": "Research",
    "sales": "Sales / Business Development",
    "writing": "Communications / Writing",
    "medical_services": "Medical Services",
    "teaching_education": "Teaching / Education",
                        

                       
    "permanent": "Permanent",
    "part_time": "Part-Time",
    "contract": "Contract",
    "internship": "Internship",

                                     
    "ux_ui": "UX / UI",            
    "art_creative": "Architecture / Art",            
    "graphic_design": "Graphic Design",            
    "software_dev_front_end": "Software Development (front-end)",                 
    "software_dev_back_end": "Software Development (back-end)",                 
    "software_dev_full_stack": "Software Development (full-stack)",                 
    "qa": "QA",                 
    "devops": "DevOps",                 
    "hardware": "Electrical Engineering",                 
    "chemical": "Chemical Engineering",                 
    "civil": "Civil Engineering",                 
    "mechanical": "Mechanical Engineering",                 
    "aerospace": "Aerospace Engineering",                 
    "biomedical": "Biomedical Engineering",                 
    "corporate_banking": "Investment Banking",             
    "investment": "Investing",             
    "trading": "Trading",             
    "fpa_accounting_audit": "FP&A / Accounting / Control",             
    "wealth_management": "Wealth Management",             
    "risk_audit": "Risk / Audit",             
    "senior_management": "Senior Management",                      
    "operations": "Operations / Project Management",                                                
    "human_resources": "Human Resources / Talent Acquisition",                                               
    "legal": "Legal",                                               
    "logistics_supply_chain": "Logistics / Supply Chain",                      
    "administrative": "Administrative",                      
    "account_executive": "Account Executive",           
    "account_manager": "Account Manager",           
    "customer_success": "Customer Success",           
    "sales_engineer": "Sales Engineer",           
    "sales_development_rep": "Sales Development Representative",           

                 
    "consulting": "Consulting",
    "consumer_products": "Consumer Products",
    "media": "Media / Entertainment",
    "financial": "Financial Services",
    "health_care": "Health Care",
    "manufacturing": "Manufacturing",
    "nonprofit": "Nonprofit / Government",
    "retail": "Retail / Trading",
    "real_estate": "Real Estate",
    "technology": "Technology",
    "education": "Education",
    "energy": "Energy",
    "government": "Government",
    "military": "Military",
                        

                    
    "statistics": "Statistics",
    "finance_and_accounting": "Finance & Accounting",
    "strategy_and_innovation": "Strategy & Innovation",
    "entrepreneurship": "Entrepreneurship",
    "economics": "Economics",
    "operations_management": "Operations Management",
    "management_and_leadership": "Management and Leadership",
                            

                 
    "creative": "Creative",
    "competitive": "Competitive",
    "conscientious": "Conscientious",
    "loyal": "Loyal",
    "resilient": "Resilient",
    "optimistic": "Optimistic",
    "driven": "Driven",
    "flexible": "Flexible",
    "flexible_attribute": "Flexible",
    "balanced": "Balanced",
    "persuasive": "Persuasive",
    "compassionate": "Compassionate",
    "courageous": "Courageous",
    "motivating": "Motivating",
    "meticulous": "Meticulous",
    "high_energy": "High-energy",

                  
    "results": "Results / Winning",
    "helping_others": "Helping Others",
    "career_advancement": "Career Advancement",
    "innovation": "Innovation / Discovery",
    "fellowship": "Fellowship",
    "personal_growth": "Personal Growth",
    "respect": "Respect / Recognition",
    "security": "Security",

                
    "problem_solving": "Problem Solving",
    "leadership": "Leadership",
    "business_acumen": "Business Acumen",
    "professionalism": "Professionalism",
    "entrepreneurial_mindset": "Entrepreneurial",
    "relationship_building": "Relationship Building",
    "work_ethic": "Work Ethic",
    "design_aesthetic": "Design Aesthetic",
    "communication": "Communication",
    "resourcefulness": "Resourcefulness",

              
    "best_in_class": "Best in Class",
    "fast_paced": "Fast-Paced",
    "hierarchical": "Hierarchical",
    "merit_based": "Merit-based",
    "stable": "Stable / Established",
    "entrepreneurial": "Entrepreneurial",
    "results_oriented": "Results-oriented",
    "harmonious": "Harmonious",
    "flat_structured": "Flat-Structured",
    "mission_driven": "Mission-Driven",

              
    "bootstrapped": "Bootstrapped",
    "seed": "Seed",
    "series_a": "Series A",
    "series_b_plus": "Series B+",
    "public": "Public",
    "private": "Private",

                     
    "rather_not_specify": "Would rather not specify",
    "lesson_than_1_million": "< $1 million",
    "1_to_5_million": "$1-$5 million",
    "5_to_50_million": "$5-$50 million",
    "50_to_500_million": "$50-$500 million",
    "more_than_500_million": "> $500 million",

                     
    "1-24": "1-24",
    "25-99": "25-99",
    "100-499": "100-499",
    "500-4999": "500-4999",
    "5000_plus": "5000+",
    "500_plus": "500+",                                                                                      

                
                                    
    "hiring_manager": "Hiring Manager",
    "hr_or_internal_recruiter": "HR / Internal Recruiter",
    "contract_recruiter": "Contract Recruiter",
    "job_role_other": "Other",

                                                  
    "yes": "Yes",
    "no": "No",

           
    "american_indian_or_alaska": "American Indian or Alaska Native",
    "asian": "Asian",
    "african_american": "Black or African American",
    "latin_american": "Latin American or Hispanic",
    "arab": "Middle Eastern or North African",
    "native_hawaiian_or_other": "Native Hawaiian or Other Pacific Islander",
    "white": "White or Caucasian",

          
    "male": "Male",
    "female": "Female",
    "no_identify": "Prefer not to answer",
    "non_binary": "Non-binary",
    "self_describe": "Prefer to self-describe",

                
    "hispanic": "Yes",
    "not_hispanic": "No",

                                  
    "start_own_company": "To start my own company",
    "advance_in_current_company": "To advance in my current organization",
    "get_better_job": "To change organizations / career",
    "expand_business_knowledge": "To expand my business knowledge",
    "more_effective_leader": "To become a more effective leader",
    "complimentary_lessons": "I'm here for the complimentary lessons",
    "advance_in_career": "To advance in my career",
    "network_with_top_tier_professionals": "To network with top-tier professionals",

                                 
    "economist": "The Economist",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "friend": "Family / Friend",
    "search_engine": "Google Search",
    "radio": "Audio (Podcast, Radio)",
    "mba_studies": "MBAStudies.com",
    "wsj": "Wall Street Journal",
    "youtube": "YouTube",
    "linkedin": "LinkedIn",
    "tv_cnbc": "TV - CNBC",
    "tv_bloomberg": "TV - Bloomberg",
    "tv_cnn": "TV - CNN",
    "tv_smithsonian": "TV - Smithsonian",
    "stack_overflow_exchange": "Stack Overflow / Exchange",
    "poets_quants": "Poets & Quants",
    "bloomberg_businessweek": "Bloomberg Businessweek",
    "mit_technology_review": "MIT Technology Review",
    "smart_audio": "Smart Audio (Alexa, Siri, etc.)",
    "colleague_manager_hr": "Colleague / Manager / HR Dept.",
    "america_economia": "América Economia",
    "e_and_t": "E&T",
    "reddit": "Reddit",
    "guild_education": "Guild Education",
    "quantic_blog": "Quantic Blog / Article",
    "google_or_apple_app_store": "App Store",
    "tiktok": "TikTok",
    "linkedin_live_event": "LinkedIn Live Event",
    "company_ambassador": "Company Advocate",
    "ghin": "Global Health Impact Network",
    "tldr_newsletter": "TLDR Newsletter",

                            
    "flexible": "I'm flexible",
    "none": "None",
    "san_francisco": "San Francisco Bay Area",
    "new_york": "New York, NY",
    "seattle": "Seattle, WA",
    "denver": "Denver, CO",
    "boston": "Boston, MA",
    "los_angeles": "Los Angeles, CA",
    "san_diego": "San Diego, CA",
    "washington_dc": "Washington, DC",
    "chicago": "Chicago, IL",
    "austin": "Austin, TX",
    "dallas": "Dallas, TX",
    "london": "London",
    "raleigh_durham": "Raleigh/Durham, NC",
    "miami": "Miami, FL",
    "atlanta": "Atlanta, GA",
    "houston": "Houston, TX",
    "seoul": "Seoul, South Korea",
    "singapore": "Singapore",
    "dubai": "Dubai, UAE",
    "paris": "Paris, France",
    "berlin": "Berlin, Germany",
    "hong_kong": "Hong Kong",
    "beijing": "Beijing, China",
    "shanghai": "Shanghai, China",
    "tokyo": "Tokyo, Japan",

                       
    "0_1_years": "0-1 Years",
    "1_2_years": "1-2 Years",
    "2_4_years": "2-4 Years",
    "4_6_years": "4-6 Years",
    "6_plus_years": "6+ Years",

                                 
    "0_1": "Less than 2",
    "2_6": "2-6",
    "7_10": "7-10",
    "11_15": "11-15",
    "16_plus": "16+",

                   
    "senior_management_role": "Senior Management (VP / C-level)",
    "management_role": "Management",
    "professional_role": "Professional",
    "administrative_role": "Administrative",
    "skilled_labor_role": "Skilled Labor",
    "service_role": "Support / Service",
    "retail_role": "Retail Position",

                              
    "high_school": "High School Diploma",
    "associates": "Associate's Degree",
    "undergraduate_bachelor": "Undergraduate University / Bachelor's Degree",
    "masters": "Master's Degree",
    "doctorate": "Doctorate / PhD Degree"
}
}
    