import { type AnyObject } from '@Types';
import {
    type GatewayError,
    type NotLoggedInError,
    type NotPermittedError,
    type DisconnectedError,
    type RTKQueryError,
    type NotAcceptableError,
    type AbortError,
} from './ReduxHelpers.types';

export function isAbortError(error: unknown): error is AbortError {
    if (!error) return false;
    if ((error as AnyObject).name === 'AbortError') return true;
    if ((error as AnyObject).status === 'TIMEOUT_ERROR') return true;
    return false;
}

export function isRTKQueryError(error: unknown): error is RTKQueryError {
    if (!error) return false;
    if ((error as RTKQueryError).status == null) return false;
    if (typeof (error as RTKQueryError).endpointPath !== 'string') return false;

    return true;
}

// In all examples we've seen, it is reasonable to treat gateway errors the same as disconnected errors.
export function isRetriableNetworkError(error: unknown): boolean {
    return isDisconnectedError(error) || isGatewayError(error);
}

export function isAuthIssue(error: unknown): boolean {
    return [401, 403].includes((error as RTKQueryError)?.status);
}

// In the messy world of network requests, some errors are just gonna happen out there in the wild. We
// don't want to report these to Sentry, so we have a function that lets us identify them
export function isExpectedError(error: unknown): boolean {
    if (isAbortError(error)) return true;
    if (isRetriableNetworkError(error)) return true;
    if (isAuthIssue(error)) return true;
    return false;
}

export function isDisconnectedError(error: unknown): error is DisconnectedError {
    if (!isRTKQueryError(error)) return false;
    const disconnectedError = error as DisconnectedError;
    return disconnectedError?.status === 0;
}

export function isNotLoggedInError(error: unknown): error is NotLoggedInError {
    if (!isRTKQueryError(error)) return false;
    const notLoggedInError = error as NotLoggedInError;
    return notLoggedInError.status === 401 && notLoggedInError.data.not_logged_in;
}

export function isNotPermittedError(error: unknown): error is NotPermittedError {
    if (!isRTKQueryError(error)) return false;
    const notPermittedError = error as NotPermittedError;
    return notPermittedError.status === 401 && !notPermittedError.data.not_logged_in;
}

export function isGatewayError(error: unknown): error is GatewayError {
    if (!isRTKQueryError(error)) return false;
    const gatewayError = error as GatewayError;
    return gatewayError.status === 502 || gatewayError.status === 504;
}

export function isNotAcceptableError(error: unknown): error is NotAcceptableError {
    if (!isRTKQueryError(error)) return false;
    const notAcceptableError = error as NotAcceptableError;
    return notAcceptableError.status === 406;
}
