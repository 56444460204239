import 'oclazyload';
import 'angular-bind-html-compile';
import 'AdmissionsProcessModals/angularModule';
import 'AdmissionsGuidance/angularModule';
import 'AspectRatioEnforcer/angularModule';
import 'Authentication/angularModule';
import 'Capabilities/angularModule';
import 'ClickKey/angularModule';
import 'ClientConfig/angularModule';
import 'ClientStorage/angularModule';
import 'Cohorts/angularModule';
import 'ContentItem/angularModule';
import 'DeepExtend/angularModule';
import 'DialogModal/angularModule';
import 'DragAndDrop/angularModule';
import 'Dynamic-node/angularModule';
import 'EmptyToEndFilter/angularModule';
import 'ErrorLogging/angularModule';
import 'EventLogger/angularModule';
import 'FileTypeIcon/angularModule';
import 'FormatsText/angularModule';
import 'FrontRoyalApiErrorHandler/angularModule';
import 'FrontRoyalErrorBoundary/angularModule';
import 'FrontRoyalFooter/angularModule';
import 'FrontRoyalGestures';
import 'FrontRoyalLinkedIn/angularModule';
import 'FrontRoyalStore/angularModule';
import 'FrontRoyalTimer/angularModule';
import 'FrontRoyalWrapper/angularModule';
import 'guid/angularModule';
import 'HoursMinutesSecondsFilter/angularModule';
import 'ImageFadeInOnLoad/angularModule';
import 'Institutions/angularModule';
import 'IsMobile/angularModule';
import 'LearnerContentCache/angularModule';
import 'Navigation/angularModule';
import 'ngStorableImage/angularModule';
import 'NoApply/angularModule';
import 'OfflineMode/angularModule';
import 'Playlists/angularModule';
import 'Porter-stemmer/angularModule';
import 'Positionable/angularModule';
import 'RepeatComplete/angularModule';
import 'RouteAnimationHelper/angularModule';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'SessionTracker/angularModule';
import 'SoundManager/angularModule';
import 'TextToImage/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Translation/angularModule';
import 'Users/angularModule';
import 'VoiceCoursePractice/angularModule';
import 'ZoomContainer/angularModule';
import 'HelpScoutBeacon/angularModule';

export default angular
    .module('FrontRoyal.Lessons', [
        'AClassAbove',
        'AdmissionsGuidance',
        'AdmissionsProcessModals',
        'angular-bind-html-compile',
        'scrollHelper',
        'sessionTracker',
        'FrontRoyal.Linkedin',
        'RepeatComplete',
        'AspectRatioEnforcer',
        'Capabilities',
        'ClickKey',
        'ClientStorage',
        'DeepExtend',
        'DialogModal',
        'DragAndDrop',
        'EventLogger',
        'FormatsText',
        'FrontRoyal.Authentication',
        'FrontRoyal.Cohorts',
        'FrontRoyal.ContentItem',
        'FrontRoyal.ClientConfig',
        'FrontRoyal.EntityMetadata',
        'FrontRoyal.ErrorBoundary',
        'FrontRoyal.GlobalMetadata',
        'FrontRoyal.Footer',
        'FrontRoyal.Institutions',
        'FrontRoyal.Navigation',
        'FrontRoyal.Playlists',
        'FrontRoyalTimer',
        'Translation',
        'FrontRoyal.Users',
        'Iguana',
        'Positionable',
        'SiteMetadata',
        'SoundManager',
        'angular-gestures',
        'dynamicNode',
        'guid',
        'imageFadeInOnLoad',
        'isMobile',
        'safeApply',
        'timeoutHelpers',
        'noApply',
        'FrontRoyal.ErrorLogService',
        'FrontRoyal.RouteAnimationHelper',
        'emptyToEndFilter',
        'porterStemmer',
        'FileTypeIcon',
        'zoomContainer',
        'oc.lazyLoad',
        'hoursMinutesSecondsFilter',
        'LearnerContentCache',
        'TextToImage',
        'ngStorableImage',
        'OfflineMode',
        'FrontRoyalStore',
        'HelpScoutBeacon',
        'VoiceCoursePractice',
    ])
    .run([
        '$injector',
        $injector => {
            const EventLogger = $injector.get('EventLogger');

            // Keep this in sync with value in lesson.launch()
            const lessonEvents = [
                'lesson:back-button-click',
                'lesson:complete',
                'lesson:feedback',
                'lesson:finish',
                'lesson:play',
                'lesson:start',
                'lesson:unload',
                'lesson:save',
                'lesson:slow_loading_message',
                'lesson:show_slow_loading_exit',
                'lesson:show_slow_loading_exit_clicked',
                'lesson:show_slow_loading_message',
                'lesson:slow_loading_exit_clicked',
                'lesson:editor:ping',
            ];
            const lessonEventsWithSuffixes = _.clone(lessonEvents);
            ['editor', 'preview'].forEach(suffix => {
                lessonEvents.forEach(eventType => {
                    lessonEventsWithSuffixes.push(`${eventType}:${suffix}`);
                });
            });

            EventLogger.setLabelProperty('lesson_title', lessonEventsWithSuffixes);

            const frameEvents = [
                'lesson:frame:finish',
                'lesson:frame:play',
                'lesson:frame:skip',
                'lesson:frame:unload',
                'lesson:frame:activate',
                'lesson:frame:practice_mode_continue_click',
            ];

            const frameEventsWithSuffixes = _.clone(frameEvents);
            ['editor', 'preview'].forEach(suffix => {
                frameEvents.forEach(eventType => {
                    frameEventsWithSuffixes.push(`${eventType}:${suffix}`);
                });
            });
            EventLogger.setLabelProperty('frame_id', frameEventsWithSuffixes);

            const challengeEvents = ['lesson:challenge_complete', 'lesson:challenge_validation'];

            const challengeEventsWithSuffixes = _.clone(challengeEvents);
            ['editor', 'preview'].forEach(suffix => {
                challengeEvents.forEach(eventType => {
                    challengeEventsWithSuffixes.push(`${eventType}:${suffix}`);
                });
            });
            EventLogger.setLabelProperty('challenge_id', challengeEventsWithSuffixes);
            EventLogger.setLabelProperty('lesson_stream_id', [
                'lesson:stream:complete',
                'lesson:stream:start',
                'lesson:stream:notify-coming-soon',
                'lesson:stream:download_certificate_click',
                'student-dashboard:keep-learning',
            ]);
        },
    ])
    .constant('PLAYER_SESSION_EXPIRY_MINUTES', 20)
    .constant('HINDI_NUMERALS', ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']);
