import { Brand } from 'AppBranding';
import clsx from 'clsx';
import { useCurrentUserIguanaObject, useTargetBrandConfig } from 'FrontRoyalAngular';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { navigationHelper } from 'Navigation';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { PlaylistCard } from './PlaylistCard';
import { useGradesData } from './useGradesData';
import { RefundEligibility } from './RefundEligibility';

const classes = {
    container: clsx(['flex', 'flex-col', 'items-center', 'justify-center', 'px-0', 'xxs:px-5', 'sm:px-14']),
    policy: {
        container: clsx(['px-3', 'xxs:px-0', 'text-[14px]', 'mt-4', 'text-beige-beyond-dark']),
        link: clsx('hover:underline', 'h-[17px]'),
    },
    group: {
        container: clsx(['w-full']),
        label: clsx(['text-lg', 'mt-8', 'mb-6', 'px-3', 'xxs:px-0', 'text-black']),
    },
};

const Link = ({ href, children }: { href: string; children: ReactNode }) => {
    const { loadRoute, loadUrl } = navigationHelper();
    const brandConfig = useTargetBrandConfig();
    const isValar = brandConfig?.branding === Brand.valar;
    const isRelative = href.startsWith('/');

    return (
        <button
            className={clsx(classes.policy.link, isValar ? 'text-blue' : 'text-coral')}
            onClick={() => (isRelative ? loadRoute(href) : loadUrl(href, '_blank'))}
            type="button"
        >
            {children}
        </button>
    );
};

// eslint-disable-next-line max-lines-per-function
export const Grades = () => {
    const {
        playlistsWithGrades,
        otherRequirementsWithGrades,
        capstoneWithGrades,
        specializationsWithGrades,
        electivesWithGrades,
        refundEligibilityInfo,
        foundationsPlaylist,
        includeFoundationsInGrades,
        isLoading,
    } = useGradesData();
    const { t } = useTranslation('back_royal');
    const brandConfig = useTargetBrandConfig();
    // FIXME: move to AppBrandConfigs.ts to remove need of iguana user here
    const supportEmail = useCurrentUserIguanaObject()?.relevantCohort?.supportEmailAddress('academicadvising');
    const imageSrc = brandConfig.avatarAlt || brandConfig.avatarDefault;

    if (isLoading) return <FrontRoyalSpinner color="force-brand" className="no-delay" />;

    return (
        <div className={classes.container}>
            <p className={classes.policy.container}>
                {t('grades.grades.policy1')}
                <Link href="/resources/academic-and-program-support">{t('grades.grades.resources')}</Link>
                {t('grades.grades.period')} {t('grades.grades.policy2')}
                <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
                {t('grades.grades.policy3')}
                <Link href={brandConfig.academicAdvisingUrl!}>{t('grades.grades.here')}</Link>
                {t('grades.grades.period')}
            </p>
            <div className={classes.group.container}>
                <h2 className={classes.group.label}>{t('grades.grades.coreCurriculum')}</h2>
                {playlistsWithGrades.map(p => (
                    <PlaylistCard
                        key={p.title}
                        completed={p.complete}
                        started={p.started}
                        imageSrc={p.image?.formats?.['100X100']?.url || imageSrc}
                        title={p.title}
                        sections={[
                            {
                                title: 'SMARTCASES',
                                rowsData: p.smartcases.map(sc => ({
                                    complete: sc.complete,
                                    started: sc.started,
                                    rowTextList: [
                                        [t('grades.grades.course'), sc.course],
                                        ['SMARTCASE', sc.title],
                                        [
                                            t('grades.grades.completed'),
                                            t('grades.grades.dateCompleted')!,
                                            sc.completedAt,
                                        ],
                                        [t('grades.grades.score'), sc.score],
                                    ],
                                })),
                            },
                            {
                                title: t('grades.grades.exams'),
                                rowsData: p.exams.map(e => ({
                                    complete: !!e.completedAt,
                                    started: e.started,
                                    rowTextList: [
                                        [t('grades.grades.examCourse'), e.title],
                                        [
                                            t('grades.grades.completed'),
                                            t('grades.grades.dateCompleted')!,
                                            e.completedAt,
                                        ],
                                        [t('grades.grades.score'), e.score],
                                    ],
                                })),
                            },
                            {
                                title: t('grades.grades.project'),
                                rowsData: p.projects?.map(pr => ({
                                    complete: pr.status !== '\u2014',
                                    started: pr.started,
                                    rowTextList: [
                                        [t('grades.grades.project'), pr.title],
                                        [t('grades.grades.rubricScore'), pr['Rubric Score']],
                                        [t('grades.grades.status'), pr.status],
                                    ],
                                })),
                            },
                        ]}
                    />
                ))}
                {!!otherRequirementsWithGrades.values.length && (
                    <PlaylistCard
                        imageSrc={imageSrc}
                        title={t('grades.grades.other')}
                        completed={otherRequirementsWithGrades.complete}
                        started={otherRequirementsWithGrades.started}
                        sections={[
                            {
                                title: 'SMARTCASES',
                                rowsData: otherRequirementsWithGrades.values.map(sc => ({
                                    complete: sc.complete,
                                    started: sc.started,
                                    rowTextList: [
                                        [t('grades.grades.course'), sc.course],
                                        ['SMARTCASE', sc.title],
                                        [
                                            t('grades.grades.completed'),
                                            t('grades.grades.dateCompleted')!,
                                            sc.completedAt,
                                        ],
                                        [t('grades.grades.score'), sc.score],
                                    ],
                                })),
                            },
                        ]}
                    />
                )}
                {!!capstoneWithGrades.values.length && (
                    <PlaylistCard
                        imageSrc={imageSrc}
                        title="Capstone"
                        completed={capstoneWithGrades.complete}
                        started={capstoneWithGrades.started}
                        sections={[
                            {
                                title: 'Capstone',
                                rowsData: capstoneWithGrades.values.map(c => ({
                                    started: c.started,
                                    rowTextList: [
                                        [t('grades.grades.title'), c.Title],
                                        [t('grades.grades.rubricScore'), c['Rubric Score']],
                                        [t('grades.grades.status'), c.status],
                                    ],
                                })),
                            },
                        ]}
                    />
                )}
            </div>
            {!!specializationsWithGrades.length && (
                <div className={classes.group.container}>
                    <h2 className={classes.group.label}>{t('grades.grades.specializations')}</h2>
                    {specializationsWithGrades.map(s => (
                        <PlaylistCard
                            key={s.title}
                            completed={s.complete}
                            started={s.started}
                            imageSrc={s.image?.formats?.['100X100']?.url || ''}
                            title={s.title}
                            sections={[
                                {
                                    title: 'SMARTCASES',
                                    rowsData: s.smartcases.map(sc => ({
                                        complete: sc.complete,
                                        started: sc.started,
                                        rowTextList: [
                                            [t('grades.grades.course'), sc.course],
                                            ['SMARTCASE', sc.title],
                                            [
                                                t('grades.grades.completed'),
                                                t('grades.grades.dateCompleted')!,
                                                sc.completedAt,
                                            ],
                                            [t('grades.grades.score'), sc.score],
                                        ],
                                    })),
                                },
                                {
                                    title: 'Exams',
                                    rowsData: s.exams.map(e => ({
                                        complete: !!e.completedAt,
                                        started: e.started,
                                        rowTextList: [
                                            [t('grades.grades.examCourse'), e.title],
                                            [
                                                t('grades.grades.completed'),
                                                t('grades.grades.dateCompleted')!,
                                                e.completedAt,
                                            ],
                                            [t('grades.grades.course'), e.score],
                                        ],
                                    })),
                                },
                            ]}
                        />
                    ))}
                </div>
            )}
            {!!electivesWithGrades.length && (
                <div className={classes.group.container}>
                    <h2 className={classes.group.label}>{t('grades.grades.optional')}</h2>
                    <PlaylistCard
                        title={t('grades.grades.electives')}
                        completed={false}
                        started
                        imageSrc={imageSrc}
                        sections={[
                            {
                                title: t('grades.grades.electives'),
                                rowsData: electivesWithGrades.map(e => ({
                                    complete: e.complete,
                                    started: e.started,
                                    rowTextList: [
                                        [t('grades.grades.course'), e.course],
                                        ['SMARTCASE', e.title],
                                        [
                                            t('grades.grades.completed'),
                                            t('grades.grades.dateCompleted')!,
                                            e.completedAt,
                                        ],
                                        [t('grades.grades.score'), e.score],
                                    ],
                                })),
                            },
                        ]}
                    />
                </div>
            )}
            {!includeFoundationsInGrades && foundationsPlaylist && (
                <div className="mt-8 flex w-full text-xs text-beige-beyond-dark">
                    <p className="me-[1px] mt-3 text-lg font-semibold leading-[0]">*</p>
                    <div>{t('grades.grades.foundationsNote', { title: foundationsPlaylist.title })}</div>
                </div>
            )}
            {refundEligibilityInfo && <RefundEligibility {...refundEligibilityInfo} />}
        </div>
    );
};
