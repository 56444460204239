// Timer is tested in TimerSingleton.spec.ts
import type * as Sentry from '@sentry/react';

type TimerOptions = {
    debug: boolean;
    sentrySpan?: Sentry.Span | null;
};

type Step = {
    eventType: string;
    duration: number;
};

export default class Timer {
    startTime: number;
    lastStepTime: number;
    steps: Step[] = [];
    debug: boolean;
    sentrySpan: Sentry.Span | null;

    constructor({ debug, sentrySpan }: TimerOptions) {
        this.startTime = Date.now();
        this.lastStepTime = Date.now();
        this.debug = debug;

        this.sentrySpan = sentrySpan || null;
    }

    addStep(step: Step) {
        this.steps.push(step);
    }

    afterFinish(finalEventType: string, totalDuration: number) {
        if (this.sentrySpan) {
            this.sentrySpan.end();
        }
        if (this.debug) {
            console.log('----------------- ', finalEventType);
            this.steps.forEach(step => {
                console.log(step.duration, step.eventType);
            });
            console.log('total ', totalDuration);
            console.log('-----------------');
        }
    }
}
