import iconCompleteApplication from 'vectors/icon-complete-application.svg';
import iconLearnMore from 'vectors/icon-learn-more.svg';
import iconGraduationCap from 'vectors/icon-graduation-cap.svg';
import { getCohort, getUserProgramState } from 'Users';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { ProgramTypeConfigs } from 'Program';
import { type BrandConfig } from 'AppBranding';
import { navigationHelper } from 'Navigation';
import { type LearnerContentCache as LearnerContentCacheType } from 'LearnerContentCache';
import { isPlaylistComplete, playlistHasAtLeastOneCourseComplete } from 'StoredProgress';
import { type AdmissionsModalConfig, type AdmissionsModalKey } from './AdmissionsProcessModals.types';
import loadRouteAndHideAlerts from './loadRouteAndHideAlerts';
import onClickAdmissionsQuestion from './onClickAdmissionsQuestion';

type Props = {
    injector: ng.auto.IInjectorService;
    userBrandConfig: BrandConfig;
};

// eslint-disable-next-line max-lines-per-function
const modalConfigs = ({ injector, userBrandConfig }: Props): Record<AdmissionsModalKey, AdmissionsModalConfig> => ({
    welcomeToDashboard: {
        key: 'welcomeToDashboard',
        localeNamespace: 'admissions_modals.welcome_to_dashboard',
        titleLocaleKey: 'welcome',
        titleLocaleParams: { brandName: userBrandConfig.brandNameShort },
        modalClasses: ['medium-large'],
        callbacks: {
            completeApplication: () => {
                loadRouteAndHideAlerts({ path: '/settings/application', injector });
            },
            onClickAdmissionsQuestion: () => onClickAdmissionsQuestion(injector),
            onClickAlumniNetwork: () => {
                loadRouteAndHideAlerts({ path: '/student-network', injector });
            },
            onClickOpenCourses: () => {
                loadRouteAndHideAlerts({ path: '/dashboard', injector });
            },
            onClickNeedMoreInfo: () => {
                const { loadUrl } = navigationHelper();
                const programType = getUserProgramState(
                    injector.get<FrontRoyalRootScope>('$rootScope').currentUser!,
                )?.programType;

                if (!programType || !ProgramTypeConfigs[programType]) return;

                const learnMoreUrl = userBrandConfig[
                    ProgramTypeConfigs[programType].learnMoreUrlBrandConfigProperty as keyof BrandConfig
                ] as string;

                if (!learnMoreUrl) return;

                loadUrl(learnMoreUrl, '_blank');
            },
        },
        elements: async () => [
            {
                icon: iconLearnMore,
                localeKey: 'need_more_info',
                localeParams: { brandName: userBrandConfig.brandNameShort },
            },
            {
                icon: iconCompleteApplication,
                localeKey: 'complete_your_application',
                imageClassName: 'complete-application',
            },
            {
                icon: iconGraduationCap,
                localeKey: 'try_our_lessons',
                imageClassName: 'graduation-cap',
            },
        ],
    },
    applicationReceived: {
        key: 'applicationReceived',
        localeNamespace: 'admissions_modals.application_received_modal',
        titleLocaleKey: 'we_received_your_application',
        modalClasses: ['medium'],
        callbacks: {
            onClickCoursesTab: () => {
                loadRouteAndHideAlerts({ path: '/courses', injector });
            },
            onClickDashboard: () => {
                loadRouteAndHideAlerts({ path: '/dashboard', injector });
            },
        },
        elements: async () => {
            const LearnerContentCache = injector.get<LearnerContentCacheType>('LearnerContentCache');
            const currentUser = injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
            const relevantCohort = getCohort(currentUser ?? null);

            if (!currentUser || !relevantCohort) return [];

            const { available_playlists } = (await LearnerContentCache.ensureStudentDashboard()).result[0];
            const progress = await currentUser.progress.getAllProgress();
            const foundationsPlaylist = available_playlists.find(
                p => p.localePackId === relevantCohort.foundationsPlaylistLocalePack.id,
            );

            if (!foundationsPlaylist) return [];

            return [
                {
                    icon: iconGraduationCap,
                    localeKey: (() => {
                        if (isPlaylistComplete(foundationsPlaylist, progress.streamProgress)) {
                            return 'foundations_completed';
                        }
                        if (playlistHasAtLeastOneCourseComplete(foundationsPlaylist, progress.streamProgress)) {
                            return ProgramTypeConfigs[relevantCohort.programType]
                                .applicationReceivedModalContinueCoursesKey!;
                        }
                        return 'complete_first_course';
                    })(),
                    localeParams: { foundationsPlaylistTitle: foundationsPlaylist.title },
                    imageClassName: 'graduation-cap',
                },
            ];
        },
    },
});

export default modalConfigs;
