import { type FC, memo } from 'react';
import { twMerge } from 'Utils/customTwMerge';
import { StudyBox } from './StudyBox';

function Placeholder({ className, children }: { className?: string; children: React.ReactNode }) {
    // merge "rounded border border-gray-300 bg-white" into the className
    return <div className={twMerge('rounded border border-gray-300 bg-white p-2', className)}>{children}</div>;
}

const StudentDashboardComponent: FC = () => (
    <div className="mx-auto flex max-w-[966px] flex-col gap-4 p-4 sm:flex-row">
        {/* Main Content */}
        <div className="flex w-full flex-col gap-4 sm:w-3/4">
            <Placeholder className="min-h-[120px]">Next Up</Placeholder>

            <StudyBox />

            <Placeholder className="min-h-[280px]">Meet Your Class</Placeholder>
            <Placeholder className="min-h-[454px]">Upcoming Events</Placeholder>
            <Placeholder className="min-h-[280px]">Bookmarked Courses</Placeholder>
        </div>

        {/* Sidebar */}
        <Placeholder className="sm:hide w-full max-w-[301px]">Sidebar</Placeholder>
    </div>
);

export const StudentDashboard = memo(StudentDashboardComponent) as typeof StudentDashboardComponent;
