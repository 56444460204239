import angularModule from '../../../lessons_module';

angularModule.factory('Lesson.FrameList.FrameViewModel', [
    '$injector',
    $injector => {
        const AClassAbove = $injector.get('AClassAbove');
        const guid = $injector.get('guid');
        const EventLogger = $injector.get('EventLogger');

        return AClassAbove.subclass(function () {
            //-------------------------------------------------
            // Publicly Accessible Properties
            // - Any of these can be overridden in subclasses
            //-------------------------------------------------

            // - complete
            // True when the learner is free to move on to the next frame.
            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return false;
                },
                configurable: true,
            });

            // / - readyToValidate
            // True when some answer has been selected, and the user must take an
            // action in order to discover whether the selected answer is correct or not.
            Object.defineProperty(this.prototype, 'readyToValidate', {
                get() {
                    return false;
                },
            });

            // - hasInvalidAnswer
            // True when the learner has selected an invalidAnswer and is being
            // shown negative feedback.
            Object.defineProperty(this.prototype, 'hasInvalidAnswer', {
                get() {
                    return false;
                },
            });

            // - hasInvalidAnswer
            // True when the learner needs to take some action.
            Object.defineProperty(this.prototype, 'waitingForAnswer', {
                get() {
                    return !this.readyToValidate && !this.complete;
                },
            });

            // in order to handle some
            // logging in scope.$digest, angular was trying to toJson the
            // frameViewModel.  The circular reference in playerViewModel was then throwing an error.
            // This removes playerViewModel from the enumerable properties, alowing it to
            // be toJsonned. (Note: I tried to use writeable: true instead of explicitly
            // setting the getters and setters, but it doesn't work when setting it
            // on the prototype.)
            Object.defineProperty(this.prototype, 'playerViewModel', {
                get() {
                    return this.$$playerViewModel;
                },
                set(val) {
                    this.$$playerViewModel = val;
                },
            });

            // in order to handle some
            // logging in scope.$digest, angular was trying to toJson the
            // frameViewModel.  The circular reference in frame was then throwing an error.
            // This removes frame from the enumerable properties, alowing it to
            // be toJsonned. (Note: I tried to use writeable: true instead of explicitly
            // setting the getters and setters, but it doesn't work when setting it
            // on the prototype.)
            Object.defineProperty(this.prototype, 'frame', {
                get() {
                    return this.$$frame;
                },
                set(val) {
                    this.$$frame = val;
                },
            });

            Object.defineProperty(this.prototype, 'lesson', {
                get() {
                    return this.frame.lesson();
                },
            });

            Object.defineProperty(this.prototype, 'editorMode', {
                get() {
                    return this.playerViewModel ? this.playerViewModel.editorMode : false;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'previewMode', {
                get() {
                    return this.playerViewModel ? this.playerViewModel.previewMode : false;
                },
            });

            Object.defineProperty(this.prototype, 'index', {
                get() {
                    return this.playerViewModel ? this.playerViewModel.frames.indexOf(this.frame) : undefined;
                },
            });

            Object.defineProperty(this.prototype, 'isPractice', {
                get() {
                    return this.frame.isPractice;
                },
            });

            Object.defineProperty(this.prototype, 'testOrAssessment', {
                get() {
                    return this.playerViewModel?.testOrAssessment ?? false;
                },
            });

            Object.defineProperty(this.prototype, 'supportsReviewPreviousMaterialBot', {
                get() {
                    return this.index === 0 && !!this.playerViewModel.preLessonReviewMessage;
                },
            });

            Object.defineProperty(this.prototype, 'supportsExplainScreen', {
                get() {
                    if (this.index > 0) return true;

                    // Admins use preview mode to help answer student questions, often on exams. Since exams often have challenges
                    // on the first screen, and there is no previous lesson to review in that case, we should AI tutor on the first screen
                    // in that case.
                    if (this.previewMode) return true;

                    return false;
                },
            });

            Object.defineProperty(this.prototype, 'supportsReviewThisLesson', {
                get() {
                    return this.playerViewModel.onLastFrame && this.complete;
                },
            });

            Object.defineProperty(this.prototype, 'frameSupportsBot', {
                get() {
                    if (!this.playerViewModel.lessonSupportsBot) return false;
                    return (
                        this.supportsReviewPreviousMaterialBot ||
                        this.supportsExplainScreen ||
                        this.supportsReviewThisLesson
                    );
                },
                configurable: true,
            });

            return {
                initialize(frame) {
                    this.frame = frame;
                    this.id = guid.generate();
                },

                giveHint() {
                    // no-op
                },

                toJasminePP() {
                    return `FrameViewModel for frame: ${this.frame.id}`;
                },

                linkToMainFrameElement(scope, elem) {
                    this.mainFrameScope = scope;
                    this.mainFrameElem = elem;

                    scope.$on('$destroy', this.unlinkFromMainFrameElement.bind(this));
                },

                // can be overriden
                destroy() {},

                unlinkFromMainFrameElement() {
                    this.mainFrameScope = undefined;
                    this.mainFrameElem = undefined;
                },

                gotoNextFrame() {
                    this.playerViewModel.gotoNext();
                },

                skipFrame() {
                    this.playerViewModel.skipFrame();
                },

                log(eventType, extraPayload, options) {
                    extraPayload = _.extend(this.logInfo(), extraPayload);
                    if (this.playerViewModel) {
                        return this.playerViewModel.log(eventType, extraPayload, options);
                    }
                    return EventLogger.log(eventType, extraPayload, options);
                },

                logInfo() {
                    const props = this.playerViewModel ? this.playerViewModel.logInfo() : {};
                    return angular.extend(props || {}, this.frame.logInfo(), {
                        frame_play_id: this.id,
                    });
                },
            };
        });
    },
]);
