import { careerProfilesApi } from 'CareerProfiles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type AnyObject } from '@Types';
import { useDispatch } from 'react-redux';
import { storeProvider } from 'ReduxHelpers';
import { debounce } from 'lodash/fp';
import { addAvailableUsers } from '../actions';
import { useCreateUserPromiseFromIds } from './useCreateUserPromiseFromIds';

const { useLazySearchForSendbirdProfilesByUserNameQuery } = careerProfilesApi;

export const useGetUsersByName = () => {
    const dispatch = useDispatch();
    const [trigger, profileQueryState] = useLazySearchForSendbirdProfilesByUserNameQuery();
    const [loadingUsers, setLoadingUsers] = useState(false);

    const createUserPromiseArray = useCreateUserPromiseFromIds();

    const checkIfCacheValueExistsForArg = useCallback(
        (arg: string) => careerProfilesApi.endpoints.searchForSendbirdProfilesByUserName.select(arg),
        [],
    );
    const debouncedTrigger = useMemo(() => debounce(500, trigger), [trigger]);

    const triggerFetchProfiles = useCallback(
        (nameText: string) => {
            const { isUninitialized } = checkIfCacheValueExistsForArg(nameText)(storeProvider.store?.getState());

            if (isUninitialized) {
                setLoadingUsers(true);
                debouncedTrigger(nameText, true);
            } else {
                setLoadingUsers(false);
            }
        },
        [checkIfCacheValueExistsForArg, debouncedTrigger],
    );

    useEffect(() => {
        if (!loadingUsers) return;
        if (!profileQueryState.currentData) return;

        const fetchedProfiles = profileQueryState.currentData || [];

        if (fetchedProfiles.length === 0) {
            setLoadingUsers(false);
            return;
        }

        const functionToFetchUsers = () => {
            Promise.all(
                createUserPromiseArray(
                    fetchedProfiles
                        .filter(p => !!p.hasEverHadSendbirdAccess && !!p.messagingEnabled)
                        .map(p => p.userId),
                ),
            ).then((...userArraysArray) => {
                const updatedUsers = userArraysArray.flat().flatMap(a => a.map(u => u.serialize())) as AnyObject[];

                dispatch(addAvailableUsers({ profiles: fetchedProfiles, users: updatedUsers }));

                setLoadingUsers(false);
            });
        };

        functionToFetchUsers();
    }, [createUserPromiseArray, dispatch, loadingUsers, profileQueryState]);

    return { triggerFetchProfiles, loading: loadingUsers };
};
