import { useLocation, type createBrowserRouter, type createHashRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { angularInjectorProvider } from 'Injector';
import { REACT_ROUTER_DIRECTIVE_NAME } from 'Navigation';
import { useRootScope } from './useRootScope';

type RouteWithDirective = {
    $$route: {
        directive: string | undefined | null;
        routeHandledBy: 'react' | 'angular' | undefined;
    };
};

// Observe route changes kicked off by angular and update the react router accordingly
export function useRespondToAngularRouteChanges(
    router: ReturnType<typeof createBrowserRouter> | ReturnType<typeof createHashRouter>,
) {
    const rootScope = useRootScope();
    const $route = angularInjectorProvider.requireInjector().get<ng.route.IRouteService>('$route');

    useEffect(() => {
        function onRouteChangeSuccess() {
            let currentPathname = window.location.pathname;
            const searchParams = window.location.search;
            const $$route = ($route.current as unknown as RouteWithDirective).$$route;
            const directiveName: string | null = $$route.directive || null;
            const routeHandledBy = directiveName === REACT_ROUTER_DIRECTIVE_NAME ? 'react' : 'angular';
            $$route.routeHandledBy = routeHandledBy;

            if (window.CORDOVA) {
                const hash = window.location.hash;
                currentPathname = hash && hash.match('#') ? hash.split('#')[1] : '/';
            }
            const target = `${currentPathname}${searchParams}`;
            router.navigate(target, { replace: true });
        }

        const off = rootScope.$on('$routeChangeSuccess', onRouteChangeSuccess);

        return off;
    }, [router, rootScope, $route]);
}

// Let angular know whenever the react route changes (for event logging)
export function useEmitRouteChangeSuccessOnReactRouteChange() {
    const { pathname } = useLocation();
    const rootScope = useRootScope();

    useEffect(() => {
        rootScope.$emit('reactRouteChangeSuccess');
    }, [rootScope, pathname]);
}
