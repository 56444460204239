import {
    SMARTLY_UPLOADS_DOMAIN,
    QUANTIC_CHINA_UPLOADS_DOMAIN,
    QUANTIC_APP_DOMAIN,
    QUANTIC_CHINA_APP_DOMAIN,
} from 'PedagoDomainConstants';
import { type ConfigFactory } from 'FrontRoyalConfig';
import { angularInjectorProvider } from 'Injector';
import { type LinkTarget } from './Navigation.types';

function modifyTargetForCordova(url: string, origTarget: LinkTarget): LinkTarget {
    const $injector = angularInjectorProvider.requireInjector();
    const $window = $injector.get('$window');

    if (!$window.CORDOVA) return origTarget;

    if (url.startsWith('blob:')) {
        return '_blank';
    }
    if (origTarget === '_blank') {
        return '_system';
    }

    // external links should open the system browser on mobile
    if (isExternalHref(url) && origTarget === '_self') {
        return '_system';
    }

    return origTarget;
}

export function modifyTarget(url: string | undefined | null, origTarget?: LinkTarget | null): LinkTarget {
    let target = origTarget || '_self';
    if (!url) return target;

    if (isExternalHref(url) && !origTarget) target = '_blank';

    return modifyTargetForCordova(url, target);
}

function modifyUrlForChinaRegionMode(url: string): string {
    const $injector = angularInjectorProvider.requireInjector();
    const ConfigFactory = $injector.get<ConfigFactory>('ConfigFactory');

    const chinaRegionMode = ConfigFactory.getSync(true)?.chinaRegionMode();
    if (chinaRegionMode) {
        // Replace uploads.smart.ly --> uploads.quantic.cn, app.quantic.edu --> app.quantic.cn in Quantic.cn context.
        url = url.replace(`//${SMARTLY_UPLOADS_DOMAIN}`, `//${QUANTIC_CHINA_UPLOADS_DOMAIN}`);
        url = url.replace(`//${QUANTIC_APP_DOMAIN}`, `//${QUANTIC_CHINA_APP_DOMAIN}`);
    }

    return url;
}

export function modifyHref(href: string): string {
    href = modifyUrlForChinaRegionMode(href);
    return href;
}

export function isExternalHref(href: string) {
    return /^https?:\/\//.test(href);
}
