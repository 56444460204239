export const USER_IN_ADMIN_FIELDS = [
    'activeInstitution',
    'admissionOffers',
    'birthdate',
    'city',
    'country',
    'deactivated',
    'email',
    'enableFrontRoyalStore',
    'execEdEligibilityBundle',
    'extendExamTime',
    'fallbackProgramType',
    'groups',
    'id',
    'ineligibleForExecEdBundle',
    'institutions',
    'mbaContentLockable',
    'name',
    'nickname',
    'phone',
    'prefLocale',
    'prefUserProgramFieldStateId',
    'programApplications',
    'programFamilyApplications',
    'programFamilyFormData',
    'programInclusions',
    'roles',
    'signableDocuments',
    'state',
    'studentDashboardVersionOverride',
    'timezone',
    'tuitionContracts',
    'refundEntitlements',
    'userIdVerifications',
    'userProgramFieldStates',
    'userProgramStates',
    'zip',
] as const;
