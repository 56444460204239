import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useResourcesAuthenticationHelper, useRequiredCurrentUser } from 'FrontRoyalAngular';
import { navigationHelper } from 'Navigation';

import headerImageMobile from 'images/resources/library-services-illustration.png';
import headerImageMobileRetina from 'images/resources/library-services-illustration@2x.png';
import interviewsPersonImageMobile from 'images/resources/library-services-open-courses-illustration.png';
import interviewsPersonImageMobileRetina from 'images/resources/library-services-open-courses-illustration@2x.png';
import { targetBrandConfig } from 'AppBranding';
import { getProgramInclusion, getCohort } from 'Users';
import { isCurrentOrHasGraduatedProgram } from 'ProgramInclusion';
import { type ProgramTypeConfig, ProgramTypeConfigs } from 'Program';
import { useLibraryDatabaseAccessQuery } from '../resourcesApi';
import { ROUTES } from '../ResourcesRoutes';
import { CategoryResultsSection } from '../CategoryResultsSection/CategoryResultsSection';
import { useSearchResults } from '../useSearchResults';
import { type CategoryDescriptionSectionProps } from '../CategoryDescriptionSection/CategoryDescriptionSection.types';
import { CategoryDescriptionSection } from '../CategoryDescriptionSection';

// eslint-disable-next-line max-lines-per-function
export function LibraryServices() {
    const { t } = useTranslation('back_royal');
    const { loadUrl } = navigationHelper();
    const currentUser = useRequiredCurrentUser();
    const ResourcesAuthenticationHelper = useResourcesAuthenticationHelper();
    const { currentData } = useLibraryDatabaseAccessQuery(getProgramInclusion(currentUser)?.id);
    const brandConfig = targetBrandConfig(currentUser);
    const programInclusion = useMemo(() => getProgramInclusion(currentUser), [currentUser]);
    const courseSummary = useMemo(() => getCohort(currentUser)?.courseSummary, [currentUser]);
    const showCourseSummary = useMemo(
        () => isCurrentOrHasGraduatedProgram(programInclusion) && !!courseSummary,
        [programInclusion, courseSummary],
    );
    const cohort = getCohort(currentUser);

    // FIXME: If we built the url ahead of time, we could just set an href on the link rather
    // than having to use an onClick and loadUrl. Before doing this, we'd probably want to convert
    // ResourcesAuthenticationHelper to an RtkQuery endpoint to get caching of the token.
    const loadUrlWithAuth = useCallback(
        (url: string) => {
            if (!currentUser) return;

            const searchParams = new URLSearchParams();

            // Users must have a program inclusion in order to access the databases, but admins
            // and other internal users will be able to do it without a program inclusion
            if (programInclusion) searchParams.append('program_inclusion_id', programInclusion.id);

            // The Cordova user won't have an auth cookie, and the auth headers aren't sent in a simple, non-ajax
            // "load URL in browser" GET request, so we first fetch a time-based token for the user to append as a query
            // param, which the `/library/database` endpoint can use to authenticate the user
            if (window.CORDOVA) {
                ResourcesAuthenticationHelper.getAuthenticationToken()
                    .then(token => {
                        searchParams.append('user_id', currentUser.id);
                        searchParams.append('token', token);
                        loadUrl(`${url}?${searchParams}`, '_blank');
                    })
                    .catch(() => {
                        // no-op
                    });
            } else {
                loadUrl(`${url}?${searchParams}`, '_blank');
            }
        },
        [ResourcesAuthenticationHelper, currentUser, loadUrl, programInclusion],
    );

    const searchResults = useSearchResults({
        categorySlug: ROUTES.RESOURCES.$.LIBRARY_SERVICES.relativePath,
    });

    // eslint-disable-next-line max-lines-per-function
    const categoryDescription = useMemo(() => {
        if (!cohort) return null;
        const programTypeConfig = ProgramTypeConfigs[cohort.programType];

        return {
            header: {
                title: t('resources.resources.library_services_title'),
                image: {
                    mobileImage: {
                        width: 40,
                        height: 40,
                        images: {
                            default: headerImageMobile,
                            retina: headerImageMobileRetina,
                        },
                    },
                    alt: '',
                },
            },
            content: [
                {
                    title: t('resources.resources.library_services_content_one_title'),
                    description: t('resources.resources.library_services_content_one_description'),
                },
                {
                    title: t('resources.resources.library_services_content_two_title'),
                    description: t('resources.resources.library_services_content_two_description'),
                },
                currentData?.hasLibraryDatabaseAccess && {
                    title: t('resources.resources.library_services_links_title')!,
                    description: t('resources.resources.library_services_links_description', {
                        brandNameShort: brandConfig?.brandNameShort,
                    })!,
                    links: [
                        {
                            key: 'statista',
                            label: t('resources.resources.library_services_link_one_label'),
                            href: '/library/database/statista',
                            onClick: (event: MouseEvent) => {
                                event.preventDefault();
                                loadUrlWithAuth('/library/database/statista');
                            },
                        },
                        {
                            key: 'one_business',
                            label: t('resources.resources.library_services_link_two_label'),
                            href: '/library/database/one_business',
                            onClick: (event: MouseEvent) => {
                                event.preventDefault();
                                loadUrlWithAuth('/library/database/one_business');
                            },
                        },
                        {
                            key: 'ibisworld',
                            label: t('resources.resources.library_services_link_three_label'),
                            href: '/library/database/ibisworld',
                            onClick: (event: MouseEvent) => {
                                event.preventDefault();
                                loadUrlWithAuth('/library/database/ibisworld');
                            },
                        },
                    ].filter(link =>
                        programTypeConfig.libraryDatabases.includes(
                            link.key as ProgramTypeConfig['libraryDatabases'][number],
                        ),
                    ),
                },
                showCourseSummary && {
                    title: t('resources.resources.library_services_content_three_title'),
                    description: t('resources.resources.library_services_content_three_description'),
                    links: [
                        {
                            label: t('resources.resources.library_services_content_three_title'),
                            href: courseSummary!.url,
                            onClick: (event: MouseEvent) => {
                                event.preventDefault();
                                loadUrl(courseSummary!.url, '_blank');
                            },
                        },
                    ],
                },
            ].filter(Boolean),
            image: {
                mobileImage: {
                    width: 420,
                    height: 191,
                    images: {
                        default: interviewsPersonImageMobile,
                        retina: interviewsPersonImageMobileRetina,
                    },
                },
                alt: 'Open courses illustration',
            },
        } as CategoryDescriptionSectionProps;
    }, [
        t,
        currentData?.hasLibraryDatabaseAccess,
        brandConfig?.brandNameShort,
        showCourseSummary,
        courseSummary,
        loadUrlWithAuth,
        loadUrl,
        cohort,
    ]);

    return (
        <div className="bg-white">
            {categoryDescription && <CategoryDescriptionSection {...categoryDescription} />}
            <CategoryResultsSection {...searchResults} />
        </div>
    );
}
