import automationMode from 'automationMode';
import deepEqual from 'deep-equal';
import { SHOW_FINISH_SCREEN_TRACING_NAME } from 'ErrorLogging';
import {
    getConversationIdForFrameId,
    chatActions,
    createTutorBotSystemMessage,
    createPretendAiMessage,
    REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    LESSON_FEEDBACK_FORM_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    getLastInitialMessage,
    UI_CONTEXT_LESSON_PLAYER,
    UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL,
} from 'TutorBot';
import { storeProvider as _storeProvider } from 'ReduxHelpers';
import { generateGuid } from 'guid';
import angularModule from '../../../lessons_module';

/*
    This is a class that holds the logic that a PlayerViewModel will need if there is an frame list
    lesson.

    In the wild, today, this is the PlayerViewModel class that we always use. There is also a PracticeFramesPlayerViewModel
    class, but that is unused.

    In order to share logic with the PracticeFramesPlayerViewModel, some logic has been split off into the
    PlayerViewModelWithFrames mixin and the PlayerViewModelWithLesson mixin. See the comments
    at the top of those files for details.
*/
const REVIEW_THIS_LESSON_BUTTON_KEY = 'review_this_lesson';
const EXPLAIN_BUTTON_KEY = 'explain';
const REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY = 'recap_last_lesson';

angularModule.factory('Lesson.FrameList.FrameListPlayerViewModel', [
    '$injector',
    $injector => {
        const PlayerViewModel = $injector.get('Lesson.PlayerViewModel');
        const PlayerViewModelWithLesson = $injector.get('PlayerViewModelWithLesson');
        const $location = $injector.get('$location');
        const PlayerViewModelWithFrames = $injector.get('PlayerViewModelWithFrames');
        const $rootScope = $injector.get('$rootScope');
        const ConfigFactory = $injector.get('ConfigFactory');
        const timerSingleton = $injector.get('timerSingleton');
        const $window = $injector.get('$window');

        // In lesson preview mode, the storeProvider is attached to the iframe.contentWindow
        // See edit_lesson_preview_mode_dir.js
        const storeProvider = $window.storeProvider ?? _storeProvider;

        return PlayerViewModel.subclass(function () {
            this.include(PlayerViewModelWithLesson);
            this.include(PlayerViewModelWithFrames);

            //-------------------------------------------------
            // Callbacks defined in PlayerViewModel
            //-------------------------------------------------

            this.setCallback('after', 'initialized', function () {
                if (this.showStartScreen && this.lesson.frames[0]) {
                    this.preloadAssetsForFrame(this.lesson.frames[0]);
                } else if (!this.showStartScreen) {
                    this.setInitialActiveFrame();
                }
            });

            //-------------------------------------------------
            // Callbacks defined in PlayerViewModelWithLesson
            //-------------------------------------------------

            this.setCallback('after', 'showed_start_screen', function () {
                this.activeFrame = undefined;
            });

            this.setCallback('after', 'hid_start_screen', function () {
                // Normally, when we are on the start screen and we set
                // showStartScreen = false, there will be no active frame,
                // and we want to go to the initial one.  However, if the active
                // frame is set in some other way (like by clicking the progress
                // hexagons, then the new activeFrame will be set before setting
                // showStartScreen to false)
                if (!this.activeFrame) {
                    this.setInitialActiveFrame();
                }
            });

            this.setCallback('after', 'showed_finish_screen', function () {
                this.activeFrame = undefined;
            });

            //-------------------------------------------------
            // Callbacks defined in PlayerViewModelWithFrames
            //-------------------------------------------------
            this.setCallback('around', 'frame_started', function (startFrame) {
                if (!this.started) {
                    this.started = true;
                }
                // when switching frames, switch back to the default locale
                this.$$showingLanguage = this.lesson.locale;

                // when switching frames, hide the bot
                this.showingBot = false;

                startFrame();
                this._saveBookmarkedFrame(this.activeFrame);
                $location.search('frame', this.activeFrame.id);
            });

            this.setCallback('after', 'frame_unloaded', function () {
                $location.search('frame', undefined);
                const activeSeconds = this._lastUnloadEvent.properties.duration_total;
                const limitedActiveSeconds = Math.min(activeSeconds, 120);
                this.frameDurations[this._lastUnloadEvent.properties.frame_id] =
                    this.frameDurations[this._lastUnloadEvent.properties.frame_id] || 0;
                this.frameDurations[this._lastUnloadEvent.properties.frame_id] += limitedActiveSeconds;
            });

            this.setCallback('after', 'frame_completed', function () {
                this._trackFrameChallengeStats();

                if (this.activeFrame?.nextFrame() && this.activeFrame.mainUiComponent.savesProgressOnComplete) {
                    this._saveBookmarkedFrame(this.activeFrame.nextFrame());
                }

                this._eagerlyMarkAsCompleteAfterLastFrame();
            });

            //-------------------------------------------------
            // Publicly Accessible Properties
            //-------------------------------------------------

            Object.defineProperty(this.prototype, 'activeFrameId', {
                get() {
                    return this.activeFrame && this.activeFrame.id;
                },
                set(val) {
                    const frame = this.lesson.frameForId(val);
                    this.activeFrame = frame;
                },
            });

            Object.defineProperty(this.prototype, 'frames', {
                get() {
                    return this.lesson.frames;
                },
            });

            Object.defineProperty(this.prototype, 'completedFrames', {
                get() {
                    if (this.logProgress) {
                        return this.lesson.ensureLessonProgress().completed_frames;
                    }

                    if (!this._completedFrames) {
                        this._completedFrames = {};
                    }

                    return this._completedFrames;
                },
            });

            Object.defineProperty(this.prototype, 'currentChallenge', {
                get() {
                    // If the frame is complete, then we do not want to treat any challenge as being active
                    // See also currentChallengeIndexInModel
                    if (this.activeFrameComplete) return null;

                    return (
                        this.activeFrameViewModel?.mainUiComponentViewModel?.currentChallengeViewModel?.model || null
                    );
                },
            });

            Object.defineProperty(this.prototype, 'currentChallengeIndexInModel', {
                get() {
                    // If the frame is complete, then we do not want to treat any challenge as being active
                    // See also currentChallenge
                    if (this.activeFrameComplete) return null;

                    const frameViewModel = this.activeFrameViewModel;
                    const mainComponentViewModel = frameViewModel?.mainUiComponentViewModel;

                    // If the frame is complete, then we do not want to treat any challenge as being active
                    return mainComponentViewModel?.indexOfCurrentChallengeInModel >= 0
                        ? mainComponentViewModel?.indexOfCurrentChallengeInModel
                        : null;
                },
            });

            Object.defineProperty(this.prototype, 'activeFrameComplete', {
                get() {
                    const frameViewModel = this.activeFrameViewModel;
                    return frameViewModel?.complete || false;
                },
            });

            Object.defineProperty(this.prototype, 'activeFrameBotInfo', {
                get() {
                    return {
                        activeFrameComplete: this.activeFrameComplete,
                        currentChallengeIndex: this.currentChallengeIndexInModel,
                        activeFrameId: this.activeFrameId,
                    };
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, '_frameDescriptionsForTutorBot', {
                get() {
                    if (!this.$$frameDescriptionsForTutorBot) {
                        this.$$frameDescriptionsForTutorBot = this.frames.reduce((acc, frame) => {
                            acc[frame.id] = frame.reify().tutorBotDescription;
                            return acc;
                        }, {});
                    }

                    return this.$$frameDescriptionsForTutorBot;
                },
            });

            Object.defineProperty(this.prototype, '_frameInfoForBotClientContext', {
                get() {
                    // Group the frames into lists based on whether they're completed or not,
                    // set the activeFrameId, and build a map of descriptions.
                    return this.frames.reduce(
                        (acc, frame) => {
                            if (frame.id === this.activeFrameId) {
                                acc.activeFrameId = frame.id;
                            } else if (this.completedFrames[frame.id]) {
                                acc.completedFrameIds.push(frame.id);
                            } else {
                                acc.remainingFrameIds.push(frame.id);
                            }
                            return acc;
                        },
                        {
                            activeFrameId: undefined,
                            completedFrameIds: [],
                            remainingFrameIds: [],
                        },
                    );
                },
            });

            Object.defineProperty(this.prototype, 'frameDurations', {
                get() {
                    if (this.logProgress) {
                        const lessonProgress = this.lesson.ensureLessonProgress();
                        lessonProgress.frame_durations = lessonProgress.frame_durations || {};
                        return lessonProgress.frame_durations;
                    }

                    if (!this._frameDurations) {
                        this._frameDurations = {};
                    }

                    return this._frameDurations;
                },
            });

            Object.defineProperty(this.prototype, 'challengeScores', {
                get() {
                    if (this.logProgress) {
                        const lessonProgress = this.lesson.ensureLessonProgress();
                        lessonProgress.challenge_scores = lessonProgress.challenge_scores || {};
                        return lessonProgress.challenge_scores;
                    }

                    if (!this._challengeScores) {
                        this._challengeScores = {};
                    }

                    return this._challengeScores;
                },
            });

            Object.defineProperty(this.prototype, 'frameHistory', {
                get() {
                    if (this.logProgress) {
                        const lessonProgress = this.lesson.ensureLessonProgress();
                        lessonProgress.frame_history = lessonProgress.frame_history || [];
                        return lessonProgress.frame_history;
                    }

                    if (!this._frame_history) {
                        this._frame_history = [];
                    }

                    return this._frame_history;
                },
                set(val) {
                    if (this.logProgress) {
                        this.lesson.ensureLessonProgress().frame_history = val;
                    } else {
                        this._frame_history = val;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'frameBookmarkId', {
                get() {
                    if (this.logProgress) {
                        const lessonProgress = this.lesson.ensureLessonProgress();
                        return lessonProgress.frame_bookmark_id;
                    }

                    return this._frame_bookmark_id;
                },
                set(val) {
                    if (this.logProgress) {
                        this.lesson.ensureLessonProgress().frame_bookmark_id = val;
                    } else {
                        this._frame_bookmark_id = val;
                    }

                    if (this.scormMode) {
                        this.rpc.call('setBookmark', val);
                    }
                },
            });

            Object.defineProperty(this.prototype, 'shouldShowPreviousButton', {
                get() {
                    // If there is a stream, the back button can go
                    // to the stream dashboard, if there is a previous frame,
                    // the back button can go there
                    return (
                        !this.showStartScreen && ((!this.lesson.assessment && !this.lesson.test) || this.previewMode)
                    );
                },
            });

            Object.defineProperty(this.prototype, 'canNavigateFreely', {
                get() {
                    // getSync should work here, but calling this with withoutThrow because we
                    // can assume the enableLessonHexagonNavigation is false if we don't have
                    // a config for some reason.  That is only set in dev mode anyhow
                    const config = ConfigFactory.getSync(true);
                    if (automationMode().performanceTest) {
                        return true;
                    }
                    if (config?.enableLessonHexagonNavigation()) {
                        return true;
                    }

                    const canEdit = $rootScope.currentUser && $rootScope.currentUser.canEditLesson(this.lesson);
                    return canEdit;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'canNavigateBackToCompletedFrames', {
                get() {
                    const lesson = this.lesson;
                    const isAssessmentOrTest = lesson.assessment || lesson.test;
                    return this.canNavigateFreely || !isAssessmentOrTest;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'streamProgress', {
                get() {
                    return this.stream ? this.stream.ensureStreamProgress() : undefined;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, '_showFinishScreenWhenReadyTimerKey', {
                get() {
                    return `showFinishScreenWhenReady-${this.lesson.id}`;
                },
            });

            Object.defineProperty(this.prototype, 'isCompletedTest', {
                get() {
                    return !!this.lesson?.test && !!this.lessonProgress?.complete;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'onLastFrame', {
                get() {
                    return this.activeFrame && !this.activeFrame.nextFrame();
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'onFirstFrame', {
                get() {
                    return this.activeFrame === this.frames[0];
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'botLaunchButtonKeys', {
                get() {
                    return [REVIEW_THIS_LESSON_BUTTON_KEY, EXPLAIN_BUTTON_KEY, REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY];
                },
            });

            Object.defineProperty(this.prototype, 'botLaunchOptions', {
                get() {
                    if (this.activeFrameViewModel?.supportsReviewPreviousMaterialBot) {
                        return {
                            uiContext: UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL,
                            messageContent: this.preLessonReviewMessage,
                            buttonKey: REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY,
                        };
                    }
                    if (this.activeFrameViewModel?.supportsReviewThisLesson) {
                        return {
                            uiContext: UI_CONTEXT_LESSON_PLAYER,
                            messageContent: REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
                            buttonKey: REVIEW_THIS_LESSON_BUTTON_KEY,
                        };
                    }
                    return {
                        uiContext: UI_CONTEXT_LESSON_PLAYER,
                        messageContent: EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
                        buttonKey: EXPLAIN_BUTTON_KEY,
                    };
                },
                configurable: true, // specs
            });

            return {
                //---------------------------------
                // Lifecycle
                //---------------------------------

                setInitialActiveFrame() {
                    // check for the start frame id first in the
                    // query params, then in lesson_progress.frame_bookmark_id
                    const lesson = this.lesson;
                    let startingFrameId;

                    // We only pay attention to the frame_bookmark_id in the querystring
                    // if this user is allowed to navigate back
                    if (this.canNavigateBackToCompletedFrames) {
                        const search = $location.search();
                        startingFrameId = search ? search.frame : undefined;
                    }

                    startingFrameId = startingFrameId || lesson.frame_bookmark_id;
                    if (startingFrameId && this.logProgress) {
                        lesson.tryToSetFrameBookmarkId(startingFrameId);
                    }

                    let initialFrame;
                    if (startingFrameId) {
                        // Be defensive.  Due to changes in content, the bookmarked
                        // frame may no longer exist.
                        initialFrame = lesson.frameForId(startingFrameId, true);
                    }

                    if (!initialFrame) {
                        initialFrame = lesson.frames[0];
                    }

                    if (initialFrame) {
                        this.gotoFrame(initialFrame);
                    }
                },

                //---------------------------------
                // Navigation
                //---------------------------------

                // goto next frame
                gotoNext() {
                    // progress to next frame or set completed to true if at last
                    if (this.activeFrame) {
                        const frameHistory = this.frameHistory;
                        const lastFrameIdInHistory = _.last(frameHistory);
                        if (lastFrameIdInHistory !== this.activeFrame.id) {
                            frameHistory.push(this.activeFrame.id);
                        }

                        if (this.activeFrame.nextFrame()) {
                            this.gotoFrame(this.activeFrame.nextFrame());
                        } else if (!this.editorMode) {
                            this._showFinishScreenWhenReady();
                        }
                    }
                },

                _showFinishScreenWhenReady() {
                    // Moving on from last frame, so
                    // - hide the frame
                    // - ensure that the lesson has been marked as complete and the progress record has been flushed to the server
                    // - show the finish screen
                    //
                    // In most cases, _ensureMarkedAsCompleteAndFlushed will
                    // already have been called when the last frame was finished

                    const timer = timerSingleton.startTimer(this._showFinishScreenWhenReadyTimerKey, {
                        sentrySpanName: SHOW_FINISH_SCREEN_TRACING_NAME,
                    });

                    this.activeFrame = undefined;
                    this._ensureMarkedAsCompleteAndFlushed(timer).then(() => {
                        // In the normal case, activeframe will still be undefined,
                        // but if the user used the hexagons at the top to
                        // navigate in the meantime, don't skip to the finish screen.
                        if (!this.activeFrame) {
                            this.showFinishScreen = true;
                        }
                        timerSingleton.finishTimer(this._showFinishScreenWhenReadyTimerKey, 'show_finish_screen_timer');
                    });
                },

                gotoPrev() {
                    let prevFrameId;
                    if (this.frameHistory.length > 0) {
                        prevFrameId = this.frameHistory.pop();

                        // be defensive against the possibility that this
                        // frame has been removed from the lesson
                        if (!this.lesson.frameForId(prevFrameId, true)) {
                            prevFrameId = undefined;
                        }
                    }

                    const showingFinishScreen = this.showFinishScreen;
                    this.showFinishScreen = false;

                    if (prevFrameId) {
                        this.gotoFrameId(prevFrameId);
                    } else if (this.activeFrame && this.activeFrame.prevFrame()) {
                        this.gotoFrame(this.activeFrame.prevFrame());
                    } else if (showingFinishScreen) {
                        this.gotoFrameIndex(this.frames.length - 1);
                    } else {
                        this.showStartScreen = true;
                    }
                },

                //---------------------------------
                // Editing
                //---------------------------------

                swapFrame(oldFrame, newFrame) {
                    // use _activeFrame instead of activeFrame so it doesn't get set automatically
                    if (oldFrame === this._activeFrame) {
                        throw new Error(
                            'Bad things will happen if you try to swap out the active frame.  See editFrameInfoPanelDirBase.',
                        );
                    }
                    const index = oldFrame.index();
                    this.removeFrame(oldFrame);
                    this.lesson.addFrame(newFrame, index);
                },

                removeFrame(frame) {
                    if (this._activeFrame === frame) {
                        let nextFrame = frame.nextFrame() || this.frames[0];
                        if (nextFrame === frame) {
                            nextFrame = undefined;
                        }
                        if (nextFrame) {
                            // do not use gotoFrame.  Since this is the editor
                            // we just want to switch without worrying about preloading
                            this.activeFrame = nextFrame;
                        } else {
                            this.activeFrame = undefined;
                        }
                    }
                    this.lesson.removeFrame(frame);
                },

                removeFrames(frames) {
                    angular.forEach(
                        frames,
                        function (frame) {
                            this.lesson.removeFrame(frame);
                        },
                        this,
                    );
                },

                setChallengeScore(challengeViewModel) {
                    const score = challengeViewModel.getScore();

                    // score will not be set for challenges with no
                    // correct answer, and also for some challenges that are not
                    // yet complete.
                    if (typeof score !== 'number') {
                        return;
                    }

                    // FIXME: once we know challenge ids are unique, just
                    // use those. See https://trello.com/c/dQ6I2HnV/403-fix-ensure-unique-component-ids-and-fix-up-lesson-progress-challenge-scores
                    const key = `${challengeViewModel.frame.id},${challengeViewModel.model.id}`;
                    const currentScore = this.challengeScores[key];
                    const hasCurrentScore = typeof currentScore === 'number';

                    // On test and assessment lessons, we preserve the very first score the user enters.
                    // On regular lessons the score can change.
                    // (Note that on assessment lessons, even though the score does not change if you do
                    // a frame over again, it could get reset back to null after you finish the lesson)
                    // This logic is duplicated server-side in merge_completed_frames
                    const shouldUpdateScore =
                        (this.testOrAssessment && !hasCurrentScore) ||
                        (!this.testOrAssessment && score !== currentScore);

                    if (!shouldUpdateScore) {
                        return;
                    }

                    this.challengeScores[key] = score;

                    // To prevent cheating, we save progress on test and assessment lessons
                    // after every challenge. If there is already a save request in flight, we
                    // skip the save in order to prevent requests from piling up in the queue.
                    // In the case where we skip the save, that response will be saved after the
                    // next challenge or when the user completes the frame.
                    // In order to improve performance, we don't do this on other
                    // lessons. (In that case progress is saved after each frame)
                    if (this.testOrAssessment && !this._flushingProgress && !(this.editorMode || !this.logProgress)) {
                        this._saveLessonAndStreamProgress({
                            activeFrameIndex: this.activeFrameIndex,
                        });
                    }
                },

                //---------------------------------
                // Language switcher
                //---------------------------------

                swapLanguage() {
                    const self = this;

                    if (self.$$showingLanguage === 'en') {
                        self._showTextFrom(this.lesson);
                    } else {
                        self.swappingLanguage = true;
                        self._loadEnglishTranslation().then(response => {
                            const englishTranslation = response.result[0];
                            if (englishTranslation) {
                                self._showTextFrom(englishTranslation);
                            } else {
                                $injector.get('$window').alert('No English translation found.');
                            }
                            self.swappingLanguage = false;
                        });
                    }
                },

                launchBot() {
                    const { getState, dispatch } = storeProvider.store;
                    let conversationId = getConversationIdForFrameId(getState(), this.activeFrameId);

                    if (!conversationId) {
                        conversationId = generateGuid();
                    }

                    if (this._needsInitialBotMessage(conversationId, this.botLaunchOptions.messageContent)) {
                        this.initialBotMessage = this._getInitialBotMessage(conversationId);
                    } else {
                        this.initialBotMessage = null;
                    }

                    dispatch(
                        chatActions.setActiveConversation({
                            conversationId,
                            conversationMetadata: {
                                frameId: this.activeFrameId,
                                uiContext: this.botLaunchOptions.uiContext,
                            },
                        }),
                    );

                    this.botClientContext = this._getBotClientContext();
                    this.showingBot = true;
                },

                hideBot() {
                    this.showingBot = false;
                    this._initialBotMessage = null;
                },

                _loadEnglishTranslation() {
                    if (!this.$$loadEnglishTranslationPromise) {
                        const FrameList = $injector.get('Lesson.FrameList');
                        this.$$loadEnglishTranslationPromise = FrameList.index({
                            filters: {
                                published: true,
                                locale_pack_id: this.lesson.locale_pack.id,
                                locale: 'en',
                            },
                            'fields[]': ['id', 'frames', 'locale'],
                        });
                    }

                    return this.$$loadEnglishTranslationPromise;
                },

                _showTextFrom(lesson) {
                    const self = this;
                    self.$$showingLanguage = lesson.locale;
                    self.$$origTextMap = self.$$origTextMap || {};
                    const getTextFromFrame = lesson.frames[self.activeFrameIndex];

                    if (!getTextFromFrame) {
                        return;
                    }

                    getTextFromFrame
                        .reify()
                        .componentsForType('Text.TextModel')
                        .forEach(getTextFromComponent => {
                            const component = self.activeFrame.getComponentById(getTextFromComponent.id);
                            if (component) {
                                // Once we've swapped languages once, we've replaced the text actually in
                                // the model (which is, admittedly, a bit hackish).  So we have to keep a map
                                // of the texts for each locale so we can switch back.
                                self.$$origTextMap[component.id] =
                                    self.$$origTextMap[component.id] || component.formatted_text;

                                let formattedText;
                                if (lesson === self.lesson) {
                                    formattedText = self.$$origTextMap[component.id];
                                } else {
                                    formattedText = getTextFromComponent.formatted_text;
                                }
                                component.formatted_text = formattedText;
                            }
                        });
                },

                _setChallengeScoresXOfY() {
                    const result = [0, 0];
                    angular.forEach(this.challengeScores, score => {
                        result[1] += 1;
                        if (score === 1) {
                            result[0] += 1;
                        } else if (score !== 0) {
                            throw new Error('Cannot calculate challengeScoresXOfY unless all scores are 0 or 1');
                        }
                    });
                    this.challengeScoresXOfY = result;
                },

                _setSecondsInLesson() {
                    let time = 0;
                    angular.forEach(this.frameDurations, seconds => {
                        time += seconds;
                    });
                    this.secondsInLesson = time;
                },

                _setLessonScore() {
                    // since challenge scores might be stored here
                    // in an instance variable or in lesson progress,
                    // we cannot rely on lesson.lessonScore
                    this.scoreMetadata = {};
                    this.lessonScore = this.lesson.getScore(this.challengeScores, this.scoreMetadata);
                },

                _markAsComplete() {
                    // see comment in PlayerViewModel._logLessonComplete about this
                    // being called more than once
                    if (this.completed) {
                        return;
                    }

                    this.frameBookmarkId = null;
                    this._setChallengeScoresXOfY();
                    this._setSecondsInLesson();
                    this._setLessonScore();
                    this.frameHistory = [];
                    this.completed = true;
                },

                // this handles BOTH of: marking a lesson as started, and saving the latest frame bookmark
                _saveBookmarkedFrame(newBookmarkedFrame) {
                    // do nothing if the frame bookmark is not changing
                    if (newBookmarkedFrame.id === this.frameBookmarkId) {
                        return;
                    }

                    // once a view model has been marked as complete, the user can
                    // still navigate back to previous frames, but we don't update
                    // the frame bookmark id.  See https://trello.com/c/epxiJOP7/932-bug-going-back-from-finish-screen-undoes-frame-bookmark-reset#
                    if (this.completed) {
                        return;
                    }

                    if (this.editorMode || !this.logProgress) {
                        return;
                    }

                    this.frameBookmarkId = newBookmarkedFrame.id;

                    // this might be called before a lesson start event ever happens,
                    // so lesson_bookmark_id might not be set yet.  The server requires it
                    const streamProgress = this.streamProgress;
                    if (streamProgress) {
                        streamProgress.lesson_bookmark_id = this.lesson.id;
                    }

                    this._saveLessonAndStreamProgress({
                        activeFrameIndex: this.activeFrameIndex,
                    });
                },

                // this handles tracking frames seen, challenges seen, and first answers for each challenge
                // should be called after an active frame has been completed and before it changes
                _trackFrameChallengeStats() {
                    const activeFrame = this.activeFrame;

                    // don't track stats in editor or if there's no activeFrame
                    if (!activeFrame || this.editorMode) {
                        return;
                    }

                    // use a hash so we don't double-count frames that are navigated to more than once
                    // this could happen due to a back button, or branching
                    this.completedFrames[activeFrame.id] = true;
                },

                // When we complete the last frame of the lesson, we want to try to get a head start
                // in saving the progress and marking the lesson as complete. We do this for performance
                // reasons because we block the finish screen until this has been done.
                _eagerlyMarkAsCompleteAfterLastFrame() {
                    if (
                        // Only do this on the last frame
                        this.activeFrame.nextFrame() ||
                        // And only if the last frame isn't a branching frame that
                        // could take us back to an arbitrary frame in the lesson
                        this.activeFrame.frameNavigator.has_branching ||
                        // And never in editorMode
                        this.editorMode
                    ) {
                        return;
                    }
                    this._ensureMarkedAsCompleteAndFlushed();
                },

                // timer can be an object created by timerSingleton.startTimer or
                // it can be undefined. It is used for performance tracing.
                _ensureMarkedAsCompleteAndFlushed(timer) {
                    // This method will be called from the frame_completed callback when the final
                    // frame is completed. Then it will be called again from _showFinishScreenWhenReady when
                    // the user clicks to move on to the finish screen.
                    //
                    // The second time it's called, we just return
                    // the promise that was created the first time. We don't repeat any of the logic.
                    //
                    // Ideally, when the second call happens, everything will already be finished
                    // and we won't need to wait at all. We trace different steps in the two different
                    // situations so we can make sense of any performance issues in sentry.
                    let finishPreviouslyStartedSaveStep;
                    if (this._markAsCompleteAndFlushPromise) {
                        finishPreviouslyStartedSaveStep = timer?.sentryTransaction?.startChild({
                            description: 'finishPreviouslyStartedSave',
                        });
                    }

                    if (!this._markAsCompleteAndFlushPromise) {
                        this._markAsCompleteAndFlushPromise = this._markAsCompleteAndFlush(timer);
                    }

                    return this._markAsCompleteAndFlushPromise.then(() => {
                        finishPreviouslyStartedSaveStep?.finish();
                    });
                },

                // timer can be an object created by timerSingleton.startTimer or
                // it can be undefined. It is used for performance tracing.
                _markAsCompleteAndFlush(timer) {
                    // We want to wait for all challenges to be saved to the server (via the flush() call)
                    // before we calculate the score (via the _markAsComplete() call).  This is necessary for our
                    // cheating prevention to work correctly, since external changes
                    // might be pushed down from the server when we record the final challenge.
                    // Unfortunately, it means that we have to make two round trips to the server
                    const ensureProgressFlushedStep = timer?.sentryTransaction?.startChild({
                        description: 'ensureProgressFlushed',
                    });
                    let markAsCompleteStep;

                    return this.ensureProgressFlushed()
                        .then(() => {
                            // If the user exited the lesson while waiting for progress to flush,
                            // don't mark the lesson as complete.
                            if (this.destroyed) return false;
                            ensureProgressFlushedStep?.finish();

                            markAsCompleteStep = timer?.sentryTransaction?.startChild({
                                description: 'markAsCompleteStep',
                            });

                            // Setting the lesson as completed will trigger another progress save and flush. The fact
                            // that we're not waiting on that flush before resolving means that we do not guarantee
                            // that progress has been saved to the server before showing the finish screen.
                            this._markAsComplete();
                            return this.ensureProgressFlushed();
                        })
                        .then(() => {
                            markAsCompleteStep?.finish();
                        });
                },

                // Look back through the conversation and see if an initial message has already been sent.
                // If we find one, check if it has the same payload as we would use now if we sent an initial
                // message. If there is already an initial message with the same payload, we do not need
                // to send another one.
                _needsInitialBotMessage(conversationId, messageContent) {
                    const state = storeProvider.store.getState();
                    const lastInitialMessage = getLastInitialMessage(state, conversationId);
                    if (!lastInitialMessage) return true;

                    // If the message changed, that means that we were in an explain screen context before, and
                    // now we're in a lesson feedback for context, or something like that.
                    if (messageContent !== lastInitialMessage.content) return true;

                    const lastInitialMessagePayload = lastInitialMessage.payload;
                    return !deepEqual(lastInitialMessagePayload, this._getInitialMessagePayload());
                },

                _getInitialMessagePayload() {
                    if (this.botLaunchOptions.uiContext === UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL) {
                        return null;
                    }

                    if (this.botLaunchOptions.uiContext === UI_CONTEXT_LESSON_PLAYER) {
                        const payload = {
                            ...this._frameInfoForBotClientContext,
                        };
                        if (
                            [
                                EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
                                LESSON_FEEDBACK_FORM_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
                            ].includes(this.botLaunchOptions.messageContent)
                        ) {
                            const { activeFrameComplete, currentChallengeIndex } = this.activeFrameBotInfo;
                            payload.currentChallengeIndex = currentChallengeIndex;
                            payload.activeFrameComplete = activeFrameComplete;
                        }
                        return payload;
                    }
                    throw new Error(`Unsupported uiContext: ${this.botLaunchOptions.uiContext}`);
                },

                // See comment near the BaseMessage type about what it means to be an "initial" message
                _getInitialBotMessage(conversationId) {
                    // When using the lesson player ui context, the client has to send a
                    // tutor bot system message to trigger the bot to generate a message
                    if (this.botLaunchOptions.uiContext === UI_CONTEXT_LESSON_PLAYER) {
                        const payload = this._getInitialMessagePayload();
                        return createTutorBotSystemMessage({
                            inputs: { content: this.botLaunchOptions.messageContent, payload },
                            conversationId,
                            isInitialMessage: true,
                        });
                    }

                    // When using the review previous material ui context, the first ai message
                    // is pregenerated, so we just need to add it to the conversation as a pretend ai message
                    if (this.botLaunchOptions.uiContext === UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL) {
                        return createPretendAiMessage({
                            content: this.botLaunchOptions.messageContent,
                            conversationId,
                            isInitialMessage: true,
                        });
                    }

                    throw new Error(`Unsupported uiContext: ${this.botLaunchOptions.uiContext}`);
                },

                _getBotClientContext() {
                    if (this.botLaunchOptions.uiContext === UI_CONTEXT_LESSON_PLAYER) {
                        return {
                            uiContext: UI_CONTEXT_LESSON_PLAYER,
                            lessonId: this.lesson.id,
                            lessonTitle: this.lesson.title,
                            frameDescriptions: this._frameDescriptionsForTutorBot,
                        };
                    }

                    if (this.botLaunchOptions.uiContext === UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL) {
                        return {
                            uiContext: UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL,
                            previousLessonOutline: this.previousLessonOutline,
                        };
                    }

                    throw new Error(`Unsupported uiContext: ${this.botLaunchOptions.uiContext}`);
                },
            };
        });
    },
]);
