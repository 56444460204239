import { type AnyObject } from '@Types';
import { type Scholarship, NO_SCHOLARSHIP } from 'Scholarship';
import { TuitionPlanFrequency } from 'TuitionPlan';

// A method similar to this exists on the server for displaying Scholarship labels.
// See scholarship.rb#admin_display_string
export const getAdminDisplayString = (scholarships?: Scholarship[] | null) => {
    if (scholarships?.length) {
        return scholarships
            .map(scholarship => {
                const { level, label, amount } = scholarship;

                if (level) {
                    let str = `Level ${level}`;
                    if (label) str += ` - ${label}`;
                    str += ` - $${amount}`;
                    return str;
                }

                // LEGACY: custom scholarships
                return `Custom Scholarship - $${amount}`;
            })
            .join(', ');
    }

    return NO_SCHOLARSHIP;
};

type ScholarshipMap = AnyObject<Scholarship[]>;

export const scholarshipsByLevel = (scholarships: Scholarship[]) =>
    scholarships.reduce<ScholarshipMap>((prev, next) => {
        if (prev[next.level]) {
            prev[next.level].push(next);
            prev[next.level].sort((a, b) => (a.amount || 0) - (b.amount || 0));
        } else {
            prev[next.level] = [next];
        }

        return prev;
    }, {});

export default getAdminDisplayString;

const tuitionPlanSortKeys = {
    [TuitionPlanFrequency.Once]: 0,
    [TuitionPlanFrequency.Annual]: 1,
    [TuitionPlanFrequency.Monthly]: 2,
    [TuitionPlanFrequency.MonthlyExtended]: 3,
    [TuitionPlanFrequency.FullScholarship]: 4,
    [TuitionPlanFrequency.Custom]: 5,
    [TuitionPlanFrequency.BiAnnual]: 6,
};

type TuitionPlanSortable = AnyObject & { frequency: TuitionPlanFrequency };

export const sortTuitionPlans = (a: TuitionPlanSortable, b: TuitionPlanSortable) =>
    tuitionPlanSortKeys[a.frequency] < tuitionPlanSortKeys[b.frequency] ? -1 : 1;
