import { type AnyObject } from '@Types';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { type CohortIguanaObject } from 'Cohorts';
import { type TranslationHelperClass } from 'Translation';
import { type navigationHelper } from 'Navigation';
import AdmissionsGuidanceModule from 'AdmissionsGuidance/angularModule/scripts/admissions_guidance_module';
import ScheduleTemplate from 'AdmissionsGuidance/angularModule/views/schedule_box.html';
import projectResource from 'vectors/project_resource.svg';
import { type StreamIguanaObject } from 'Lessons';
import { type CurriculumStatus } from 'ProgramInclusion';
import { SidebarBoxKey, type SidebarBoxConfig } from '../StudentDashboard.types';
import {
    getCoursesFromLocalePackIds,
    getPeriodDates,
    streamsByLocalePackId,
    createTitleFromPeriod,
    hideThisWeeksScheduleWatcher,
} from './scheduleHelpers';

type Params = {
    TranslationHelper: TranslationHelperClass;
    curriculumStatus: CurriculumStatus | null;
    relevantCohort: CohortIguanaObject;
    formatsText: AnyObject<(text: string) => string>;
    loadUrl: ReturnType<typeof navigationHelper>['loadUrl'];
};

const desktopContentTemplate = cacheAngularTemplate(
    AdmissionsGuidanceModule,
    'AdmissionsGuidance/Schedule',
    ScheduleTemplate,
);

// eslint-disable-next-line max-lines-per-function
export const scheduleConfig = ({
    TranslationHelper,
    formatsText,
    relevantCohort,
    loadUrl,
    curriculumStatus,
}: Params): SidebarBoxConfig => {
    const translationHelper = new TranslationHelper('lessons.stream.student_dashboard_schedule');
    const description = relevantCohort.currentPeriod?.description
        ? formatsText.processMarkdown(relevantCohort.currentPeriod.description)
        : undefined;

    return {
        key: SidebarBoxKey.schedule,
        scope: {
            projectResource,
            loadUrl,
            curriculumStatus,
            relevantCohort,
            periodDates: getPeriodDates(relevantCohort),
            currentPeriod: relevantCohort.currentPeriod,
            requiredCourses: [],
            optionalCourses: [],
            description,
            submitProject: () => {
                loadUrl(`/projects/upload?cohort_name=${relevantCohort.name}`, '_blank');
            },
            openExternalLink: (url: string) => {
                loadUrl(url, '_blank');
            },
        },
        watchers: {
            ...hideThisWeeksScheduleWatcher,
            streams: (scope, newValue) => {
                scope.streamsByLocalePackId = streamsByLocalePackId((newValue as StreamIguanaObject[]) || []);
            },
            currentPeriod: (scope, newValue) => {
                const currentPeriod = newValue as CohortIguanaObject['currentPeriod'];
                const streams = scope.streamsByLocalePackId as AnyObject<StreamIguanaObject>;

                if (currentPeriod) {
                    scope.requiredCourses = getCoursesFromLocalePackIds(
                        streams,
                        currentPeriod.requiredStreamLocalePackIds || [],
                    );

                    scope.optionalCourses = getCoursesFromLocalePackIds(
                        streams,
                        currentPeriod.optionalStreamLocalePackIds || [],
                    );
                }
            },
        },
        desktopContentTemplate,
        heading: createTitleFromPeriod(relevantCohort.currentPeriod, formatsText),
        buttons: [
            {
                id: 'button_schedule',
                text: translationHelper.get('view_full_schedule'),
                onClick: () => {
                    loadUrl(relevantCohort.external_schedule_url, '_blank');
                },
            },
        ],
    };
};

export default scheduleConfig;
