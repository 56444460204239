import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { useTranslation } from 'react-i18next';
import { useSearchResults } from '../useSearchResults';
import { SearchResultsSection } from '../SearchResultsSection';
import { useAdmissionsTab } from '../useAdmissionsTab';
import { ROUTES } from '../ResourcesRoutes';

// eslint-disable-next-line max-lines-per-function
export function SearchResults() {
    const { t } = useTranslation('back_royal');
    const { showAdmissionsTab } = useAdmissionsTab();
    const [{ query }] = useTypedSearchParams(ROUTES.RESOURCES.SEARCH);
    const { articlesData, status } = useSearchResults({ query });

    const categorySlugsToTitles = {
        [ROUTES.RESOURCES.$.ADMISSIONS.relativePath]: t('resources.resources.admissions'),
        [ROUTES.RESOURCES.$.ACADEMIC_AND_PROGRAM_SUPPORT.relativePath]: t(
            'resources.resources.academic_and_program_support_title',
        ),
        [ROUTES.RESOURCES.$.CAREER_SERVICES.relativePath]: t('resources.resources.career_services'),
        [ROUTES.RESOURCES.$.LIBRARY_SERVICES.relativePath]: t('resources.resources.library_services'),
        [ROUTES.RESOURCES.$.STUDENT_EVENTS.relativePath]: t('resources.resources.student_events'),
    };

    const results =
        Object.entries(categorySlugsToTitles)
            // suppress the Admissions results if showAdmissionsTab is false
            .filter(([categorySlug, _title]) =>
                showAdmissionsTab ? true : categorySlug !== ROUTES.RESOURCES.$.ADMISSIONS.relativePath,
            )
            // Collect the articles for each category -- note that an article can be in multiple categories.
            // This filters out articles that aren't in any of the categorySlugsToTitles (the Student
            // Resources collection(s)), but we use the search results in other collections to know whether
            // to show the hasMoreResults link.
            .map(([categorySlug, title]) => ({
                title,
                articles: articlesData
                    ? articlesData.filter(article => (article.categorySlugs || []).includes(categorySlug))
                    : [],
            })) ?? [];

    const totalResults = results.reduce((counter, currentItem) => counter + currentItem.articles.length, 0);

    const filteredResults = results.filter(category => category.articles.length !== 0);

    const displayedArticleIds = filteredResults.flatMap(({ articles }) => articles.map(({ id }) => id));
    const hasMoreResults = articlesData ? articlesData.some(item => !displayedArticleIds.includes(item.id)) : false;

    return (
        <div className="bg-white">
            <SearchResultsSection {...{ results: filteredResults, totalResults, status, hasMoreResults }} />
        </div>
    );
}
