const config = {
    build_number: '598634',
    build_timestamp: '2025-01-24T17:23:18.548Z',
    build_commit: '8de8892e0e866b92fc6efc2250f8298d50366f68',
};

// locally, the build number is always 4122 (which allows us to load non-English content locally)
if (Number.isNaN(parseInt(config.build_number, 10))) {
    config.build_number = '4122';
}

export default config;
