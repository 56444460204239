import React from 'react';
import { type UserManagementActionType } from '../UserManagementActions/UserManagementAction.types';
import { HandlePaymentOptionVal, type HandlePaymentOption } from './PaymentSelect.types';

const WhatHappensOnGracePeriodEnd = () => (
    <>
        <p>
            After this time, if the payment situation has not been resolved, the student will see a message telling them
            that they need to make payments and that they should contact the bursar.
        </p>
        <p>
            If the student is not in a good payment state before the start of the cohort, the student&apos;s content
            will be locked.
        </p>
    </>
);

const manualPaymentMessage = (purpose: string) => (
    <>
        <p>
            Once this form is submitted, a 20 minute grace period will be assigned to allow time to {purpose}. During
            this time, the student will be considered to be in a good payment state.
        </p>
        <WhatHappensOnGracePeriodEnd />
    </>
);

const PaymentGracePeriodMessage = () => (
    <>
        <p>Up until the date entered below, the student will be considered to be in a good payment state.</p>
        <WhatHappensOnGracePeriodEnd />
    </>
);

export const paymentRequiredPaymentOptions: HandlePaymentOption[] = [
    {
        label: 'I plan to immediately record a payment for the full amount of the contract (or I already have recorded a payment)',
        value: HandlePaymentOptionVal.FullAmountPayment,
        desc: (
            <div data-testid={HandlePaymentOptionVal.FullAmountPayment}>{manualPaymentMessage('record a payment')}</div>
        ),
    },
    {
        label: 'I plan to send an invoice to the user for the full amount of the contract',
        value: HandlePaymentOptionVal.FullAmountInvoice,
        desc: (
            <div data-testid={HandlePaymentOptionVal.FullAmountInvoice}>
                <PaymentGracePeriodMessage />
            </div>
        ),
    },
    {
        label: 'I plan to set up a subscription for the user',
        value: HandlePaymentOptionVal.SubscriptionManual,
        desc: (
            <div data-testid={HandlePaymentOptionVal.SubscriptionManual}>
                {manualPaymentMessage('set up a subscription')}
            </div>
        ),
    },
    {
        label: 'I would like for a subscription to be created for the user',
        value: HandlePaymentOptionVal.SubscriptionAuto,
        desc: (
            <div data-testid={HandlePaymentOptionVal.SubscriptionAuto}>
                <p>
                    This option will automatically create a subscription for the user based on the options selected
                    above. Please verify that the subscription is created successfully in Stripe after submitting this
                    form.
                </p>
                <PaymentGracePeriodMessage />
            </div>
        ),
    },
    {
        label: 'I would like to set a payment grace period for the user',
        value: HandlePaymentOptionVal.PaymentGracePeriod,
        desc: (
            <div data-testid={HandlePaymentOptionVal.PaymentGracePeriod}>
                <PaymentGracePeriodMessage />
            </div>
        ),
    },
];

export const fullScholarshipPaymentOptions: HandlePaymentOption[] = [
    {
        label: 'No payments are required',
        value: HandlePaymentOptionVal.NoPaymentsRequired,
        desc: <div data-testid={HandlePaymentOptionVal.NoPaymentsRequired} />,
    },
];

export function showDatePicker(handlePaymentOption: HandlePaymentOptionVal) {
    return [
        HandlePaymentOptionVal.FullAmountInvoice,
        HandlePaymentOptionVal.PaymentGracePeriod,
        HandlePaymentOptionVal.SubscriptionAuto,
    ].includes(handlePaymentOption);
}

export function useHandlePaymentOptions(
    isFullScholarship: boolean,
    isOneTimePlan?: boolean,
    actionType?: UserManagementActionType,
) {
    let options = isFullScholarship ? fullScholarshipPaymentOptions : paymentRequiredPaymentOptions;

    if (isOneTimePlan) {
        options = options.filter(
            // One-time plans do not support subscriptions
            option =>
                option.value !== HandlePaymentOptionVal.SubscriptionManual &&
                option.value !== HandlePaymentOptionVal.SubscriptionAuto,
        );
    }
    switch (actionType) {
        case 'RegisterStudent':
            return options.filter(
                // RegisterStudent does not yet support 'I would like for a subscription to be created for the user'
                option => option.value !== HandlePaymentOptionVal.SubscriptionAuto,
            );
        case 'ChangeRegistration':
            return options.filter(
                // ChangeRegistration no longer supports 'I plan to set up a subscription for the user'
                option => option.value !== HandlePaymentOptionVal.SubscriptionManual,
            );
        default:
            return options;
    }
}
