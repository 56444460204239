import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/more_about_you_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('moreAboutYouForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const TranslationHelper = $injector.get('TranslationHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const Cohort = $injector.get('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                scope.isNonEnglish = FormHelper.isNonEnglish();
                scope.moreAboutYouStep = scope.steps.find(s => s.stepName === 'more_about_you');

                scope.helper = EditCareerProfileHelper;
                const translationHelper = new TranslationHelper('careers.edit_career_profile.more_about_you_form');

                scope.helper.logEventForApplication('viewed-more_about_you');

                scope.programTitle = Cohort.standaloneShortProgramTitle(scope.programType);
                scope.isDegreeProgram = Cohort.isDegreeProgram(scope.programType);

                scope.awardsPlaceholder = translationHelper.get('add_an_award');

                scope.reasonForApplyingOptionKeys = [
                    'start_own_company',
                    'advance_in_current_company',
                    'get_better_job',
                    'expand_business_knowledge',
                    'more_effective_leader',

                    // New options from https://trello.com/c/7ukMVFNq
                    'advance_in_career',
                    'network_with_top_tier_professionals',

                    // This option is at the bottom of the list on purpose since
                    // it's focused mainly towards Ivy Exec users registering from
                    // the /offer/ivy-exec dynamic landing page.
                    'complimentary_lessons',
                ];

                scope.attributeKeys = [
                    'creative',
                    'competitive',
                    'conscientious',
                    'loyal',
                    'resilient',
                    'optimistic',
                    'driven',
                    'flexible',
                    'balanced',
                    'persuasive',
                    'compassionate',
                    'courageous',
                    'motivating',
                    'meticulous',
                    'high_energy',
                ];

                scope.motivationKeys = [
                    'results',
                    'helping_others',
                    'career_advancement',
                    'innovation',
                    'fellowship',
                    'personal_growth',
                    'respect',
                    'security',
                ];

                scope.strengthKeys = [
                    'problem_solving',
                    'leadership',
                    'business_acumen',
                    'professionalism',
                    'entrepreneurial',
                    'relationship_building',
                    'work_ethic',
                    'design_aesthetic',
                    'communication',
                    'resourcefulness',
                ];

                FormHelper.supportForm(scope, scope.more_about_you);
                FormHelper.supportAutoSuggestOptions(scope);

                const propsValuesMap = {
                    race: scope.raceKeys,
                };
                FormHelper.supportCheckboxGroups(scope, scope.careerProfile, propsValuesMap);

                scope.getOptionsAndFilter = (type, searchText) =>
                    scope.getOptionsForType(type, searchText).then(options => {
                        // filter the response
                        const existingTexts = _.map(scope.careerProfile[type], 'text');

                        return _.reject(options, option => _.includes(existingTexts, option.text));
                    });

                scope.getOptionsForAwardsAndInterests = searchText =>
                    scope.getOptionsAndFilter('awards_and_interests', searchText);
            },
        };
    },
]);
