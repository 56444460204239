import { useTranslation, Trans } from 'react-i18next';
import { setupBrandNameProperties } from 'AppBranding';
import { navigationHelper } from 'Navigation';

export default function PartialAccessModal(props) {
    const { dismissPartialAccessModal, $injector } = props;
    const $rootScope = $injector.get('$rootScope');
    const dateHelper = $injector.get('dateHelper');
    const { loadRoute } = navigationHelper();

    const currentUser = $rootScope.currentUser;

    let admissionDeadline;
    const scope = { currentUser };
    setupBrandNameProperties($injector, scope);
    const { brandNameShort } = scope;

    const showApply =
        (!currentUser.hasEverApplied || currentUser.isNotJoiningProgramOrHasBeenExpelled) &&
        currentUser.relevantCohort.isDegreeProgram;

    if (showApply) {
        const applicableAdmissionRound = currentUser.relevantCohort.getApplicableAdmissionRound();
        admissionDeadline =
            applicableAdmissionRound &&
            dateHelper.formattedUserFacingMonthDayLong(applicableAdmissionRound.applicationDeadline);
    }

    const { t } = useTranslation('back_royal');

    return (
        <div className="partial-access-modal" style={{ left: 'auto', top: '20px', height: 'auto' }}>
            <button
                type="button"
                className="close-button"
                aria-label="Close"
                onClick={() => dismissPartialAccessModal.setShowPartialAccessModal()}
            />

            <div className="main-content">
                <p>
                    {brandNameShort &&
                        t('student_network.partial_access_modal.explain_react', { brandName: brandNameShort })}
                </p>
                <p>{t('student_network.partial_access_modal.instructions')}</p>
            </div>

            {showApply && admissionDeadline && (
                <div className="apply-content">
                    <p>
                        <Trans
                            i18nKey="back_royal:student_network.partial_access_modal.deadline_react"
                            components={[<br />, <span />]}
                            values={{ date: admissionDeadline }}
                        />
                    </p>

                    <button
                        type="button"
                        className="flat round white"
                        onClick={() => loadRoute('/settings/application')}
                    >
                        {t('student_network.partial_access_modal.apply_now')}
                    </button>
                </div>
            )}
        </div>
    );
}
