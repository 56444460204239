import { type FormConfig } from 'FormConfigs';

export interface NavigationSectionConfig {
    name: NavigationSectionKey;
    isActive?: (section: NavigationSectionKey) => boolean;
    labelKey?: string;
    subsections?: FormConfig[];
    shouldBeVisible: () => boolean;
    onClick: (arg?: boolean) => void;
}

export enum NavigationSectionKey {
    application = 'application',
    application_status = 'application_status',
    account = 'account',
    my_profile = 'my-profile',
    billing = 'billing',
    nominations = 'nominations',
    preferences = 'preferences',
    documents = 'documents',
    sign_out = 'sign_out',
    faq = 'faq',
    switch_program = 'switch_program',
    feedback = 'feedback',
    grades = 'grades',
    select_program = 'select-program',
}

export type FrontRoyalRouteService = angular.route.IRouteService & {
    current: ExposedCurrentRouteService | undefined;
    frontRoyalIsResolvingRoute: boolean;
};

export type ExposedCurrentRouteService = angular.route.ICurrentRoute & {
    $$route: FrontRoyalDollarDollarRoute | undefined;
};

export type FrontRoyalDollarDollarRoute = {
    directive: string;
};

export type AppHeaderViewModel = {
    setBodyBackground: (background: string) => void;
    showAlternateHomeButton: boolean;
    setTitleHTML: (title: string) => void;
    hideMobileMenu: boolean;
};

export interface InAppBrowser {
    addEventListener: (arg0: string, arg1: (event: { url: string }, callback: (arg0: string) => void) => void) => void;
    close: () => void;
    open: (url?: string | URL, target?: string, features?: string) => this;
}

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top' | '_system';
