import { type auto } from 'angular';
import { type FrontRoyalStore, type Image } from 'FrontRoyalStore';
import StorableImageError from './StorableImageError';

export default class StoredImagesRepo {
    urls: string[] = [];
    injector: auto.IInjectorService;
    frontRoyalStore: FrontRoyalStore;
    imagesFetched: Image[] = [];
    urlsToRetry: string[] = [];

    constructor(urls: string[], injector: auto.IInjectorService) {
        this.urls = urls;
        this.injector = injector;
        this.frontRoyalStore = this.injector.get('frontRoyalStore');
    }

    async fetchAndStoreImages() {
        await this.fetchImages(this.urls, 'retry');
        await this.fetchImages(this.urlsToRetry);

        // Originally, we tried to put images in bulk but sometimes we ran into
        // "Failed to write Data Blob" errors. We made that go away by putting the images
        // into the db one-by-one. Around 10/3/2022 we went back to the bulk put to speed things
        // up. If we hit the same error again, we should catch it and fall back to putting the images
        // one-by-one. (consider the promise-retry library)
        return this.frontRoyalStore.retryRequestOnHandledError(
            'storeImages',
            this.imagesFetched,
            // See comment near similar line in getStreamAndEnsureStored
            { errorHandlingPolicies: { throwOnStorageQuotaExceededError: true } },
        );
    }

    async fetchImages(urls: string[], onError?: string) {
        if (!urls.length) return;

        await Promise.all(urls.map(async url => this.fetchArrayBuffer(url, onError), this));
    }

    async fetchArrayBuffer(url: string, onError = 'throw') {
        // If this stream was previously stored, then the image might already be in the db. In that case,
        // we shouldn't bother fetching it. We used to check for all the loaded images in bulk further up,
        // but that led to an error inside of Dexie.
        const imageExists = await this.frontRoyalStore.retryOnHandledError(db => db.images.get(url));
        if (imageExists) return; // If an image is already stored in the db, don't fetch it

        const genericFailureMsg = `Failed to fetch image ${url}`;
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(genericFailureMsg);
            const data = await (await response.blob()).arrayBuffer();
            this.imagesFetched.push({
                url,
                data,
            });
        } catch (error) {
            if (onError === 'retry') {
                this.urlsToRetry.push(url);
                return;
            }
            throw new StorableImageError((error instanceof Error && error.message) || genericFailureMsg, {
                cause: error,
            });
        }
    }
}
