export const MESSAGE_INPUT_MAX_LENGTH = 2000;
export const HAS_SEEN_INITIAL_GREETING = 'hasSeenInitialGreeting';
export const EVENT_MESSAGE_SENT = 'tutorbot:message_sent';
export const EVENT_STUDENT_EVALUATED_MESSAGE = 'tutorbot:student_evaluated_message';
export const REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT = 'REVIEW_LESSON';
export const EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT = 'EXPLAIN_SCREEN';
export const LESSON_FEEDBACK_FORM_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT = 'LESSON_FEEDBACK_FORM';
export const UI_CONTEXT_LESSON_PLAYER = 'lesson_player';
export const UI_CONTEXT_BOT_PAGE = 'bot_page';
export const UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL = 'review_previous_material';
