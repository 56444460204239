import angularModule from '../../../../../../editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel', [
    '$injector',
    $injector => {
        const ComponentEditorViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        );
        const ThisOrThatEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ThisOrThat',
        );
        const BasicMultipleChoiceEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.BasicMultipleChoice',
        );
        const FillInTheBlanksEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.FillInTheBlanks',
        );
        const BlanksOnImageImageEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.BlanksOnImage',
        );
        const MatchingEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.Matching',
        );
        const ImageHotspotEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ImageHotspot',
        );
        const ComposeBlanksEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ComposeBlanks',
        );
        const ComposeBlanksOnImageEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ComposeBlanksOnImage',
        );
        const MultipleChoicePollEditorTemplate = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.MultipleChoicePoll',
        );
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const ComponentOverlayModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel',
        );
        const TextImageInteractiveEditorViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveEditorViewModel',
        );
        const SharedAnswerListMixin = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.SharedAnswerListMixin',
        );
        const HasInteractiveCardsMixin = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.HasInteractiveCards',
        );
        const SequentialOrConsumableMixin = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.SequentialOrConsumableMixin',
        );
        const MaxTextLengthConfig = $injector.get('MaxTextLengthConfig');
        const NoIncorrectAnswersMixin = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.NoIncorrectAnswersMixin',
        );
        const CheckManyMixin = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.CheckManyMixin',
        );
        const HasBranchingMixin = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.HasBranchingMixin',
        );

        const ChallengesEditorViewModel = ComponentEditorViewModel.subclass(function () {
            this.setModel('Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesModel');

            this.include(SharedAnswerListMixin);
            this.include(HasInteractiveCardsMixin);
            this.include(SequentialOrConsumableMixin);
            this.include(ThisOrThatEditorTemplate);
            this.include(BasicMultipleChoiceEditorTemplate);
            this.include(FillInTheBlanksEditorTemplate);
            this.include(BlanksOnImageImageEditorTemplate);
            this.include(MatchingEditorTemplate);
            this.include(ImageHotspotEditorTemplate);
            this.include(ComposeBlanksEditorTemplate);
            this.include(ComposeBlanksOnImageEditorTemplate);
            this.include(MultipleChoicePollEditorTemplate);
            this.include(NoIncorrectAnswersMixin);
            this.include(CheckManyMixin);
            this.include(HasBranchingMixin);

            this.supportConfigOption('newChallengeType');
            this.supportConfigOption('userDefinedOptions');
            this.supportConfigOption('allowSetContextImages');
            this.supportConfigOption('disallowAddAndReorderChallenges');
            this.supportConfigOption('maxRecommendedTextLengthWithoutImage');
            this.supportConfigOption('maxRecommendedTextLengthWithImage');

            this.onConfigChange('newChallengeType', function (challengeType) {
                if (challengeType) {
                    this.model.challenges.forEach(challenge => {
                        if (!challenge.isA(challengeType)) {
                            challenge.remove();
                        }
                    });
                }
            });

            this.onConfigChange('maxRecommendedTextLengthWithoutImage', function () {
                this._updateMainTextComponentConfig();
            });
            this.onConfigChange('maxRecommendedTextLengthWithImage', function () {
                this._updateMainTextComponentConfig();
            });

            Object.defineProperty(this.prototype, 'mainImage', {
                // Logic is very similar to challenges.rb#single_main_image_relevant_to_all_challenges
                get() {
                    if (this.interactiveCards && this.interactiveCards.editorViewModel.firstImage) {
                        return this.interactiveCards.editorViewModel.firstImage;
                    }
                    if (
                        this.model.sharedContentForInteractiveImage &&
                        this.model.sharedContentForInteractiveImage.image
                    ) {
                        return this.model.sharedContentForInteractiveImage.image;
                    }
                    if (this.firstContextImage) {
                        return this.firstContextImage;
                    }
                    if (this.secondContextImage) {
                        return this.secondContextImage;
                    }
                    return undefined;
                },
                set(val) {
                    if (this.interactiveCards) {
                        this.interactiveCards.editorViewModel.firstImage = val;
                    } else if (
                        this.model.sharedContentForInteractiveImage &&
                        this.model.sharedContentForInteractiveImage.isA(ComponentOverlayModel)
                    ) {
                        this.model.sharedContentForInteractiveImage.image = val;
                    } else if (this.config.allowSetContextImages) {
                        this.firstContextImage = val;
                    } else {
                        // do nothing (matching, for example, just does not support main Image)
                    }
                },
            });

            Object.defineProperty(this.prototype, 'firstContextImage', {
                get() {
                    if (
                        this.model.sharedContentForFirstImage &&
                        this.model.sharedContentForFirstImage.isA(ImageModel)
                    ) {
                        return this.model.sharedContentForFirstImage;
                    }
                    return undefined;
                },
                set(val) {
                    if (this.config.allowSetContextImages) {
                        this.model.sharedContentForFirstImage = val;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'secondContextImage', {
                get() {
                    if (
                        this.model.sharedContentForSecondImage &&
                        this.model.sharedContentForSecondImage.isA(ImageModel)
                    ) {
                        return this.model.sharedContentForSecondImage;
                    }
                    return undefined;
                },
                set(val) {
                    if (this.config.allowSetContextImages) {
                        this.model.sharedContentForSecondImage = val;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'sharedComponentOverlayEditorViewModel', {
                get() {
                    if (
                        this.model.sharedContentForInteractiveImage &&
                        this.model.sharedContentForInteractiveImage.isA(ComponentOverlayModel)
                    ) {
                        return this.model.sharedContentForInteractiveImage.editorViewModel;
                    }
                    return undefined;
                },
            });

            return {
                directiveName: 'cf-challenges-editor',

                initialize($super, model) {
                    $super(model);
                    // Default values
                    this.setConfig({
                        maxRecommendedTextLengthWithoutImage: MaxTextLengthConfig.TEXT_WITHOUT_IMAGE,
                        maxRecommendedTextLengthWithImage: MaxTextLengthConfig.TEXT_WITH_IMAGE,
                    });
                },

                setup() {
                    this.model.challenges = [];
                    this.model.layout = TextImageInteractiveEditorViewModel.addComponentTo(this.frame).setup().model;
                    this.model.layout.target = this.model;

                    return this;
                },

                addChallenge(index, overlayEditorViewModel) {
                    const newChallengeType = this.config.newChallengeType;
                    if (!newChallengeType) {
                        throw new Error('Cannot add a challenge when config.newChallengeType is not set');
                    }
                    const EditorViewModel = newChallengeType.EditorViewModel;
                    const newChallenge = EditorViewModel.addComponentTo(this.frame).setup().model;

                    // If there's an overlay, add associated overlay blank
                    if (angular.isDefined(overlayEditorViewModel)) {
                        this.findOrCreateBlankForChallenge(newChallenge, overlayEditorViewModel);
                    }

                    if (angular.isDefined(index)) {
                        this.model.challenges.splice(index, 0, newChallenge);
                    } else {
                        this.model.challenges.push(newChallenge);
                    }

                    return newChallenge.editorViewModel;
                },

                setMainTextComponent(textComponent) {
                    // If there's already something here that is not text, you probably
                    // weren't trying to replace it.  So we'll throw.
                    if (this.model.sharedContentForText && !this.model.sharedContentForText.isA(TextModel)) {
                        throw new Error(
                            `Cannot replace existing ${this.model.sharedContentForText.type} with setter on mainTextComponent.`,
                        );
                    }
                    this.model.sharedContentForText = textComponent;
                },

                _updateMainTextComponentConfig() {
                    // this is a bit of a hack.  it assumes that the mainTextComponent is in
                    // sharedContentForText.  If that ever changes, this will fail
                    // silently
                    this.model.on(
                        'set:sharedContentForText',
                        () => {
                            if (this.mainTextComponent) {
                                this.mainTextComponent.editorViewModel.setConfig({
                                    maxRecommendedTextLength: () => {
                                        // TODO: account for different image heights here
                                        // WARNING: mainInteractiveImage is not defined
                                        if (this.mainImage) {
                                            return this.config.maxRecommendedTextLengthWithImage;
                                        }
                                        return this.config.maxRecommendedTextLengthWithoutImage;
                                    },
                                    showTextToolbar: true,
                                    autoRenderKey: 'renderMainAutomatically',
                                });
                            }
                        },
                        true,
                    );
                },
            };
        });

        return ChallengesEditorViewModel;
    },
]);
