/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type User, UserOnlineState } from '@sendbird/chat';
import { type FC } from 'react';
import clsx from 'clsx';
import { messagingLocation, useGetConnectionStatus, useGetStudentsToMessage } from '../utils';
import { MemberAvatars } from './MemberAvatars';

const classes = {
    listContainer: clsx('h-[calc(100%-36px)]', 'overflow-y-auto'),
    listItem: clsx(
        'flex',
        'min-h-[30px]',
        'w-full',
        'items-center',
        'mb-[15px]',
        'px-[10px]',
        'py-[5px]',
        'cursor-pointer',
        'transition-colors',
        'hover:bg-slate-grey-extra-light/60',
        'hover:rounded-lg',
        'focus:bg-slate-grey-extra-light/60',
        'focus:rounded-lg',
        'focus:outline-none',
    ),
    avatar: clsx('h-[30px]', 'w-[30px]', 'me-2', 'shrink-0'),
    nickname: clsx('font-semibold', 'text-[16px]', 'text-black', 'me-2'),
    status: (online = true) =>
        clsx(
            'rounded-full',
            online ? 'bg-[#21A170]' : 'bg-white border-[1.5px] border-slate-grey',
            'h-[10px]',
            'w-[10px]',
            'me-2',
            'flex-shrink-0',
        ),
    location: clsx('flex', 'text-[16px]', 'text-slate-grey'),
    checkboxContainer: clsx('flex', 'grow', 'justify-end'),
    checkbox: (checked = false) =>
        clsx(
            'w-[20px]',
            'h-[20px]',
            'flex',
            'justify-center',
            'items-center',
            'border',
            'border-black',
            checked ? 'bg-black' : 'bg-white',
        ),
    checkIcon: clsx('text-[16px]', 'text-white'),
};

export const SelectUsersList: FC<{
    users: User[];
    handleListItemClick: (user: User) => void;
    checked: (user: User) => boolean;
}> = ({ users, handleListItemClick, checked }) => {
    const { profilesById } = useGetStudentsToMessage();
    const getConnectionStatus = useGetConnectionStatus();

    return users.length ? (
        <ul className={classes.listContainer}>
            {users.map(u => {
                const userProfile = profilesById[u.userId];
                return (
                    <li
                        key={u.userId}
                        className={classes.listItem}
                        onClick={() => handleListItemClick(u)}
                        tabIndex={0}
                        onKeyDown={e => {
                            if (e.key !== 'Enter') return;
                            e.preventDefault();
                            handleListItemClick(u);
                        }}
                    >
                        <MemberAvatars members={[u]} className={classes.avatar} showOnlineStatus={false} />
                        <div className={classes.nickname}>{u.nickname}</div>
                        <div className={classes.status(getConnectionStatus(u) === UserOnlineState.ONLINE)} />
                        {userProfile && <div className={classes.location}>{messagingLocation(userProfile)}</div>}
                        <div className={classes.checkboxContainer}>
                            {checked(u) ? (
                                <div className={classes.checkbox(true)}>
                                    <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
                                </div>
                            ) : (
                                <div className={classes.checkbox()} />
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    ) : null;
};
