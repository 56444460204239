import { AnimatePresence, motion } from 'framer-motion';
import '@livekit/components-styles';
import {
    type AgentState,
    LiveKitRoom,
    useVoiceAssistant,
    BarVisualizer,
    RoomAudioRenderer,
    VoiceAssistantControlBar,
    DisconnectButton,
} from '@livekit/components-react';
import { type MediaDeviceFailure } from 'livekit-client';
import { memo, useEffect, useMemo, useState } from 'react';
// import { useKrispNoiseFilter } from '@livekit/components-react/krisp';
import { useConnectionDetails } from 'Livekit';
import { LoadingBoundary } from 'LoadingBoundary';
import { errorHandlingComponentsForSubComponents } from 'FrontRoyalErrorBoundary';
import { useSelector } from 'react-redux';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons/faSquareCheck';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoAgentNotification } from './NoAgentNotification';
import { CloseIcon } from './CloseIcon';
import { RoomEventListener } from './RoomEventListener';
import { getTopics } from './voiceCoursePracticeSelectors';
import { type VoiceCoursePracticeAttributes, type PracticeTopic } from './VoiceCoursePractice.types';
// import { NoAgentNotification } from '@/components/NoAgentNotification';

type Props = {
    tokenAttributes: Omit<VoiceCoursePracticeAttributes, 'agentIdentifier'>;
};

function topicIsActive(topic: PracticeTopic) {
    return topic.started && !topic.reviewed;
}

const VoiceCoursePracticeComponent = ({ tokenAttributes }: Props) => (
    // FIXME: this loading boundary isn't doing anything anymore. We're not suspending in there. Maybe
    // we want an error boundary?
    <LoadingBoundary errorActions={errorHandlingComponentsForSubComponents}>
        <VoiceCoursePracticeRoom tokenAttributes={tokenAttributes} />
    </LoadingBoundary>
);

function Topic({ topic }: { topic: PracticeTopic }) {
    const active = useMemo(() => topicIsActive(topic), [topic]);

    return (
        <div className="flex items-center">
            <FontAwesomeIcon icon={topic.reviewed ? faSquareCheck : faSquare} className="mr-2" />
            <span className={active ? 'underline' : ''}>{topic.title}</span>
        </div>
    );
}

function VoiceCoursePracticeRoom({ tokenAttributes: incomingTokenAttributes }: Props) {
    const tokenAttributes = useMemo(
        () => ({ ...incomingTokenAttributes, agentIdentifier: 'coursePractice' }),
        [incomingTokenAttributes],
    );

    const [connectionDetails, unsetConnectionDetails, initializeConnectionDetails] = useConnectionDetails({
        tokenAttributes,
    });
    const [agentState, setAgentState] = useState<AgentState>('disconnected');
    const topics = useSelector(getTopics);

    return (
        <div data-lk-theme="default" className="grid h-full content-center bg-[var(--lk-bg)]">
            <LiveKitRoom
                token={connectionDetails?.participantToken}
                serverUrl={connectionDetails?.serverUrl}
                connect={!!connectionDetails}
                audio
                video={false}
                onMediaDeviceFailure={onDeviceFailure}
                onDisconnected={() => {
                    unsetConnectionDetails();
                }}
                className="grid  items-center"
            >
                <SimpleVoiceAssistant onStateChange={setAgentState} />
                <ControlBar onConnectButtonClicked={initializeConnectionDetails} agentState={agentState} />
                <RoomAudioRenderer />
                <NoAgentNotification state={agentState} />
                <RoomEventListener />
            </LiveKitRoom>

            <div className="flex flex-col space-y-2 pl-2">
                {topics?.map(topic => (
                    <Topic key={topic.title} topic={topic} />
                ))}
            </div>
        </div>
    );
}

function SimpleVoiceAssistant({ onStateChange }: { onStateChange: (state: AgentState) => void }) {
    const { state, audioTrack } = useVoiceAssistant();
    useEffect(() => {
        onStateChange(state);
    }, [onStateChange, state]);
    return (
        <div className="mx-auto h-[100px] max-w-[90vw]">
            <BarVisualizer
                state={state}
                barCount={5}
                trackRef={audioTrack}
                className="agent-visualizer"
                options={{ minHeight: 24 }}
            />
        </div>
    );
}

function ControlBar({
    onConnectButtonClicked,
    agentState,
}: {
    onConnectButtonClicked: () => void;
    agentState: AgentState;
}) {
    // Krisp broke things for us, and it seems like our plan doesn't support it anyway, so we're disabling it for now.
    /**
     * Use Krisp background noise reduction when available.
     * Note: This is only available on Scale plan, see {@link https://livekit.io/pricing | LiveKit Pricing} for more details.
     */
    // const krisp = useKrispNoiseFilter();
    // useEffect(() => {
    //     krisp.setNoiseFilterEnabled(true);
    // }, [krisp]);

    return (
        <div className="relative h-[100px]">
            <AnimatePresence>
                {agentState === 'disconnected' && (
                    <motion.button
                        initial={{ opacity: 0, top: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, top: '-10px' }}
                        transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
                        className="absolute left-1/2 -translate-x-1/2 rounded-md bg-white px-4 py-2 uppercase text-black"
                        onClick={() => onConnectButtonClicked()}
                    >
                        Start a conversation
                    </motion.button>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {agentState !== 'disconnected' && agentState !== 'connecting' && (
                    <motion.div
                        initial={{ opacity: 0, top: '10px' }}
                        animate={{ opacity: 1, top: 0 }}
                        exit={{ opacity: 0, top: '-10px' }}
                        transition={{ duration: 0.4, ease: [0.09, 1.04, 0.245, 1.055] }}
                        className="absolute left-1/2 flex h-8 -translate-x-1/2  justify-center"
                    >
                        <VoiceAssistantControlBar controls={{ leave: false }} />
                        <DisconnectButton>
                            <CloseIcon />
                        </DisconnectButton>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

function onDeviceFailure(error?: MediaDeviceFailure) {
    console.error(error);
    alert(
        'Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab',
    );
}

export const VoiceCoursePractice = memo(VoiceCoursePracticeComponent) as typeof VoiceCoursePracticeComponent;
