import 'FrontRoyalStore/angularModule';
import 'ErrorLogging/angularModule';
import 'FrontRoyalTimer/angularModule';
import 'OfflineMode/angularModule';
import { updateOutdatedStreams } from './updateOutdatedStreams';

angular
    .module('StoredContent', ['OfflineMode', 'FrontRoyalStore', 'FrontRoyal.ErrorLogService', 'FrontRoyalTimer'])
    .run([
        '$injector',
        $injector => {
            const $rootScope = $injector.get('$rootScope');
            const $timeout = $injector.get('$timeout');
            const offlineModeManager = $injector.get('offlineModeManager');
            const EventLogger = $injector.get('EventLogger');

            let ensureStoredContentTimeout;
            const oneMinute = 60 * 1000;
            function ensureStoredContent(delay) {
                $timeout.cancel(ensureStoredContentTimeout);
                if ($rootScope.currentUser) {
                    EventLogger.log('dupe_loading_issue:queue_loading_from_angular_module', {
                        value: delay,
                    });

                    ensureStoredContentTimeout = $timeout(delay);

                    // After running ensureStoredContent, we run it
                    // again every so often, so that we're always responding to
                    // changes in the user's progress.
                    ensureStoredContentTimeout
                        .then(() => {
                            EventLogger.log('dupe_loading_issue:start_update_outdated_streams', {
                                value: delay,
                            });
                            return updateOutdatedStreams($injector);
                        })
                        .then(() => {
                            EventLogger.log('dupe_loading_issue:call_ensure_curriculum', {
                                value: delay,
                            });
                            return offlineModeManager.ensureCurriculumAvailableForOfflineUse();
                        })
                        .then(() => ensureStoredContent(5 * oneMinute))
                        .catch(err => {
                            // prevent unhandled rejection error when $rootScope is destroyed
                            if (err !== 'canceled') throw err;
                        });
                }
            }

            $rootScope.$watchGroup(['currentUser', 'currentUser.pref_locale', 'currentUser.pref_offline_mode'], () => {
                if ($rootScope.currentUser) {
                    EventLogger.log('dupe_loading_issue:trigger_on_user_attr_change', {
                        params: {
                            pref_locale: $rootScope.currentUser?.pref_locale,
                            pref_offline_mode: $rootScope.currentUser?.pref_offline_mode,
                        },
                    });
                }
                ensureStoredContent(0);
            });

            $rootScope.$on('$destroy', () => {
                $timeout.cancel(ensureStoredContentTimeout);
            });
            return offlineModeManager;
        },
    ]);
