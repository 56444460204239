import { Link as NextUiLink } from '@nextui-org/react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { Icon } from 'FrontRoyalIcon';
import { cva } from 'class-variance-authority';
import { type MouseEventHandler, type ReactNode } from 'react';
import { useLinkParams, type LinkTarget } from 'Navigation';

const anchorLink = cva(null, {
    variants: {
        withArrow: {
            true: 'inline-flex gap-[0.35rem]',
        },
        color: {
            brand: 'text-coral hover:text-coral-dark valar:text-blue valar:hover:text-blue-dark',
            'lightish-blue': 'text-lightish-blue',
        },
        allowWrap: {
            true: 'inline',
        },
    },
    defaultVariants: {
        withArrow: false,
        color: 'lightish-blue',
    },
    compoundVariants: [
        {
            color: 'lightish-blue',
            withArrow: false,
            className: 'hover:underline',
        },
        {
            withArrow: true,
            className: 'hover:no-underline',
        },
    ],
});

type AnchorLinkProps = {
    withArrow?: boolean;
    color?: 'brand' | 'lightish-blue';
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    className?: string;
    target?: LinkTarget;
    to?: string;
    state?: unknown;
    replace?: boolean;
    children: ReactNode;
    allowWrap?: boolean;
};

function shouldUseReactRouterDomLink({
    state,
    replace,
    to,
}: {
    state: unknown;
    replace: boolean | undefined;
    to: string | null | undefined;
}) {
    const val = !!state;

    if (!val && replace) {
        throw new Error('replace is only supported when we are using the react-router-dom link component');
    }

    if (val && to && to !== '.') {
        throw new Error('react-router-dom component should only be used when to is "." and state is provided');
    }

    return val;
}

// This component is deprecated. It should not be used in any new places. Use ClickableLink instead.
// See https://trello.com/c/lETaUDCA for an explanation of why/where this component is still used and
// what the plan is for it moving forward
export function AnchorLink(props: AnchorLinkProps) {
    const { children, withArrow, color, className, to, onClick, state, replace, allowWrap } = props;

    if (allowWrap && withArrow) {
        throw new Error('Links with arrows cannot be configured to wrap');
    }

    const useReactRouterDomLink = shouldUseReactRouterDomLink({ state, replace, to });
    let { target } = props;

    let href: string | null | undefined = to;
    ({ href, target } = useLinkParams({ href: to, target }));

    return useReactRouterDomLink ? (
        <ReactRouterDomLink
            className={anchorLink({ withArrow, color, className, allowWrap })}
            target={target}
            to="."
            replace={replace}
            state={state}
        >
            {children}
            {withArrow === true ? <Icon iconName="RightArrow16" className="rtl:rotate-180" /> : null}
        </ReactRouterDomLink>
    ) : (
        <NextUiLink
            className={anchorLink({ withArrow, color, className, allowWrap })}
            target={target}
            href={href || undefined}
            onClick={onClick}
        >
            {children}
            {withArrow === true ? <Icon iconName="RightArrow16" className="rtl:rotate-180" /> : null}
        </NextUiLink>
    );
}
