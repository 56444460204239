import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import { VALAR_DOMAIN, VALAR_DOMAIN_STAGING } from 'PedagoDomainConstants';
import { DEFAULT_SIGNUP_LOCATION } from 'SignupLocations';

// We're ignoring the rules here in order to avoid refactoring the specs in featured_events_box_dir. That one
// gets confusing because the spec relies on an absolute navigationHelper import in featured_events_box_dir
// and a relative one in nvigation_helper_mixin. Probably it should be resolved by splitting up the logic
// in some other way in that directive/spec, but I don't feel like figuring it out right now.
// eslint-disable-next-line quantic/only-relative-within-module
import { navigationHelper } from 'Navigation';

/*
    Adds scope methods for route and url based navigation
*/

angularModule.factory('Navigation.NavigationHelperMixin', [
    '$injector',

    $injector => {
        const $window = $injector.get('$window');
        const isMobile = $injector.get('isMobile');
        const $location = $injector.get('$location');
        const ConfigFactory = $injector.get('ConfigFactory');

        const { getUrlPrefix, loadRoute, loadUrl, loadFaqUrl } = navigationHelper();

        return {
            // FIXME: getUrlPrefix, loadRoute, and loadUrl are only here to not break
            // existing code referencing any of these functions.
            // We should refactor all references to use the `navigationHelper` module,
            // and fix up any specs.
            getUrlPrefix,
            loadRoute,
            loadUrl,
            loadFaqUrl,
            onLink(scope) {
                scope.getUrlPrefix = getUrlPrefix;
                scope.loadRoute = loadRoute;
                scope.loadUrl = loadUrl;
                scope.loadFaqUrl = loadFaqUrl;

                scope.openFAQ = () => {
                    scope.loadFaqUrl('/help', '_blank');
                };

                scope.pricingURL = '/pricing';
                scope.gotoPricing = () => {
                    if ($window.RUNNING_IN_TEST_MODE) {
                        throw new Error('Do not reload page in test mode');
                    }
                    $window.location.assign($window.location.origin + scope.pricingURL);
                };

                scope.gotoForgotPassword = preserveParams => {
                    const urlPrefix = scope.getUrlPrefix();

                    if ($window.CORDOVA && isMobile()) {
                        scope.loadRoute('/onboarding/hybrid/forgot-password', preserveParams);
                    } else {
                        scope.loadRoute(`/${urlPrefix}forgot-password`, preserveParams);
                    }
                };

                scope.gotoJoin = preserveParams => {
                    const urlPrefix = scope.getUrlPrefix();

                    if (urlPrefix.startsWith('valar')) {
                        const config = ConfigFactory.getSync();

                        if (config.appEnvType() === 'development') {
                            $window.location.assign(`http://localhost:8001${DEFAULT_SIGNUP_LOCATION}`);
                        } else if (config.appEnvType() === 'staging') {
                            $window.location.assign(`https://${VALAR_DOMAIN_STAGING}${DEFAULT_SIGNUP_LOCATION}`);
                        } else {
                            $window.location.assign(`https://${VALAR_DOMAIN}${DEFAULT_SIGNUP_LOCATION}`);
                        }
                    } else {
                        scope.loadRoute(`/${urlPrefix}join`, preserveParams);
                    }
                };

                scope.gotoSignIn = (preserveParams, _urlPrefix = '') => {
                    const urlPrefix = _urlPrefix || scope.getUrlPrefix();
                    scope.loadRoute(`/${urlPrefix}sign-in`, preserveParams);
                };

                scope.gotoMarketingHome = () => {
                    let urlPrefix = scope.getUrlPrefix();

                    if (urlPrefix.startsWith('valar')) {
                        const config = ConfigFactory.getSync();
                        if (config.appEnvType() === 'development') {
                            // Ideally, we would send you to the Valar marketing homepage in the Gatsby project at 8001,
                            // but our current development process doesn't require the Gatsby project to be running in
                            // tandem with back royal, so there's no guarantee that the page is up and running. This hole
                            // in our development process can be seen in other places of the app, so rather than try to
                            // do the more correct thing here, we're just being consistent with the existing behavior
                            // and sending you back to the Rails marketing homepage.
                            $window.location.assign($window.location.origin);
                        } else if (config.appEnvType() === 'staging') {
                            $window.location.assign(`https://${VALAR_DOMAIN_STAGING}`);
                        } else {
                            $window.location.assign(`https://${VALAR_DOMAIN}`);
                        }
                    } else {
                        // HACK: should ideally look up valid prefixes that work on the homepage here; just hardcode for now
                        if (urlPrefix !== '/mba') {
                            urlPrefix = '';
                        }
                        $window.location.assign(`${$window.location.origin}/${urlPrefix}`);
                    }
                };

                scope.gotoHome = () => {
                    scope.loadRoute('/home');
                };

                scope.isActive = urls => {
                    for (let i = 0; i < urls.length; i++) {
                        if ($location.path().startsWith(urls[i])) {
                            return true;
                        }
                    }

                    return false;
                };
            },
        };
    },
]);
