import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { Container } from 'Container';
import { Typography } from 'Typography';
import { Image, type ImageProps } from 'Image';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import FrontRoyalSpinner from 'FrontRoyalSpinner';

import searchImage from 'images/resources/search-empty-state.png';
import searchImageRetina from 'images/resources/search-empty-state@2x.png';
import { AnchorLink } from 'AnchorLink';
import { useCurrentUser } from 'FrontRoyalAngular';
import { ProgramType, ProgramTypeConfigs } from 'Program';
import { Brand } from 'AppBranding';
import { getCohort } from 'Users';
import { SlopeBackground } from '../SlopeBackground';
import { type ArticleCard } from '../ArticleCard';
import { ArticleGallery } from '../ArticleGallery';
import { ROUTES } from '../ResourcesRoutes';

const image: ImageProps = {
    mobileImage: {
        width: 204,
        height: 201,
        images: {
            default: searchImage,
            retina: searchImageRetina,
        },
    },
    alt: '',
};

type ResultsProps = {
    title: string;
    articles: ComponentProps<typeof ArticleCard>[];
};

type SearchResultsSectionProps = {
    results: ResultsProps[];
    totalResults: number;
    status: 'loading' | 'success' | 'error';
    hasMoreResults: boolean;
};

function localeSuffixForProgramType(programType: ProgramType) {
    const programTypeConfig = ProgramTypeConfigs[programType];
    if (programTypeConfig.branding === Brand.valar) {
        return 'valar';
    }
    if (programTypeConfig.isExecEd) {
        return 'execEd';
    }
    return 'quantic';
}

// eslint-disable-next-line max-lines-per-function
export function SearchResultsSection({ results, totalResults, status, hasMoreResults }: SearchResultsSectionProps) {
    const { t } = useTranslation('back_royal');
    const [{ query }] = useTypedSearchParams(ROUTES.RESOURCES.SEARCH);

    const currentUser = useCurrentUser();
    const programType = getCohort(currentUser)?.programType ?? ProgramType.mba;
    const searchMoreLink = t(`resources.resources.search_more_link_${localeSuffixForProgramType(programType)}`, {
        query,
    });

    return (
        <section>
            <header className="pb-7.5 pt-13.5 md:pb-10">
                <Container>
                    <Typography variant="header1" className="text-center font-normal text-grey-darker">
                        {t('resources.resources.search_results_found')} “<span className="font-medium">{query}</span>”
                    </Typography>
                    <Typography variant="smallText" className="mt-2.5 text-center text-grey-darker">
                        {status === 'success' ? (
                            parseHtml(t('resources.resources.we_have_found', { count: totalResults }))
                        ) : (
                            <>&nbsp;</>
                        )}
                    </Typography>
                </Container>
            </header>
            <div className="relative overflow-hidden pb-20 pt-20 sm:pt-[135px]">
                <SlopeBackground />
                {status !== 'success' ? (
                    <Container className="relative my-[137px] md:my-[112px]">
                        {status === 'error' ? (
                            <Typography variant="body1" className="text-center text-beige-beyond-beyond-dark">
                                {t('resources.resources.failed_to_load_articles')}
                            </Typography>
                        ) : (
                            <FrontRoyalSpinner />
                        )}
                    </Container>
                ) : (
                    <div>
                        {totalResults ? (
                            <Container className="relative">
                                <div className="space-y-[70px]">
                                    {results.map(section => (
                                        <ArticleGallery
                                            key={section.title}
                                            title={section.title}
                                            subTitle={
                                                t('resources.resources.articles_count', {
                                                    count: section.articles.length,
                                                }) as string
                                            }
                                            items={section.articles}
                                        />
                                    ))}
                                </div>
                                {hasMoreResults ? (
                                    <Typography
                                        variant="body1"
                                        className="mt-[70px] text-center text-beige-beyond-beyond-dark"
                                    >
                                        {t('resources.resources.for_further_search_results')}
                                        <AnchorLink to={searchMoreLink} color="brand" target="_blank">
                                            {parseHtml(t('resources.resources.click_here'))}
                                        </AnchorLink>
                                    </Typography>
                                ) : null}
                            </Container>
                        ) : (
                            <Container className="relative">
                                <div className="h-[201px] text-center drop-shadow-lg">
                                    <Image {...image} imgClassName="inline-flex" />
                                </div>
                                {hasMoreResults ? (
                                    <Typography
                                        variant="body1"
                                        className="mt-[70px] text-center text-beige-beyond-beyond-dark md:mt-[50px]"
                                    >
                                        {t('resources.resources.for_further_search_results')}
                                        <AnchorLink to={searchMoreLink} color="brand" target="_blank">
                                            {parseHtml(t('resources.resources.click_here'))}
                                        </AnchorLink>
                                    </Typography>
                                ) : null}
                            </Container>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
}
