import { type OfflineModeManager } from 'OfflineMode';
import { type TranslationHelperClass } from 'Translation';
import { type DialogModal } from 'DialogModal';
import { type AnyObject } from '@Types';
import { type SafeApply } from 'SafeApply';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { ArticleSiteId } from 'Resources/Resources.types';
import { angularInjectorProvider } from 'Injector';
import { type LinkTarget, type InAppBrowser } from './Navigation.types';
import { modifyHref, modifyTarget } from './linkHelpers';

function loadRoute(path: string, preserveParams?: boolean) {
    const $injector = angularInjectorProvider.requireInjector();
    const $location = $injector.get('$location');
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const safeApply = $injector.get<SafeApply>('safeApply');

    if (preserveParams) {
        $location.path(path);
    } else {
        $location.url(path);
    }

    safeApply($rootScope);
}

function navigate(url: string, target: LinkTarget, features: string | undefined) {
    const $injector = angularInjectorProvider.requireInjector();
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const $window = $injector.get('$window');
    const safeApply = $injector.get<SafeApply>('safeApply');

    $window.open(url, target, features);
    safeApply($rootScope);
}

function confirmNavigationAndNavigate(url: string, target: LinkTarget, features: string | undefined) {
    const $injector = angularInjectorProvider.requireInjector();
    const DialogModal = $injector.get<DialogModal>('DialogModal');
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const translationHelper = new TranslationHelper('navigation.navigation_helper');

    DialogModal.confirm({
        confirmCallback() {
            navigate(url, target, features);
        },
        title: translationHelper.get('you_are_offline'),
        text: translationHelper.get('still_want_to_navigate'),
        buttonContainerClass: 'center',
        confirmButtonClass: 'no-float',
        confirmButtonText: translationHelper.get('continue'),
        size: 'medium',
    });
}

function getUrlPrefix() {
    const $injector = angularInjectorProvider.requireInjector();
    const $window = $injector.get('$window');
    const $route = $injector.get('$route') as { current: { pathParams: AnyObject & { url_prefix: string } } };

    let urlPrefix;
    if ($window.CORDOVA && $window.CORDOVA.forcedUrlPrefix) {
        urlPrefix = $window.CORDOVA.forcedUrlPrefix;
    } else if ($route && $route.current && $route.current.pathParams) {
        urlPrefix = $route.current.pathParams.url_prefix;
    }

    urlPrefix = urlPrefix ? `${urlPrefix}/` : '';
    return urlPrefix;
}

// This code is really old, and I'm not sure if it's still valid. If someone passed a relative URL
// to loadUrl, this will prepend the appropriate domain to it. I don't think this even makes sense since
// we switched to having separate app and marketing domains. I don't think this works in the China region,
// so if we were using it we would have noticed that and updated it.
function modifyRelativeUrl(url: string): string {
    const $injector = angularInjectorProvider.requireInjector();
    const $window = $injector.get('$window');

    if (url.slice(0, 1) === '/') {
        if (getUrlPrefix().startsWith('miyamiya')) {
            return `https://miyamiya.org${url}`;
        }
        return $window.ENDPOINT_ROOT + url;
    }

    return url;
}

function loadUrl(
    url: string | null | undefined,
    target: LinkTarget = '_self',
    features: string | undefined = undefined,
) {
    const $injector = angularInjectorProvider.requireInjector();
    const offlineModeManager = $injector.get<OfflineModeManager>('offlineModeManager');

    if (!url) return undefined;

    // FIXME: we're changing modifyTarget so that target would default to _self
    // in the case of a non-external href. I don't think we pass non-external hrefs
    // here, or what we'd expect if we did. But think about it again
    target = modifyTarget(url, target);
    url = modifyRelativeUrl(url);
    url = modifyHref(url);

    if (offlineModeManager.inOfflineMode) {
        confirmNavigationAndNavigate(url, target, features);
        return undefined;
    }

    navigate(url, target, features);

    return undefined;
}

// Right now, this function is only used in the context of BioSig.
// On web, it will load BioSig's UI in the current window.
// On Cordova, it will load BioSig's UI in the inAppBrowser.
// As such, I didn't feel the need to implement any special handling wrt.
// offline mode, chian region mode, miya miya, etc. like the loadUrl function does.
function loadUrlInAppBrowserIfAvailable(
    url: string,
    features: string,
    attachInAppBrowserEventListenerCallback?: (inAppBrowserReference: InAppBrowser) => void,
) {
    const $injector = angularInjectorProvider.requireInjector();
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const safeApply = $injector.get<SafeApply>('safeApply');
    const $window = $injector.get('$window');

    // Use the InAppBrowser if we have it
    if ($window.cordova?.InAppBrowser) {
        const inAppBrowserReference = $window.cordova.InAppBrowser.open(
            url as string,
            '_blank',
            features,
        ) as InAppBrowser;

        // Consumers of loadUrlInAppBrowserIfAvailable can supply a callback that only gets
        // run when using the InAppBrowser. This is useful for attaching event listeners to
        // the InAppBrowser reference.
        // See also BioSigMixin.
        if (attachInAppBrowserEventListenerCallback) {
            attachInAppBrowserEventListenerCallback(inAppBrowserReference);
        }

        safeApply($rootScope);
    }
    // Fallback to the default behavior
    else {
        loadUrl(url, '_self', features);
    }
}

function loadFaqUrl(relativeUrl: string, target: LinkTarget = '_self', opts: { siteId?: string } & AnyObject = {}) {
    const $injector = angularInjectorProvider.requireInjector();
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const $window = $injector.get('$window');

    relativeUrl = relativeUrl.replace(/\/$/, '');

    // We use the site_id in the URL params on the server to figure out
    // what support site to redirect to. There may in instances, however, where
    // you want to link to a specific FAQ article that belongs to a particular
    // support site that isn't associated with the current user's relevant cohort.
    // In that case, you should use the article's unique path as the url rather
    // than the generalized alias version. Note, however, that the server will default
    // to the Quantic support site for unique FAQ article paths.
    //
    // NOTE: We expect there to be a currentUser here, but we're being defensive
    // just in case.

    const helpScoutArticleSiteId =
        opts.siteId ?? $rootScope.currentUser?.relevantCohort?.helpScoutArticleSiteId ?? ArticleSiteId.quantic;

    let url;
    try {
        const faqUrl = new URL(relativeUrl, $window.ENDPOINT_ROOT);
        faqUrl.searchParams.set('site_id', helpScoutArticleSiteId);
        url = faqUrl.pathname + faqUrl.search + faqUrl.hash;
    } catch (err) {
        // If the URL constructed above isn't a valid URL, we do our best
        // effort to replicate that logic here.
        const search = `?site_id=${helpScoutArticleSiteId}`;
        const fragmentIndex = relativeUrl.indexOf('#');
        if (fragmentIndex === -1) {
            url = relativeUrl + search;
        } else {
            const pathname = relativeUrl.slice(0, fragmentIndex);
            const hash = relativeUrl.slice(fragmentIndex);
            url = pathname + search + hash;
        }
    }

    loadUrl(url, target);
}

function goHome() {
    const $injector = angularInjectorProvider.requireInjector();
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    $rootScope.goHome();
    $rootScope.$apply();
}

export function navigationHelper() {
    return {
        getUrlPrefix,
        loadRoute,
        loadUrl,
        loadUrlInAppBrowserIfAvailable,
        loadFaqUrl,
        goHome,
    };
}
