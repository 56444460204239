import angularModule from 'Settings/angularModule/scripts/settings_module';
import { targetBrandConfig } from 'AppBranding';
import template from 'Settings/angularModule/views/tuition_and_registration/registered_manage_billing.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getTuitionContract } from 'Users';
import { subscriptionIncomplete, subscriptionPastDue } from 'Subscription';
import { tuitionContractComplete, tuitionContractLikelyHasNoInvoices, paymentPerInterval } from 'TuitionContract';
import { formatCurrencyForDisplay } from 'Currency';
import { usesRecurringPayments } from 'TuitionPlan';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('registeredManageBilling', [
    '$injector',

    function factory($injector) {
        const ConfigFactory = $injector.get('ConfigFactory');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const HttpQueue = $injector.get('HttpQueue');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                launchProgramGuide: '&',
            },
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------

                NavigationHelperMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.billingEmail = targetBrandConfig(
                    scope.currentUser,
                    ConfigFactory.getSync(),
                ).emailAddressForUsername('billing');

                scope.billingMessageValues = {
                    billingEmail: scope.billingEmail,
                };

                scope.needsToOpenInBrowser = $window.CORDOVA;

                //-------------------------
                // Locale Keys
                //-------------------------

                // Note that we're using this in a function called from a $watchGroup below. This is
                // because these translations should change when data comes in from Stripe webhooks.
                scope.setLocaleKeys = () => {
                    scope.newBrowserKey = undefined;
                    scope.warningMessageKey = undefined;
                    scope.warningDescriptionKey = undefined;
                    scope.billingMessageKey = undefined;
                    scope.processingPaymentMessageKey = undefined;
                    scope.buttonKey = undefined;

                    if (scope.needsToOpenInBrowser) {
                        scope.newBrowserKey = 'settings.tuition_and_registration.new_browser_payment_details';
                        scope.buttonKey = 'settings.tuition_and_registration.launch_browser';
                    }
                    // If a Subscription is incomplete, it requires some action on behalf of the
                    // user to authorize/confirm payment.
                    // A couple things that could cause an incomplete Subscription:
                    // 1. the user needs to go through Stripe's 3D Secure process.
                    // 2. the user needs to verify microdeposits for an ACH Subscription.
                    else if (scope.subscriptionIncomplete) {
                        scope.warningMessageKey = 'settings.tuition_and_registration.action_required';
                        scope.warningDescriptionKey = 'settings.tuition_and_registration.confirm_payment';
                        scope.billingMessageKey = 'settings.tuition_and_registration.additional_steps_required';
                    }
                    // If a subscription is past_due, we want the user to fix their payment details,
                    else if (scope.subscriptionPastDue) {
                        scope.buttonKey = 'settings.tuition_and_registration.registered_manage_billing_payment_trouble';
                        scope.warningMessageKey = 'settings.tuition_and_registration.trouble_processing_payment';
                        scope.warningDescriptionKey =
                            'settings.tuition_and_registration.please_re_enter_payment_details';
                    }
                    // This has to come before we check userPaidOutsideOfStripe because some
                    // users have paid both through and outside of Stripe.
                    else if (scope.subscription) {
                        const registeredPaymentSmallprintKey =
                            'settings.tuition_and_registration.registered_payment_smallprint';

                        let descriptor = scope.tuitionContract.tuitionPlan.frequency;
                        if (usesRecurringPayments(scope.tuitionContract.tuitionPlan)) {
                            // All recurring payment plans share the same locale here.
                            descriptor = 'recurring';
                        }

                        scope.billingMessageValues.nextPaymentDate = scope.subscription.currentPeriodEnd
                            ? new Date(scope.subscription.currentPeriodEnd * 1000).toLocaleDateString(undefined, {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                              })
                            : null;

                        if (scope.currentUser.shouldChargeCreditCardPaymentFee) {
                            scope.billingMessageKey = `${registeredPaymentSmallprintKey}_with_fee_${descriptor}`;
                        } else {
                            scope.billingMessageKey = `${registeredPaymentSmallprintKey}_${descriptor}`;
                        }

                        scope.buttonKey = 'settings.tuition_and_registration.registered_manage_billing';
                    } else if (scope.userPaidOutsideOfStripe) {
                        scope.billingMessageKey = 'settings.tuition_and_registration.registered_contact_support';
                    } else if (scope.tuitionContractComplete) {
                        scope.billingMessageKey = 'settings.tuition_and_registration.narrative_all_paid';

                        // We do not show users the button to initiate a Stripe Billing Portal session if they have
                        // a complete TuitionContract for a onetime purchase. This is because Stripe does not (yet)
                        // create invoices for onetime purchases.
                        // See also https://stripe.com/docs/payments/checkout/post-payment-invoices
                        // and @session_params[:invoice_creation] in StripeWrappers::Checkout::Session
                        if (!scope.tuitionContractLikelyHasNoInvoices) {
                            scope.buttonKey =
                                'settings.tuition_and_registration.registered_manage_billing_view_payment_history';
                        }
                    } else if (scope.currentUser.completedStripeCheckoutSession) {
                        scope.processingPaymentMessageKey =
                            'settings.tuition_and_registration.registered_processing_payment';
                    }
                };

                //-------------------------
                // Billing portal session handling
                //-------------------------

                scope.getStripeBillingPortalUrl = () =>
                    $http
                        .post(`${window.ENDPOINT_ROOT}/api/stripe_billing_portal_sessions`)
                        .then(response => response?.data?.session?.url)
                        .catch(response => HttpQueue.unfreezeAfterError(response.config) && undefined);

                scope.openStripeBillingPortalUrl = async () => {
                    scope.buttonKey = 'settings.tuition_and_registration.processing';

                    const stripeBillingPortalUrl = await scope.getStripeBillingPortalUrl();
                    if (stripeBillingPortalUrl) {
                        scope.stripeProcessing = true;

                        // on Cordova, open the portal Url in the InApp browser.
                        if (scope.needsToOpenInBrowser) {
                            scope.loadUrl(stripeBillingPortalUrl, '_blank', 'location=no');
                        }
                        // otherwise, open the portal Url in this tab.
                        else {
                            $window.location.href = stripeBillingPortalUrl;
                        }
                    }
                };

                //-------------------------
                // Watches
                //-------------------------

                // Watch for changes pushed down from the server so we can
                // show the correct UI if the user makes any changes in the
                // portal.
                scope.$watchGroup(['currentUser.paid_outside_of_stripe', 'currentUser.tuitionContracts'], () => {
                    scope.userPaidOutsideOfStripe = scope.currentUser.paid_outside_of_stripe;

                    const tuitionContract = getTuitionContract(scope.currentUser);
                    scope.tuitionContract = tuitionContract;
                    scope.paymentPerInterval = formatCurrencyForDisplay(paymentPerInterval(tuitionContract));
                    scope.billingMessageValues.paymentPerInterval = scope.paymentPerInterval;
                    scope.tuitionContractComplete = tuitionContractComplete(tuitionContract);
                    scope.tuitionContractLikelyHasNoInvoices = tuitionContractLikelyHasNoInvoices(tuitionContract);

                    const subscription = tuitionContract?.subscription;
                    scope.subscription = subscription;
                    scope.subscriptionIncomplete = subscriptionIncomplete(subscription);
                    scope.subscriptionPastDue = subscriptionPastDue(subscription);

                    scope.setLocaleKeys();
                });
            },
        };
    },
]);
