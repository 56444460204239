import { TuitionPlanFrequency, type TuitionPlan } from './TuitionPlan.types';

export function isOneTimePlan(tuitionPlanId: string | null | undefined, tuitionPlans: TuitionPlan[]) {
    if (!tuitionPlanId) return false;
    const tuitionPlan = tuitionPlans.find(plan => plan.id === tuitionPlanId);
    return tuitionPlan?.frequency === TuitionPlanFrequency.Once;
}

export function availableTuitionPlanForFrequency(availableTuitionPlans: TuitionPlan[], frequency: string) {
    return availableTuitionPlans?.find(tuitionPlan => tuitionPlan.frequency === frequency);
}

// If you change this you might need to change uses_recurring_payments? in TuitionPlan.rb
export function usesRecurringPayments(tuitionPlan: TuitionPlan) {
    return [TuitionPlanFrequency.Monthly, TuitionPlanFrequency.MonthlyExtended, TuitionPlanFrequency.Annual].includes(
        tuitionPlan.frequency,
    );
}
