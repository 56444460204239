import clsx from 'clsx';
import { useSelector } from 'react-redux';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { LoadingBoundary } from 'LoadingBoundary';
import { usePollForUsers } from '../utils';
import { ChannelList } from './ChannelList';
import { Channel } from './Channel';
import { getActiveChannelUrl, getLoading } from '../selectors';
import { ChannelBuilder } from './ChannelBuilder';
import { ErrorMessage } from './ErrorMessage';

const classes = {
    container: clsx(
        'messageWidget',
        'sm:shadow-[0px_0px_8px_5px_rgba(0,0,0,.2)]',
        'sm:rounded-[8px]',
        'absolute',
        'z-[101]',
        'w-full',
        'sm:w-[calc(100vw-76px)]',
        'sm:max-w-[948px]',
        'md:max-w-[1024px]',
        'h-[calc(100vh-40px-54px)]',
        'notch:h-[calc(100vh-40px-54px-env(safe-area-inset-top)-env(safe-area-inset-bottom))]',
        'sm:h-[75vh]',
        'sm:max-h-[554px]',
        'md:h-[85vh]',
        'md:max-h-[800px]',
        'top-[40px]',
        'sm:top-[71.5px]',
        'left-0',
        'ltr:sm:left-[calc(100vw-calc(min(948px,calc(100vw-76px))+38px))]',
        'rtl:sm:right-[calc(100vw-calc(min(948px,calc(100vw-76px))+38px))]',
        'ltr:md:left-[calc(100vw-calc(min(1024px,calc(100vw-76px))+38px))]',
        'rtl:md:right-[calc(100vw-calc(min(1024px,calc(100vw-76px))+38px))]',
        'rtl:sm:left-auto',
        'px-0',
        'transition-transform',
        'duration-[250ms]',
        'opacity-0 ease-out',
        'opacity-100 ease-in',
    ),
    arrow: clsx(
        'top-[-4px]',
        'sm:top-[-6px]',
        'right-[95px]',
        'rtl:right-[73px]',
        'ltr:sm:right-[56px]',
        'ltr:md:right-[98px]',
        'rtl:sm:left-[56px]',
        'rtl:md:left-[98px]',
        'h-[8.5px]',
        'sm:h-[20px]',
        'w-[8.5px]',
        'sm:w-[20px]',
        'bg-white',
        'rotate-45',
        'absolute',
    ),
    sendbirdWrapper: clsx('relative', 'h-full', 'sm:overflow-hidden', 'sm:rounded-[8px]', 'flex', 'bg-white'),
    modalOverlay: clsx('relative', 'inset-0', 'h-[100vh]', 'w-[100vw]', 'bg-transparent', 'z-[100]', 'cursor-default'),
};

export const MessageWidget = () => {
    const loading = useSelector(getLoading);
    const activeChannel = useSelector(getActiveChannelUrl);

    usePollForUsers();

    return (
        <div className={classes.modalOverlay}>
            <div className={classes.container} aria-hidden onClick={e => e.stopPropagation()}>
                <div className={classes.arrow} />
                <div className={classes.sendbirdWrapper}>
                    <LoadingBoundary errorActions={{ other: ErrorMessage }}>
                        {loading ? (
                            // This loading state can happen if we are updating redux state outside of the react component
                            <FrontRoyalSpinner className="no-delay m-auto" />
                        ) : (
                            <>
                                <ChannelList />
                                {activeChannel ? <Channel /> : <ChannelBuilder />}
                            </>
                        )}
                    </LoadingBoundary>
                </div>
            </div>
        </div>
    );
};
