import { createApi } from 'ReduxHelpers';
import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { type TokenAttributes } from './Livekit.types';

export const liveKitApi = createApi('LiveKit', {
    ...getBackRoyalApiBaseConfig('LiveKit'),
    endpoints: builder => ({
        getToken: builder.mutation<string, { tokenAttributes: TokenAttributes }>({
            query: ({ tokenAttributes }) => ({
                url: 'livekit/get_token.json',
                method: 'POST',
                body: { tokenAttributes },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: ({ contents: { token } }: { contents: { token: string } }): string => token,
        }),
    }),
});

export default liveKitApi;
