import { formattedUserFacingMonthDayYearLong } from 'DateHelpers';
import { sortBy } from 'lodash/fp';
import { navigationHelper } from 'Navigation';
import {
    getActiveEnrollmentAgreementSigningLink,
    getHasActiveEnrollmentAgreementSigningLink,
    hasSignedEnrollmentAgreement,
    getTilaDisclosureSigningLink,
    getHasTilaDisclosureSigningLink,
    getHasSignedTilaDisclosure,
    getRequiresTilaDisclosure,
    getActiveIdVerificationPeriod,
} from 'Users';
import { getFirstIncompleteForm } from 'FormCompletionHelper';
import { StudentProfileFormConfigs } from 'FormConfigs';

export default function getEnrollmentSidebarTodoGroups($injector, { isLoadingBioSig, enrollBioSig }) {
    const $rootScope = $injector.get('$rootScope');
    const user = $rootScope.currentUser;
    if (!user || !user.career_profile) {
        return [[], {}];
    }
    const { loadRoute, loadUrl, loadFaqUrl } = navigationHelper();

    const UserIdVerificationViewModel = $injector.get('UserIdVerificationViewModel');
    const offlineModeManager = $injector.get('offlineModeManager');

    const userIdVerificationViewModel = new UserIdVerificationViewModel();
    userIdVerificationViewModel.launchVerificationModalIfLastIdologyVerificationFailed();

    const handleClick = (enrollmentTodo, callback) => {
        if (enrollmentTodo.hasBeenCompleted() || enrollmentTodo.disabled()) {
            return;
        }
        callback();
    };

    const todoDisabled = () => offlineModeManager.inOfflineMode;

    /*
        NOTE: This list of requirements is essentially duplicated in
        program_inclusion.rb#determine_if_all_enrollment_requirements_met.
        If we make edits here, we may need to make edits there as well.
    */
    const todoItems = [
        {
            name: 'todo_enrollment_agreement',
            localeKey: 'sign_enrollment_agreement',
            isVisible: () => hasSignedEnrollmentAgreement(user) || getHasActiveEnrollmentAgreementSigningLink(user),
            hasBeenCompleted: () => hasSignedEnrollmentAgreement(user),
            onClick() {
                handleClick(this, () => loadUrl(getActiveEnrollmentAgreementSigningLink(user), '_blank'));
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDocumentsDeadline,
        },
        {
            name: 'todo_unofficial_transcripts',
            localeKey:
                user.career_profile.educationExperiencesThatCanRequireOfficial.length > 1
                    ? 'upload_unofficial_transcripts'
                    : 'upload_unofficial_transcript',
            isVisible: () => {
                const isPastTranscriptRequirement =
                    user.isCurrentOrHasCompletedActiveProgram || user.transcripts_verified;
                const providedZeroUnofficialTranscripts =
                    user.career_profile.educationExperienceRequiringOfficialTranscript?.unofficialTranscripts
                        ?.length === 0;
                return (
                    user.recordsIndicateUserShouldProvideTranscripts &&
                    !(isPastTranscriptRequirement && providedZeroUnofficialTranscripts)
                );
            },
            hasBeenCompleted: () =>
                user.career_profile.educationExperienceRequiringOfficialTranscript?.unofficial_transcripts_approved ||
                (user.transcripts_verified &&
                    user.career_profile.educationExperienceRequiringOfficialTranscript?.unofficialTranscripts
                        ?.length !== 0),
            inReview: () =>
                user.career_profile.educationExperienceRequiringOfficialTranscript?.unofficialTranscripts?.length !==
                    0 &&
                !user.career_profile.educationExperienceRequiringOfficialTranscript?.unofficial_transcripts_approved,
            onClick() {
                handleClick(this, () => loadRoute('/settings/documents'));
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDocumentsDeadline,
        },
        {
            name: 'todo_english_language',
            localeKey: 'upload_english_language_documents',
            isVisible: () => user.recordsIndicateUserShouldUploadEnglishLanguageProficiencyDocuments,
            hasBeenCompleted: () => user.english_language_proficiency_documents_approved,
            inReview: () =>
                !user.missingEnglishLanguageProficiencyDocuments &&
                !user.english_language_proficiency_documents_approved,
            onClick() {
                handleClick(this, () => loadRoute('/settings/documents'));
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDocumentsDeadline,
        },
        {
            name: 'todo_id_verify',
            localeKey: 'verify_your_identity',
            isVisible: () => !user.isAuditing && !!getActiveIdVerificationPeriod(user),
            hasBeenCompleted: () =>
                !!getActiveIdVerificationPeriod(user) && !user.unverifiedForCurrentIdVerificationPeriod,
            onClick() {
                handleClick(this, () => userIdVerificationViewModel.launchVerificationModal());
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDeadline,
        },
        {
            name: 'todo_tila_disclosure',
            localeKey: 'sign_tila_disclosure',
            isVisible: () =>
                getRequiresTilaDisclosure(user) &&
                (getHasSignedTilaDisclosure(user) || getHasTilaDisclosureSigningLink(user)),
            hasBeenCompleted: () => getHasSignedTilaDisclosure(user),
            onClick() {
                handleClick(this, () => loadUrl(getTilaDisclosureSigningLink(user), '_blank'));
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDeadline,
        },
        {
            name: 'todo_address',
            localeKey: 'provide_mailing_address',
            isVisible: () => !!user.relevantCohort?.requiresMailingAddress,
            hasBeenCompleted: () => !user.missingAddress,
            onClick() {
                handleClick(this, () => loadRoute('/settings/documents'));
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDeadline,
        },
        {
            name: 'todo_complete_student_profile',
            localeKey: 'complete_student_profile',
            isVisible: () => user.relevantCohort?.supportsNetworkAccess,
            hasBeenCompleted: () => !!user.career_profile.completedAt,
            onClick() {
                handleClick(this, () => {
                    const steps = StudentProfileFormConfigs;
                    const firstIncompleteForm = getFirstIncompleteForm(user, steps);
                    const index = steps.findIndex(step => step.stepName === firstIncompleteForm?.stepName) + 1; // 1-indexed
                    loadRoute(`/settings/my-profile?page=${index}`);
                });
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDeadline,
        },
        {
            name: 'todo_biosig_id_enrollment',
            localeKey: 'create_exam_signatures',
            // This click handler for this todo is asynchronous and has to talk to our server,
            // so we show a spinner while that request is in flight.
            isLoading: () => !!isLoadingBioSig,
            isVisible: () => user.relevantCohort?.requiresProctoring,
            hasBeenCompleted: () => !!user.has_created_exam_signature,
            onClick() {
                handleClick(this, () => {
                    enrollBioSig();
                });
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.enrollmentDeadline,
        },
        {
            name: 'todo_official_transcripts',
            localeKey: 'send_us_your_transcripts',
            isVisible: () => user.recordsIndicateUserShouldProvideTranscripts,
            hasBeenCompleted: () => user.transcripts_verified,
            inReview: () => !user.transcripts_verified && !user.missingOfficialTranscripts,
            onClick() {
                handleClick(this, () =>
                    loadFaqUrl('/help/am-i-required-to-provide-official-documents-transcript-diploma-etc', '_blank'),
                );
            },
            disabled: todoDisabled,
            dueBy: user.relevantCohort?.officialTranscriptsDeadline,
            subTodosConfig: {
                isVisible: () => user.recordsIndicateUserShouldProvideTranscripts,
                getSubTodos: () => [user.career_profile.educationExperienceRequiringOfficialTranscript].filter(n => n),
                hasBeenCompleted: educationExperience =>
                    educationExperience.transcriptApprovedOrWaived || user.transcripts_verified,
                inReview: educationExperience =>
                    educationExperience.officialTranscriptInReview && !user.transcripts_verified,
                getSubTodoText: educationExperience => educationExperience.degreeAndOrgNameString,
            },
        },
    ];

    // Go through all the above todoItems, grouping past due items together
    // and items due on the same day together.
    const groups = sortBy('dueBy')(todoItems).reduce((accumulator, item) => {
        const dueBy =
            item.dueBy < new Date() && item.isVisible() && !item.hasBeenCompleted()
                ? 'pastDue'
                : formattedUserFacingMonthDayYearLong(item.dueBy);
        accumulator[dueBy] ||= [];
        accumulator[dueBy].push(item);

        // Putting the items with sub-checklist items at the bottom of the group makes for a cleaner UI
        accumulator[dueBy] = sortBy(todoItem => (todoItem.subTodosConfig ? '1' : 0))(accumulator[dueBy]);
        return accumulator;
    }, {});

    return [todoItems, groups];
}
