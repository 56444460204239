export enum TuitionPlanFrequency {
    Once = 'once',
    Annual = 'annual',
    Monthly = 'monthly',
    MonthlyExtended = 'monthly_extended',
    FullScholarship = 'full_scholarship',
    Custom = 'custom',
    BiAnnual = 'bi_annual',
}

export interface Institution {
    id: string;
    name: string;
}

export type TuitionPlan = {
    id: string;
    baseTuition: number;
    name: string;
    tag: string;
    frequency: TuitionPlanFrequency;
    numIntervals: number | null;
};
