import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type PracticeTopic, type VoiceCoursePracticeSliceState } from './VoiceCoursePractice.types';
import { voiceCoursePracticeSliceName } from './constants';

const defaultState: VoiceCoursePracticeSliceState = {
    topics: null,
};

export const voiceCoursePracticeSlice = createSlice({
    name: voiceCoursePracticeSliceName,
    initialState: defaultState,
    reducers: {
        setTopics: (state: VoiceCoursePracticeSliceState, action: PayloadAction<PracticeTopic[]>) => {
            state.topics = action.payload;
        },
    },
});
