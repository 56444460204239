/**
 * Resolves to `true` if the promise resolves truthy otherwise
 * it resolves to the label
 */
export const addLabelIfFalsy = (promise: Promise<unknown>, label: string) =>
    promise.then(
        v => (v ? true : label),
        () => label,
    );

/**
 * Takes an array of promises that have been wrapped in `addLabelIfFalsy`
 * and returns a tuple where the first value is whether or not all of the promises were truthy
 * and the second value is an array of the labels of promises that resolved falsy
 */
export default async function allTruthy(promises: Promise<string | true>[]) {
    const labelsOfFalsyPromises = (await Promise.all(promises)).filter(v => v !== true);

    return [labelsOfFalsyPromises.length === 0, labelsOfFalsyPromises] as const;
}
