import { useCurrentUser } from 'FrontRoyalAngular';
import { useLearnerContentCache } from 'LearnerContentCache/useLearnerContentCache';
import { isIncluded } from 'ProgramInclusion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type StudentDashboardContentResponse } from 'StudentDashboard';
import { getProgramInclusion } from 'Users';

const ONE_MINUTE = 60 * 1000;

export function useInProgressExam() {
    const user = useCurrentUser();
    const LearnerContentCache = useLearnerContentCache();
    const [dashboardContent, setDashboardContent] = useState<StudentDashboardContentResponse>();
    const includedInProgram = useMemo(() => isIncluded(getProgramInclusion(user)), [user]);

    const inProgressExam = useMemo(() => {
        const streams = dashboardContent?.result?.flatMap(resultObj => resultObj.lesson_streams) ?? [];
        const exams = streams.filter(s => s.exam);
        return exams.find(exam => exam.lesson_streams_progress?.inProgress);
    }, [dashboardContent]);

    // We are only checking if the user has an active exam in their currently active program. This is probably
    // ok, since AI Advisor is only going to have access to the content from their currently active program. So, it
    // wouldn't work to switch to a different program and then try to ask questions about your exam.
    // We only check for active exams when users are included in programs so we don't lock AI Advisor for admins
    // who are testing exams.
    const getDashboardContent = useCallback(async () => {
        if (!includedInProgram) return;

        const response = await LearnerContentCache.ensureStudentDashboard();
        // Due to mutation via `shoveProgressIntoContent`, we must currently make a new object
        // in order to trigger an update to the `inProgressExam` memo.
        setDashboardContent({ ...response });
    }, [LearnerContentCache, setDashboardContent, includedInProgram]);

    useEffect(() => {
        // Check on mount
        getDashboardContent();
        // Re-check roughly in step with status pings.
        const interval = setInterval(getDashboardContent, ONE_MINUTE);
        // Also re-check when tab/window takes focus
        window.addEventListener('focus', getDashboardContent);

        return () => {
            clearInterval(interval);
            window.removeEventListener('focus', getDashboardContent);
        };
    }, [getDashboardContent]);

    return { inProgressExam };
}
