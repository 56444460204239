import { useCurrentUser } from 'FrontRoyalAngular';
import { useMemo } from 'react';
import { getCohort } from 'Users';
import { ProgramType } from 'Program';
import { type ArticleSearchResult, type ResourcesAPIResponse } from './Resources.types';
import { useSearchArticlesQuery } from './resourcesApi';
import { type ROUTES } from './ResourcesRoutes';

export const getArticleCardsDetails = (items: ResourcesAPIResponse<ArticleSearchResult>['articles']['items']) =>
    items.map(item => ({
        id: item.id,
        title: item.name,
        description: item.preview,
        url: item.url,
        status: item.status,
        visibility: item.visibility,
        categorySlugs: item.categorySlugs,
    }));

type UseSearchResultsProps = {
    query?: string;
    categorySlug?:
        | typeof ROUTES.RESOURCES.$.ADMISSIONS.relativePath
        | typeof ROUTES.RESOURCES.$.ACADEMIC_AND_PROGRAM_SUPPORT.relativePath
        | typeof ROUTES.RESOURCES.$.CAREER_SERVICES.relativePath
        | typeof ROUTES.RESOURCES.$.LIBRARY_SERVICES.relativePath
        | typeof ROUTES.RESOURCES.$.STUDENT_EVENTS.relativePath;
};

export function useSearchResults({ query = '', categorySlug }: UseSearchResultsProps) {
    const currentUser = useCurrentUser();
    const programType = getCohort(currentUser)?.programType ?? ProgramType.mba;

    // We use this in two modes:
    //  1. When navigating to a category page, we pass in the categorySlug and a no query.  Because the HelpScout search
    //     API doesn't let you filter by category, we actually fetch all articles in the relevant Student Resources*
    //     collection(s) and filter them client-side.  This means that we don't need to refetch this data query when you
    //     navigate to other category pages; we just re-filter the results.
    //  2. For a search, we pass in a query and no categorySlug.  This searches the entire site (including collections we
    //     don't want to show in the Resources section) and then SearchResults.tsx filters the results client-side based
    //     on the categories in the Student Resources collection(s).
    const {
        data: currentData,
        isLoading,
        isError,
    } = useSearchArticlesQuery(
        {
            query,
            programType,
        },

        // prevent errors when currentUser changes to null on logout
        // (As long as this is only used on Resources pages, this isn't really
        // necessary, since Layout.tsx enforces that currentUser is not null, but
        // conceptually it makes sense)
        { skip: !programType },
    );

    const articlesData = useMemo(() => {
        if (currentData && currentData?.items) {
            const articleItems = getArticleCardsDetails(currentData.items);
            if (categorySlug) {
                return articleItems.filter(article => (article.categorySlugs || []).includes(categorySlug));
            }
            return articleItems;
        }
        return null;
    }, [currentData, categorySlug]);

    // eslint-disable-next-line no-nested-ternary
    const status = isError ? 'error' : isLoading ? 'loading' : 'success';
    return { articlesData, status } as const;
}
