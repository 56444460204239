import { type Nullable } from '@Types';
import moment from 'moment-timezone';
import {
    type Cohort,
    getRegistrationDeadline as getRegistrationDeadlineForCohort,
    inEarlyAdmissionsRegistrationPeriod as inEarlyAdmissionsRegistrationPeriodForCohort,
} from 'Cohorts';
import { type AdmissionOffer, AdmissionOfferStatus } from './AdmissionOffer.types';

export const offeredAdmission = (admissionOffer: Nullable<AdmissionOffer>) =>
    admissionOffer?.status === AdmissionOfferStatus.OfferedAdmission;

export const acceptedAdmissionOffer = (admissionOffer: Nullable<AdmissionOffer>) =>
    admissionOffer?.status === AdmissionOfferStatus.AcceptedAdmissionOffer;

export function declinedAdmissionOffer(admissionOffer: Nullable<AdmissionOffer>) {
    return admissionOffer?.status === AdmissionOfferStatus.DeclinedAdmissionOffer;
}

export function earlyRegistrationDeadlineMs(admissionOffer: Nullable<AdmissionOffer>): Nullable<number> {
    if (!admissionOffer || !admissionOffer.earlyRegistrationDeadline) return null;

    return moment(1000 * admissionOffer.earlyRegistrationDeadline)
        .startOf('day')
        .valueOf();
}

export function inEarlyAdmissionsRegistrationPeriod(admissionOffer: AdmissionOffer, cohort: Cohort) {
    if (!admissionOffer) return false;

    const earlyAdmissionRegistrationDeadline = earlyRegistrationDeadlineMs(admissionOffer);
    return (
        inEarlyAdmissionsRegistrationPeriodForCohort(cohort) &&
        !!earlyAdmissionRegistrationDeadline &&
        Date.now() < earlyAdmissionRegistrationDeadline
    );
}

export function getRegistrationDeadline(admissionOffer: AdmissionOffer, cohort: Cohort) {
    if (!admissionOffer) return null;

    return inEarlyAdmissionsRegistrationPeriod(admissionOffer, cohort)
        ? earlyRegistrationDeadlineMs(admissionOffer)
        : getRegistrationDeadlineForCohort(cohort);
}
