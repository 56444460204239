import { type AnyObject } from '@Types';
import { type ErrorMessage, type DataMessage, type TopicsUpdatedMessage } from './VoiceCoursePractice.types';

export function isDataMessage(message: AnyObject): message is DataMessage {
    return isTopicsUpdatedMessage(message) || isErrorMessage(message);
}

export function isTopicsUpdatedMessage(message: AnyObject): message is TopicsUpdatedMessage {
    return message.type === 'topicsUpdated';
}

export function isErrorMessage(message: AnyObject): message is ErrorMessage {
    return message.type === 'error';
}
