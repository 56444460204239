import {
    type CurrentUserIguanaObject,
    getAoiRecordFromUserProgramState,
    getUserProgramState,
    getRelevantUserProgramStates,
    getUserProgramFieldState,
} from 'Users';
import { aoiRecordCreationTime } from 'ProgramAoi';
import { fetchBrandConfig, Brand } from 'AppBranding';
import { type CohortClass } from 'Cohorts';
import { type ProgramSwitcherProgramConfig } from './ProgramSwitcher.types';

// eslint-disable-next-line max-lines-per-function
export function getProgramSwitcherConfigs($injector: ng.auto.IInjectorService, user: CurrentUserIguanaObject) {
    const Cohort = $injector.get<CohortClass>('Cohort');
    const programSwitcherConfigs: ProgramSwitcherProgramConfig[] = [];
    const userProgramStates = getRelevantUserProgramStates(user) ?? [];

    userProgramStates.forEach(userProgramState => {
        const brand = Cohort.branding(userProgramState.programType);
        const brandConfig = fetchBrandConfig(brand);
        const QuanticBrandConfig = fetchBrandConfig(Brand.quantic);
        const userProgramFieldState = getUserProgramFieldState(user, { userProgramState });

        // In the case where certain `brandConfig` values are undefined for a particular program type,
        // we can use Quantic branding as a fallback.
        const programSwitcherConfig: ProgramSwitcherProgramConfig = {
            aoiRecord: getAoiRecordFromUserProgramState(user, userProgramState)!,
            styleClass: brandConfig.brandStyleClass ?? QuanticBrandConfig.brandStyleClass,
            programTitle: Cohort.programSwitcherMenuTitle(userProgramState.programType),
            menuLogo: (brandConfig.appMenuProgramLogo ?? QuanticBrandConfig.appMenuProgramLogo) as string,
            institutionId: brandConfig.institutionId ?? QuanticBrandConfig.institutionId,
            userProgramFieldStateId: userProgramFieldState!.id,
        };

        programSwitcherConfigs.push(programSwitcherConfig);
    });

    if (programSwitcherConfigs.length === 0) return [];

    const programTypeForLastActiveProgram = getUserProgramState(user)?.programType;
    if (!programTypeForLastActiveProgram) return [];
    const brandForLastActiveProgram = Cohort.branding(programTypeForLastActiveProgram);
    const brandConfigForLastActiveProgram = fetchBrandConfig(brandForLastActiveProgram);

    // Sort programs by:
    // 1. Pref program
    // 2. Other programs in the same institution, recency descending, programs without an AOI record last
    // 3. Other programs NOT in the same institution, recency descending, programs without an AOI record last
    programSwitcherConfigs.sort((a, b) => {
        if (
            a.userProgramFieldStateId === user.prefUserProgramFieldStateId &&
            b.userProgramFieldStateId !== user.prefUserProgramFieldStateId
        ) {
            return -1;
        }

        if (
            a.userProgramFieldStateId !== user.prefUserProgramFieldStateId &&
            b.userProgramFieldStateId === user.prefUserProgramFieldStateId
        ) {
            return 1;
        }

        if (
            a.institutionId === brandConfigForLastActiveProgram.institutionId &&
            b.institutionId !== brandConfigForLastActiveProgram.institutionId
        ) {
            return -1;
        }

        if (
            a.institutionId !== brandConfigForLastActiveProgram.institutionId &&
            b.institutionId === brandConfigForLastActiveProgram.institutionId
        ) {
            return 1;
        }

        if (a.aoiRecord && !b.aoiRecord) {
            return -1;
        }

        if (!a.aoiRecord && b.aoiRecord) {
            return 1;
        }

        if (a.aoiRecord && b.aoiRecord) {
            if (aoiRecordCreationTime(a.aoiRecord) > aoiRecordCreationTime(b.aoiRecord)) return -1;
            if (aoiRecordCreationTime(a.aoiRecord) < aoiRecordCreationTime(b.aoiRecord)) return 1;
        }

        return 0;
    });

    // First item in the array is the most recent, active program. Set property to `selected`
    // in order to apply various css styles.
    programSwitcherConfigs[0].selected = true;

    return programSwitcherConfigs;
}
