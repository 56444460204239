import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { type ReactElement, useCallback, useEffect, useState, useMemo, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { type User } from '@sendbird/chat';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import { useTranslation } from 'react-i18next';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useGetStudentsToMessage, useGetUsersByName } from '../utils';
import { setPresetNewMessageRecipient, setShowNewMessage, setShowProfileUserId } from '../actions';
import {
    getChannelFromUserChannelMap,
    getPresetNewMessageRecipient,
    getShowNewMessage,
    getShowProfileUserId,
} from '../selectors';
import { MessageInputWithoutContext } from './MessageInput';
import { DateSeparator } from './DateSeparator';
import { MessageContent } from './MessageContent';
import { SelectedUserPill } from './SelectedUserPill';
import { SelectUsersList } from './SelectUsersList';
import { NewChannelInfo } from './NewChannelnfo';
import { UserProfile } from './UserProfile';

const classes = {
    container: (show: boolean) =>
        clsx('sm:flex', 'w-full', 'h-full', 'border-s', 'border-slate-grey-mid-light', show ? 'flex' : 'hidden'),
    channelBuilderContainer: (showingProfile: boolean) =>
        clsx(
            'flex',
            'flex-col',
            { 'w-full': !showingProfile, 'md:flex hidden': showingProfile },
            'relative',
            'h-full',
            'sm:transition-all',
            'grow',
        ),
    profilePanelContainer: (showingProfile: boolean) =>
        clsx('z-10', 'h-full', 'border-s', 'border-slate-grey-light', 'sm:transition-all', {
            'w-0': !showingProfile,
            'w-full md:basis-[300px] md:max-w-[300px] md:grow-0 md:shrink-0': showingProfile,
        }),
    header: {
        container: clsx(
            'w-full',
            'h-16',
            'border-b',
            'flex',
            'border-slate-grey-mid-light',
            'justify-between',
            'items-center',
            'flex-shrink-0',
        ),
        text: clsx('text-lg', 'text-black', 'font-semibold', 'ms-[20.5px]'),
        button: clsx('me-[20.5px]', 'text-lg', 'text-black', 'sm:hidden'),
    },
    addUser: {
        container: clsx(
            'min-h-[50px]',
            'border-b',
            'border-slate-grey-mid-light',
            'px-[20.5px]',
            'flex',
            'flex-shrink-0',
        ),
        label: clsx('text-[16px]', 'text-slate-grey', 'h-[50px]', 'w-fit', 'flex', 'items-center', 'me-[9px]'),
        input: {
            container: clsx('flex', 'w-full', 'flex-wrap', 'items-center', 'max-h-[160px]', 'overflow-auto'),
            element: clsx(
                'text-[16px]',
                'placeholder-slate-grey-mid-darker',
                'text-black',
                'flex',
                'flex-grow',
                'border-none',
                'min-w-[65px]',
                'max-w-full',
                'focus:outline-none',
            ),
        },
    },
    selectedUsersContainer: (showExistingPreview: boolean) =>
        clsx(
            showExistingPreview
                ? 'm-0 ml-[-1px] mb-[-25px] overflow-hidden'
                : 'mx-[15.5px] my-[11.5px] flex flex-col overflow-y-auto',
            'flex-grow',
        ),
    suggestedLabel: clsx('text-xs', 'text-slate-grey', 'mb-[15px]', 'px-[10px]', 'font-semibold'),
    inputContainer: clsx('h-[95px]', 'border-t', 'border-slate-grey-mid-light', 'pb-[24px]', 'pt-[15px]', 'w-full'),
};

const ChannelPreview = ({ url }: { url: string }) => (
    <GroupChannel
        channelUrl={url}
        renderChannelHeader={() => <div className="hidden" />}
        renderCustomSeparator={DateSeparator}
        renderMessageContent={p => <MessageContent {...p} onRefSet={() => undefined} />}
        renderMessageInput={() => <div className="hidden" />}
        renderPlaceholderLoader={() => <FrontRoyalSpinner className="h-full" color="force-brand" />}
        renderPlaceholderEmpty={() => null as unknown as ReactElement}
    />
);

// eslint-disable-next-line max-lines-per-function
export const ChannelBuilder = () => {
    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);
    const dispatch = useDispatch();
    const show = useSelector(getShowNewMessage);
    const profileUserId = useSelector(getShowProfileUserId);
    const presetNewMessageRecipient = useSelector(getPresetNewMessageRecipient);
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { availableUsers, suggestedUsers } = useGetStudentsToMessage();
    const { triggerFetchProfiles, loading } = useGetUsersByName();

    const inputIsFocused = useRef(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [showSuggested, setShowSuggested] = useState(false);
    const selectedUserIds = useMemo(() => selectedUsers.map(u => u.userId), [selectedUsers]);
    const [showExisting, setShowExisting] = useState(false);
    const [showFiltered, setShowFiltered] = useState(false);
    const [inputFocusCount, setInputFocusCount] = useState(0);
    const existingChannelForSelectedUsers = useSelector(state => getChannelFromUserChannelMap(state, selectedUserIds));

    useEffect(() => {
        // Using onFocus and onBlur to keep track of inputIsFocused with `useState` would trigger this effect onBlur
        // due to the inputIsFocused state change, which caused a users list to unmount and not register a click event.
        // The inputFocusCount and inputIsFocused ref are a workaround to only trigger this when the input is focused,
        // not when it's blurred.
        const isAboutToAddUser = !inputValue && inputIsFocused.current;
        const isFreshState = !selectedUsers.length && !inputValue && !inputIsFocused.current;
        const isSearching = !!inputValue;
        const hasSelectedUsers = !!selectedUsers.length;

        if (isSearching) {
            setShowSuggested(false);
            setShowExisting(false);
            setShowFiltered(true);
        } else if (isAboutToAddUser) {
            setShowSuggested(true);
            setShowExisting(false);
            setShowFiltered(false);
        } else if (hasSelectedUsers) {
            setShowSuggested(false);
            setShowExisting(!!existingChannelForSelectedUsers);
            setShowFiltered(false);
        } else if (isFreshState) {
            setShowSuggested(true);
            setShowExisting(false);
            setShowFiltered(false);
        }
    }, [selectedUsers, inputValue, existingChannelForSelectedUsers, suggestedUsers, inputFocusCount]);

    useEffect(() => {
        if (inputValue) {
            setFilteredUsers(
                availableUsers
                    .filter(u =>
                        u.nickname.replace(/\s+/g, '').toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase()),
                    )
                    .sort((a, b) => {
                        const aName = a.nickname.toLocaleLowerCase();
                        const bName = b.nickname.toLocaleLowerCase();

                        const aIndex = aName.indexOf(inputValue);
                        const bIndex = bName.indexOf(inputValue);

                        if (aIndex !== bIndex) return aIndex - bIndex;

                        return Math.abs(aName.length - inputValue.length) - Math.abs(bName.length - inputValue.length);
                    })
                    .filter((_, i) => i <= 20),
            );
        } else {
            setFilteredUsers([]);
        }
    }, [availableUsers, inputValue]);

    useEffect(() => {
        if (presetNewMessageRecipient) {
            const user = sdk.buildUserFromSerializedData(presetNewMessageRecipient.serializedUser);
            setSelectedUsers([user]);
            dispatch(setPresetNewMessageRecipient(null));
        }
    }, [selectedUsers, presetNewMessageRecipient, dispatch, sdk]);

    const handleListItemClick = useCallback(
        (user: User) => {
            setSelectedUsers(currentlySelectedUsers =>
                currentlySelectedUsers.find(u => u.userId === user.userId)
                    ? currentlySelectedUsers.filter(u => u.userId !== user.userId)
                    : currentlySelectedUsers.concat(user),
            );
            if (inputValue) setInputValue('');
        },
        [inputValue],
    );

    const checked = useCallback((user: User) => !!selectedUsers.find(u => u.userId === user.userId), [selectedUsers]);

    return (
        <div className={classes.container(show)}>
            <div className={classes.channelBuilderContainer(!!profileUserId)}>
                <div className={classes.header.container}>
                    <h3 className={classes.header.text}>{t('messaging.newMessage')}</h3>
                    <button
                        type="button"
                        className={classes.header.button}
                        onClick={() => dispatch(setShowNewMessage(false))}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className={classes.addUser.container}>
                    <div className={classes.addUser.label}>{t('messaging.toInput')}</div>
                    <div
                        className={classes.addUser.input.container}
                        onClick={() => {
                            inputRef.current?.focus();
                        }}
                        tabIndex={0}
                        onKeyDown={e => {
                            e.stopPropagation();
                            if (e.key === 'Enter') {
                                inputRef.current?.focus();
                            }
                        }}
                        role="button"
                    >
                        {selectedUsers.map(u => (
                            <SelectedUserPill key={u.userId} user={u} handleListItemClick={handleListItemClick} />
                        ))}

                        <input
                            ref={inputRef}
                            className={classes.addUser.input.element}
                            value={inputValue}
                            placeholder={!selectedUsers.length ? t('messaging.searchClassmates')! : undefined}
                            onFocus={() => {
                                inputIsFocused.current = true;
                                setInputFocusCount(current => current + 1);
                            }}
                            onBlur={() => {
                                inputIsFocused.current = false;
                            }}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (
                                    e.key === 'Backspace' &&
                                    inputIsFocused.current &&
                                    !inputValue &&
                                    selectedUsers.length
                                ) {
                                    setSelectedUsers(selectedUsers.slice(0, -1));
                                }
                            }}
                            onChange={e => {
                                setInputValue(e.currentTarget?.value || '');

                                if (e.currentTarget?.value) triggerFetchProfiles(e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className={classes.selectedUsersContainer(showExisting)}>
                    {loading ? (
                        <FrontRoyalSpinner color="force-brand" className="no-delay centered" />
                    ) : showSuggested ? (
                        <>
                            <p className={classes.suggestedLabel}>{t('messaging.suggested')}</p>
                            <SelectUsersList
                                users={suggestedUsers}
                                checked={checked}
                                handleListItemClick={handleListItemClick}
                            />
                        </>
                    ) : showFiltered ? (
                        <SelectUsersList
                            users={filteredUsers}
                            checked={checked}
                            handleListItemClick={handleListItemClick}
                        />
                    ) : showExisting && existingChannelForSelectedUsers ? (
                        <ChannelPreview url={existingChannelForSelectedUsers} />
                    ) : (
                        <NewChannelInfo users={selectedUsers} />
                    )}
                </div>

                <div className={classes.inputContainer}>
                    <MessageInputWithoutContext
                        usersToMessage={selectedUsers}
                        existingChannel={existingChannelForSelectedUsers}
                    />
                </div>
            </div>
            <div className={classes.profilePanelContainer(!!profileUserId)}>
                {!!profileUserId && (
                    <UserProfile
                        profileUserId={profileUserId}
                        onSendMessageClick={() => {
                            dispatch(setShowProfileUserId(null));
                        }}
                    />
                )}
            </div>
        </div>
    );
};
