import {
    type ResultTypeFrom,
    type QueryArgFrom,
    type EndpointDefinitions,
    type Api,
    type BaseQueryFn,
    type FetchArgs,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { type ApiWithHooks } from './ReduxHelpers.types';

export function useSuspenseQuery<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
    Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
    Params extends QueryArgFrom<Definitions[Name]>,
>(
    api: ApiWithHooks<BaseQuery, Definitions, Path, TagTypes>,
    endpoint: Name,
    ...params: Params extends void ? [] : [Params]
) {
    const endpointDefinition = api.endpoints[endpoint];

    if (!('useQuery' in endpointDefinition)) throw new Error(`Endpoint "${String(endpoint)}" is not a query`);

    const { data, isError, isLoading, error, isFetching } = endpointDefinition.useQuery(params[0]);
    const [trigger] = endpointDefinition.useLazyQuery();

    if (isLoading) throw trigger(params[0]);
    if (isError) throw error;

    return {
        data: data as ResultTypeFrom<Definitions[Name]>,
        // is fetching is true when there is already data, but we are fetching new data
        isFetching,
        refetch: () => trigger(params[0]),
    };
}

// type MutationResult<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// > = {
//     data: ApiResultType<BaseQuery, Definitions, Path, TagTypes, Name>;
//     error: unknown;
// };
// type MutationCallback<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// > = (
//     params: Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['QueryArg'],
// ) => Promise<Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['ResultType']>;

// type UseSuspenseMutationReturnValue<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// > = [
//     MutationCallback<BaseQuery, Definitions, Path, TagTypes, Name>,
//     MutationResult<BaseQuery, Definitions, Path, TagTypes, Name>,
// ];

// This implementation seemed to be working at some point. After that, I found issues in
// useSuspenseQuery and refactored it. useSuspenseMutation might still work as-is. Would need to be re-tested though
// export function useSuspenseMutation<
//     BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
//     Definitions extends EndpointDefinitions,
//     Path extends string,
//     TagTypes extends string,
//     Name extends keyof Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'],
// >(
//     api: Api<BaseQuery, Definitions, Path, TagTypes>,
//     endpoint: Name,
// ): UseSuspenseMutationReturnValue<BaseQuery, Definitions, Path, TagTypes, Name> {
//     type ResultType = ApiResultType<BaseQuery, Definitions, Path, TagTypes, Name>;

//     const [promise, setPromise] = useState<Promise<ResultType> | null>(null);
//     const [data, setData] = useState<ResultType | null>(null);
//     const [error, setError] = useState<unknown | null>(null);
//     const dispatch = useDispatch();

//     const send = useCallback(
//         (params: Api<BaseQuery, Definitions, Path, TagTypes>['endpoints'][Name]['Types']['QueryArg']) => {
//             const newPromise = makeRequest({ api, endpoint, params, dispatch }).then(setData, setError);
//             setPromise(newPromise);
//             return newPromise;
//         },
//         [api, endpoint, dispatch],
//     );

//     const isLoading = promise && !data && !error;
//     if (error) throw error;
//     if (isLoading) throw promise;

//     return [
//         send,
//         {
//             data,
//             error,
//         },
//     ];
// }
