/* eslint-disable no-setter-return */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { uniqBy } from 'lodash/fp';
import { determineAiAdvisorLocked } from 'TutorBot';

/*
    This is a mixin that holds the logic that a PlayerViewModel will need if there is an associated
    lesson.

    In the wild, today, the PlayerViewModel always has an associated lesson. We created this mixin when
    we created the PracticeFramesPlayerViewModel, which models a player that does not have an associated lesson.
*/
angularModule.factory('PlayerViewModelWithLesson', [
    '$injector',

    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const $location = $injector.get('$location');
        const SiteMetadata = $injector.get('SiteMetadata');
        const $rootScope = $injector.get('$rootScope');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');
        const StreamSummariesHelper = $injector.get('Stream.StreamSummariesHelper');
        const SessionTracker = $injector.get('SessionTracker');
        const lessonTranslationHelper = new TranslationHelper('lessons.models.lesson.lesson_player_view_model');
        const Stream = $injector.get('Lesson.Stream');
        const ClientStorage = $injector.get('ClientStorage');
        const $timeout = $injector.get('$timeout');
        const guid = $injector.get('guid');
        const $q = $injector.get('$q');
        const offlineModeManager = $injector.get('offlineModeManager');
        const timerSingleton = $injector.get('timerSingleton');
        const frontRoyalStore = $injector.get('frontRoyalStore');

        return new AModuleAbove({
            included(target) {
                target.defineCallbacks(
                    'showed_start_screen',
                    'showed_finish_screen',
                    'hid_start_screen',
                    'hid_finish_screen',
                );

                target.setCallback('after', 'initialized', function afterInit() {
                    this._waitForFlushedOrEnterOfflineModeAborts = [];
                    this.$$streamWasCompletedAlreadyBeforeStart =
                        this.stream && this.stream.progressStatus() === 'completed';
                    this.$$showStartScreen = !this.options.skipStartScreen && !this.editorMode && !this.lessonProgress;
                    this.$$showFinishScreen = false;
                    // logProgress is not set to true in most tests, so we don't have
                    // to worry about stubbing out LessonProgress save calls everywhere
                    // Also, if we are as admin logged in as another user, dont log progress
                    this.logProgress = this.options.logProgress === true;
                    if ($rootScope.currentUser && $rootScope.currentUser.ghostMode) {
                        this.logProgress = false;
                    }

                    if (!ContentAccessHelper.canLaunch(this.lesson)) {
                        this.logProgress = false;
                    }

                    if (this.logProgress) {
                        this.lesson.ensureLessonProgress();
                    }

                    this._buildNewSummaries();
                });

                target.setCallback('after', 'started', function afterStarted() {
                    // See comment in player-view-model.js
                    this.shouldClearCacheOnPlayerDestroy = this.stream?.lesson_streams_progress == null;

                    this._checkIfUserHasGivenOfflineModeConsent();

                    this._logLessonStart();

                    // if lesson is already completed, learner is retaking it
                    if (this.lesson.progressStatus() === 'completed') {
                        this._retaking = true;
                    }
                });

                target.setCallback('after', 'completed', function afterCompleted() {
                    // _logLessonComplete has probably already been
                    // called, in which case this does nothing.  See
                    // note in _logLessonComplete
                    this._logLessonComplete();
                    this._preloadCertificateImage();
                });

                target.setCallback('after', 'destroyed', function afterDestroyed() {
                    this.log('lesson:unload');

                    // Ensure that any changes (e.g., progress updates) made with the copy of the stream in this player_view_model
                    // are reflected in the stream cache. This fixed an issue where the stream_completed_dir
                    // was not working correctly sometimes due to it thinking the stream was not completed when
                    // really it was. See https://trello.com/c/lazr5dSo
                    if (this.stream) {
                        Stream.setCache(this.stream);
                    }

                    this._waitForFlushedOrEnterOfflineModeAborts.forEach(abort => abort());
                });

                target.setCallback('after', 'linked_to_app_header_view_model', function afterLinked() {
                    const appHeaderViewModel = _.last(this.appHeaderViewModels);
                    // custom back button behavior
                    // note: since this is global, this will break if we have more than one playerViewModel at the same time
                    const chapter = this.lesson.chapter();

                    if (chapter) {
                        const position = chapter.lessonPositionFor(this.lesson);
                        appHeaderViewModel.textRows = [
                            // chapter.title,
                            lessonTranslationHelper.get('lesson_x_of_x', {
                                index: position.index + 1,
                                lessonCount: position.lessonCount,
                            }),
                        ];
                    }

                    appHeaderViewModel.lastPlayedStream = this.stream;
                });

                target.setCallback('after', 'clear_client_storage', function afterClearClientStorage() {
                    ClientStorage.removeItem(this.lessonClientStorageKey);
                });

                const logInfo = target.prototype.logInfo;
                target.prototype.logInfo = function _logInfo() {
                    const obj = logInfo.apply(this);
                    return angular.extend(this.lesson.logInfo(), obj);
                };

                Object.defineProperty(target.prototype, 'lesson', {
                    get() {
                        return this._lesson;
                    },
                    set(lesson) {
                        this._lesson = lesson;
                        return lesson;
                    },
                });

                Object.defineProperty(target.prototype, 'assessment', {
                    get() {
                        return this.lesson && this.lesson.assessment;
                    },
                });

                Object.defineProperty(target.prototype, 'test', {
                    get() {
                        return this.lesson && this.lesson.test;
                    },
                });

                Object.defineProperty(target.prototype, 'testOrAssessment', {
                    get() {
                        return this.lesson && this.lesson.testOrAssessment;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'stream', {
                    get() {
                        return this.lesson.stream();
                    },
                });

                Object.defineProperty(target.prototype, 'minimumCompletionScoreInt', {
                    get() {
                        if (!this.lesson.assessment) {
                            return undefined;
                        }
                        return 80;
                    },
                });

                Object.defineProperty(target.prototype, 'lessonFailed', {
                    get() {
                        if (!angular.isDefined(this.minimumCompletionScoreInt)) {
                            return false;
                        }

                        // If they've done no challenges, they've failed
                        if (angular.isUndefined(this.lessonScore)) {
                            return true;
                        }

                        if ($rootScope.currentUser.preferStrictSmartCaseScore) return !(this.lessonScore >= 0);

                        return this.lessonScore < this.minimumCompletionScoreInt / 100;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'lessonPassed', {
                    get() {
                        if (!angular.isDefined(this.minimumCompletionScoreInt)) {
                            return null;
                        }

                        return !this.lessonFailed;
                    },
                });

                Object.defineProperty(target.prototype, 'retaking', {
                    get() {
                        return this._retaking || false;
                    },
                });

                Object.defineProperty(target.prototype, 'lessonProgress', {
                    get() {
                        return this.lesson.lesson_progress;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'showStartScreen', {
                    get() {
                        return this.$$showStartScreen;
                    },
                    set(val) {
                        if (val === this.showStartScreen) {
                            return val;
                        }

                        const callback = val ? 'showed_start_screen' : 'hid_start_screen';
                        this.runCallbacks(callback, function setShowStartScreen() {
                            this.$$showStartScreen = val;
                        });
                        return val;
                    },
                });

                Object.defineProperty(target.prototype, 'showFinishScreen', {
                    get() {
                        return this.$$showFinishScreen;
                    },
                    set(val) {
                        if (val === this.showFinishScreen) {
                            return val;
                        }

                        const callback = val ? 'showed_finish_screen' : 'hid_finish_screen';
                        this.runCallbacks(callback, function setShowFinishScreen() {
                            this.$$showFinishScreen = val;
                        });

                        return val;
                    },
                });

                Object.defineProperty(target.prototype, 'seoTitleLocked', {
                    get() {
                        return this.$$seoTitleLocked;
                    },
                    set(val) {
                        this.$$seoTitleLocked = val;
                        // if we're relocking the value, sync it back up with the original
                        if (val) {
                            this.lesson.entity_metadata.title = this.lesson.title;
                        }
                    },
                });

                Object.defineProperty(target.prototype, 'seoDescriptionLocked', {
                    get() {
                        return this.$$seoDescriptionLocked;
                    },
                    set(val) {
                        this.$$seoDescriptionLocked = val;
                        // if we're relocking the value, sync it back up with the original
                        if (val) {
                            this.lesson.entity_metadata.description = this.lesson.description;
                        }
                    },
                });

                Object.defineProperty(target.prototype, 'seoCanonicalUrlLocked', {
                    get() {
                        return this.$$seoCanonicalUrlLocked;
                    },
                    set(val) {
                        this.$$seoCanonicalUrlLocked = val;
                        // if we're relocking the value, sync it back up with the current entity_metadata.title
                        if (val) {
                            this.lesson.entity_metadata.canonical_url = SiteMetadata.seoCanonicalUrlFromTitle(
                                'lesson',
                                this.lesson.entity_metadata.title,
                                this.lesson.id,
                            );
                        }
                    },
                });

                // The summaries contained in this stream that will be newly unlocked upon completion
                Object.defineProperty(target.prototype, 'newSummaries', {
                    get() {
                        return this.$$newSummaries;
                    },
                });

                Object.defineProperty(target.prototype, 'lessonClientStorageKey', {
                    get() {
                        return `PlayerViewModelWithLesson.${this.lesson.id}`;
                    },
                });

                const PLAYER_SESSION_EXPIRY_MINUTES = $injector.get('PLAYER_SESSION_EXPIRY_MINUTES');
                Object.defineProperty(target.prototype, 'sessionId', {
                    get() {
                        /*
                            FIXME: the key should include the userId and lessonStreamId.  It is rare,
                            but sometimes sessions span users or streams.

                            I looked at a few examples:

                            In the duplicate user case, there was one case where a user seemed
                            to have two different accounts and had done the lesson on both on the
                            same device.  In another case, it seemed to be two users sharing a device.

                            In the duplicate stream case, it was alexie and the two streams had the
                            same title.  So either the same lesson was in two streams and she was
                            testing them both one after another or she moved the lesson from one
                            stream to another.

                            We should maybe also clear the session ids from local storage when
                            logging out, the way we do with some other things.  Or we could build
                            the user id stuff in at the SessionTracker level instead of at the
                            PlayerViewModelWithLesson level.

                            See https://trello.com/c/aqZZZO3k
                        */
                        let expirationThreshold;
                        const oneMinute = 60 * 1000;
                        if (this.sessionType === 'lessonEditor') {
                            expirationThreshold = 5 * oneMinute;
                        } else {
                            expirationThreshold = PLAYER_SESSION_EXPIRY_MINUTES * oneMinute;
                        }

                        const streamId = this.lesson.stream() && this.lesson.stream().id;
                        const key = [this.sessionType, this.lesson.id, streamId].join(':');

                        return SessionTracker.pingCurrentSession(key, expirationThreshold).id;
                    },
                });

                Object.defineProperty(target.prototype, '_flushingProgress', {
                    get() {
                        return Object.keys(this._progressFlushPromises || {}).length > 0;
                    },
                });

                Object.defineProperty(target.prototype, 'streamLessonTutorBotRecord', {
                    get() {
                        return (this.stream && this.stream.getStreamLessonTutorBotRecord(this.lesson.id)) || null;
                    },
                });

                Object.defineProperty(target.prototype, 'preLessonReviewMessage', {
                    get() {
                        return this.streamLessonTutorBotRecord?.pre_lesson_review_message || null;
                    },
                });

                Object.defineProperty(target.prototype, 'lessonSupportsBot', {
                    get() {
                        if (offlineModeManager.inOfflineMode) return false;

                        // Always show AI Tutor in the editor preview, even if one of the following
                        // conditions is not met
                        if (this.previewMode) return true;

                        if (this.testOrAssessment) return false;

                        // The lesson player bot doesn't work well in other languages. See https://trello.com/c/5whnJZSb
                        if (this.lesson.locale !== 'en') return false;

                        if (this.$$aiAdvisorLocked === undefined) {
                            this.$$aiAdvisorLocked = determineAiAdvisorLocked('lesson_player', $injector);
                        }

                        return !this.$$aiAdvisorLocked;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'previousLessonOutline', {
                    get() {
                        return this.stream?.previousLessonOutline(this.lesson.id) || null;
                    },
                    configurable: true,
                });
            },

            hasLesson: true,

            initializeLockedSeoFields() {
                if (this.lesson.title === this.lesson.entity_metadata.title) {
                    this.seoTitleLocked = true;
                }
                if (this.lesson.description === this.lesson.entity_metadata.description) {
                    this.seoDescriptionLocked = true;
                }
                if (
                    SiteMetadata.seoCanonicalUrlFromTitle(
                        'lesson',
                        this.lesson.entity_metadata.title,
                        this.lesson.id,
                    ) === this.lesson.entity_metadata.canonical_url
                ) {
                    this.seoCanonicalUrlLocked = true;
                }
            },
            gotoChapterDashboard() {
                $location.url(this.lesson.streamDashboardWithChapterUrl);
            },

            gotoStreamCompleted() {
                const stream = this.lesson.stream();
                $location.url(`/course/${stream.id}/completed`);
            },

            moveOnAfterComplete() {
                const stream = this.stream;

                // prevent double-clicks. https://trello.com/c/MQNC1eV0
                if (this._movingOn) {
                    return;
                }
                this._movingOn = true;

                if (this.lessonFailed) {
                    this.replaceMeWithThisPlayerViewModel = this.lesson.createPlayerViewModel(this.options);
                    this.replaceMeWithThisPlayerViewModel.showStartScreen = true;
                } else if (
                    stream &&
                    stream.progressStatus() === 'completed' &&
                    !this.retaking &&
                    !this.$$streamWasCompletedAlreadyBeforeStart
                ) {
                    this.gotoStreamCompleted();
                } else if (ContentAccessHelper.canLaunch(this.lesson.nextLessonInStream)) {
                    // This transition looks better if we hide the button and the
                    // finish screen immediately, wait a beat for the animation to complete,
                    // and then go into the next lesson.
                    this.showFinishScreen = false;
                    $timeout(500).then(() => this.lesson.nextLessonInStream.launch('move_on_after_complete'));
                } else if (stream) {
                    $location.url(this.stream.streamDashboardPath);
                } else {
                    $location.url('/courses');
                }
            },

            setLessonClientStorage(keyParts, val) {
                const key = this._getClientStorageSubKey(keyParts);
                const obj = this._getLessonClientStorageObj();
                obj[key] = val;
                ClientStorage.setItem(this.lessonClientStorageKey, JSON.stringify(obj));
            },

            getLessonClientStorage(keyParts) {
                const key = this._getClientStorageSubKey(keyParts);
                return this._getLessonClientStorageObj()[key];
            },

            ensureProgressFlushed() {
                // We return a promise that resolves once all
                // save flush calls that have already been made are complete.
                // It's possible that another save call could be made
                // while we're waiting for these, but right now we don't
                // have a need to deal with that.  If we did need to, we
                // would just make this recursive.
                const promises = _.values(this._progressFlushPromises);
                return $q.all(promises);
            },

            _getLessonClientStorageObj() {
                let json = ClientStorage.getItem(this.lessonClientStorageKey);

                if (angular.isUndefined(json)) {
                    return {};
                }

                // In this context, we expect to get back a string from ClientStorage,
                // as we always call JSON.stringify on the object before we save it
                // to local storage. However, we have seen instances where we attempt
                // to call JSON.parse on an object from inside this method.
                //
                // see also: https://sentry.io/pedago/front-royal/issues/776245165/ (see raw additional data)
                if (typeof json === 'object') {
                    json = JSON.stringify(json);
                }

                let parsed;
                try {
                    parsed = JSON.parse(json);
                } catch (e) {
                    $injector
                        .get('ErrorLogService')
                        .notify('unable to parse invalid lesson progress store', undefined, {
                            error: e && e.message,
                            lesson_client_storage_key: this.lessonClientStorageKey,
                            json_string: json,
                        });
                }
                return parsed || {};
            },

            _getClientStorageSubKey(keyParts) {
                return Array.isArray(keyParts) ? keyParts.join('-') : keyParts;
            },

            // this method is overridden (extended, really) in frameListPlayerViewModel
            _logLessonComplete() {
                this.clearClientStorage();

                /*
                        This method can be called more that once, so
                        we need to make sure it only does what it does
                        once.  It can be called more than once because:

                        1. If a learner goes back and forth from the
                            finish screen, then they will finish the
                            last frame more than once.
                        2. We call _logLessonComplete in the completed
                            setter just to be sure, even though it
                            seems like the user must have completed
                            the last frame before getting there (at
                            least in the FrameList case)
                    */
                if (this._lessonCompleteLogged) {
                    return;
                }
                this._lessonCompleteLogged = true;

                this.log(
                    'lesson:finish',
                    _.extend(
                        {
                            score: this.lessonScore,
                            lesson_passed: this.lessonPassed,
                            score_metadata: this.scoreMetadata,
                        },
                        this.logInfo(),
                    ),
                );

                // If the user failed an assessment, the lesson is not complete
                this.rpc.call('logLessonComplete', !this.lessonFailed, this.lessonScore);

                if (this.editorMode || !this.logProgress) {
                    return;
                }

                const lessonProgress = this.lesson.ensureLessonProgress();

                if (this.lessonScore || this.lessonScore === 0) {
                    lessonProgress.best_score = Math.max(lessonProgress.best_score || 0, this.lessonScore);
                }

                if (this.assessment) {
                    lessonProgress.restart();
                }

                if (!this.lessonFailed) {
                    lessonProgress.complete = true;
                }

                if (this.stream) {
                    const setComplete = this.stream.setCompleteIfAllLessonsComplete();

                    // See comment in player-view-model.js
                    if (setComplete) {
                        this.shouldClearCacheOnPlayerDestroy = true;
                    }
                }

                this._saveLessonAndStreamProgress();
            },

            _updateBookmarkedStreams(response, startedNewStream) {
                // Before we implemented the FrontRoyalStore, the server would send down
                // favorite_lesson_stream_locale_packs.  When the lessonProgressInterceptor
                // is active, we will get append_to_favorite_lesson_stream_locale_packs.
                // FIXME: this has always been a strange way and a strange place to do this.
                // We don't push down changes made to this list through the ping or anything,
                // but we will kind of accidentally push them down if you start a course in this
                // browser after starting one in another browser.  We should probably just bookmark
                // streams through regular client code when a course is started and then push down
                // the list through pings like we do for other stuff.
                const favoriteStreamsReturned =
                    response?.meta?.favorite_lesson_stream_locale_packs ||
                    response?.meta?.append_to_favorite_lesson_stream_locale_packs;
                let shouldPreloadStudentDashboard = false;
                if (favoriteStreamsReturned) {
                    let newFavorites;
                    if (response.meta.append_to_favorite_lesson_stream_locale_packs) {
                        newFavorites = uniqBy('id')(
                            $rootScope.currentUser.favorite_lesson_stream_locale_packs.concat(
                                response.meta.append_to_favorite_lesson_stream_locale_packs,
                            ),
                        );
                    } else {
                        newFavorites = response.meta.favorite_lesson_stream_locale_packs;
                    }

                    // ensure that the collection has actually changed before re-assigning and triggering any LearnerContentCache watches
                    if (!_.isEqual($rootScope.currentUser.favorite_lesson_stream_locale_packs, newFavorites)) {
                        $rootScope.currentUser.favorite_lesson_stream_locale_packs = newFavorites;
                        shouldPreloadStudentDashboard = true;
                    }

                    $rootScope.currentUser.favorite_lesson_stream_locale_packs.forEach(favorite => {
                        if (favorite.id === this.stream.localePackId) {
                            this.stream.favorite = true;
                        }
                    });
                }

                // This is wrapped in a small timeout to ensure that watches in LearnerContentCache trigger first.
                $timeout(() => {
                    if (shouldPreloadStudentDashboard) {
                        $injector.get('LearnerContentCache').preloadStudentDashboard();
                    }

                    if (startedNewStream) {
                        const EventLogger = $injector.get('EventLogger');
                        EventLogger.log('dupe_loading_issue:queue_loading_update_bookmarked_streams');
                        offlineModeManager.ensureCurriculumAvailableForOfflineUse();
                    }
                }, 1000);
            },

            _saveLessonAndStreamProgress(metadata) {
                if (!this.logProgress) {
                    return;
                }

                // ensureProgressFlushed will check this._progressFlushPromises to decide
                // if we need to wait for progress to be saved before progressing in certain situations
                this._progressFlushPromises = this._progressFlushPromises || {};
                const id = guid.generate();

                const streamProgress = this.stream && this.stream.ensureStreamProgress();
                const startedNewStream = !!streamProgress?.isNew();
                const promise = this.lesson
                    .ensureLessonProgress()
                    .save(streamProgress)
                    .then(response => {
                        if ($rootScope.currentUser) {
                            this._updateBookmarkedStreams(response, startedNewStream);
                        }

                        // Until waitForFlushedOrEnterOfflineMode resolves, there will be an entry in
                        // this._progressFlushPromises and ensureProgressFlushed will prevent the user
                        // from moving on.
                        // * If the user enters offline mode, then we no longer need to
                        //      block them moving on. Progress will be saved to the store and
                        //      we will show them a message between each lesson telling them that they
                        //      need to get online to save their progress.
                        // * If the user is not in offline mode, then we wait until flushes succeed before
                        //      moving on.
                        const { promise: waitForFlushedOrEnterOfflineModePromise, abort } =
                            frontRoyalStore.waitForFlushedOrEnterOfflineMode();
                        this._waitForFlushedOrEnterOfflineModeAborts.push(abort);
                        return waitForFlushedOrEnterOfflineModePromise;
                    })
                    .then(flushed => {
                        // If flushed is false, then the user is in offline mode, because that's the only way
                        // waitForFlushedOrEnterOfflineModePromise will resolve with false
                        if (!flushed && !ContentAccessHelper.allowedOffline(this.lesson)) {
                            DialogModal.confirm({
                                text: lessonTranslationHelper.get(this._mustBeOnlineKey()),
                                size: 'small',
                                cancelButtonText: lessonTranslationHelper.get('exit_lesson'),
                                cancelCallback: () => $location.url('/dashboard'),
                                confirmButtonText: lessonTranslationHelper.get('retry'),

                                // If the flush retry succeeds, then we resolve the confirm callback and the
                                // modal is hidden. If it fails, we reject the callback and the confirm remains
                                confirmCallback: () =>
                                    $q((resolve, reject) => {
                                        frontRoyalStore.flush().then(flushedOnRetry => {
                                            if (flushedOnRetry) {
                                                resolve();
                                            } else {
                                                reject();
                                            }
                                        });
                                    }),
                                blurTargetSelector: 'div[ng-controller]',
                            });
                        }
                    })
                    .then(() => {
                        delete this._progressFlushPromises[id];
                    });

                promise.metadata = metadata;

                this._progressFlushPromises[id] = promise;
            },

            _logLessonStart() {
                if (this.editorMode) {
                    return;
                }

                this.log('lesson:play');

                // Logging the timer event with a different name to distinguish from lesson:play,
                // just because there is a bunch of logic in side of `log` that I didn't want to mess with
                timerSingleton.finishTimer(
                    `lesson:clickedLaunch:${this.lesson.id}`,
                    'lesson_launch_timer',
                    this.logInfo(),
                );

                $rootScope.$broadcast('lesson:play', this.lesson);

                if (!this.logProgress) {
                    return;
                }

                const streamProgress = this.stream && this.stream.ensureStreamProgress();
                if (streamProgress) {
                    streamProgress.lesson_bookmark_id = this.lesson.id;
                }
                this._saveLessonAndStreamProgress();
            },

            _mustBeOnlineKey() {
                if (this.assessment) {
                    return 'must_be_online_exit_or_retry_assessment';
                }
                if (this.test) {
                    return 'must_be_online_exit_or_retry_test';
                }
                return 'must_be_online_exit_or_retry';
            },

            _checkIfUserHasGivenOfflineModeConsent() {
                if (!$rootScope.currentUser) {
                    return;
                }

                if ($rootScope.currentUser.pref_offline_mode) {
                    return;
                }

                if ($rootScope.currentUser.has_seen_offline_mode_consent_modal) {
                    return;
                }

                offlineModeManager.isOfflineModeSupported().then(supported => {
                    if (supported) {
                        DialogModal.alert({
                            title: new TranslationHelper('lessons.lesson.offline_mode_consent_modal').get('header'),
                            content: `<offline-mode-consent-modal></offline-mode-consent-modal>`,
                            size: 'medium',
                            hideCloseButton: true,
                        });
                    }
                });
            },

            _buildNewSummaries() {
                this.$$newSummaries = StreamSummariesHelper.summariesForStreamOrLesson(
                    this.stream,
                    $rootScope.currentUser,
                    this.lesson,
                );
            },

            _preloadCertificateImage() {
                if (this.stream) {
                    this.stream.preloadCertificateImage();
                }
            },
        });
    },
]);
