import { ClickableText } from 'ClickableText';
import { type FC, memo } from 'react';
import { useParams } from 'react-router-dom';

const StudyBoxComponent: FC = () => {
    const { studyTab } = useParams();

    return (
        <div className="min-h-[840px] rounded border border-gray-300 bg-white p-2">
            <div>
                <ClickableText href="/dashboard/currently-studying">Currently Studying</ClickableText> |{' '}
                <ClickableText href="/dashboard/curriculum">Curriculum</ClickableText>
            </div>
            {studyTab}
        </div>
    );
};

export const StudyBox = memo(StudyBoxComponent) as typeof StudyBoxComponent;
