import { useCallback, useMemo, useState } from 'react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type ProgramType, type ProgramTypeConfig } from 'Program';
import { useTranslation } from 'react-i18next';
import { type CurrentUserIguanaObject } from 'Users';
import { type navigationHelper } from 'Navigation';
import { ExecEdCertCategoryConfigs } from '../../helpers/ExecEdCategories';
import { programCategoryForProgramType } from '../../helpers/utils';
import { linearBlueToTransparentGradientStyle, linearWhiteGradientStyle } from '../../helpers/gradients';
import {
    type ExecEdEligibilityBundle,
    type EligibleProgram,
    type ExecEdCertCategory,
} from '../../ExecEdEligibility.types';
import { CertRecommendedProgram } from './CertRecommendedProgram';
import { CertEligibleProgramCategory } from './CertEligibleProgramCategory';

type Props = {
    filteredEligibilityBundle: ExecEdEligibilityBundle;
    programTypeConfigs: { [programType: string]: ProgramTypeConfig };
    loadUrl: ReturnType<typeof navigationHelper>['loadUrl'];
    currentUser: CurrentUserIguanaObject;
};

// eslint-disable-next-line max-lines-per-function
export function CertModalContent({ filteredEligibilityBundle, programTypeConfigs, loadUrl, currentUser }: Props) {
    const { t } = useTranslation('back_royal');

    const recommendedProgramType = filteredEligibilityBundle?.recommendedProgramType;
    const recommendedProgramCategory = recommendedProgramType && programCategoryForProgramType(recommendedProgramType);
    const [loading, setLoading] = useState(false);

    const otherProgramsByCategory = useMemo(() => {
        const eligiblePrograms = filteredEligibilityBundle?.eligiblePrograms;
        const otherPrograms = eligiblePrograms?.filter(p => p.programType !== recommendedProgramType);
        return otherPrograms?.reduce((acc: { [category: string]: EligibleProgram[] }, program) => {
            const category = programCategoryForProgramType(program.programType);
            return category
                ? {
                      ...acc,
                      [category]: [...(acc[category] || []), program],
                  }
                : acc;
        }, {});
    }, [recommendedProgramType, filteredEligibilityBundle]);

    const orderedCategories = useMemo(
        () =>
            otherProgramsByCategory &&
            Object.keys(otherProgramsByCategory).sort(
                (a, b) =>
                    ExecEdCertCategoryConfigs[a as ExecEdCertCategory].displaySortOrder -
                    ExecEdCertCategoryConfigs[b as ExecEdCertCategory].displaySortOrder,
            ),
        [otherProgramsByCategory],
    );

    const handleReviewRegisterClick = useCallback(
        (programType: ProgramType) => {
            if (loading) return;
            setLoading(true);
            currentUser.registerPreApprovedExecEdProgram(programType).finally(() => setLoading(false));
        },
        [currentUser, loading, setLoading],
    );

    const handleLearnMoreClick = useCallback(
        (programType: ProgramType) => {
            loadUrl(programTypeConfigs[programType].marketingPageUrl, '_blank');
        },
        [loadUrl, programTypeConfigs],
    );

    if (loading) {
        return (
            <div className="mt-5 flex w-full items-center justify-center">
                <FrontRoyalSpinner color="blue" className="no-delay static-height" />
            </div>
        );
    }

    return (
        <div className="cert-modal-content flex w-full flex-col items-center justify-center gap-[30px] md:relative md:-top-[40px] md:flex-row md:items-start md:justify-center md:gap-[34px]">
            {/* Recommended Program */}
            <div
                className="flex w-[256px] shrink-0 grow-0 flex-col items-center border border-t-0 border-solid border-white pb-[25px] md:sticky md:top-[227px] md:z-20 md:mt-[2px]"
                style={{ ...linearBlueToTransparentGradientStyle('to top') }}
            >
                <div className="relative -top-[12px] flex w-full items-center justify-center">
                    <div className="line relative -left-[1px] h-[1px] flex-1 bg-white" />
                    <div className="max-w-[90%] px-[9px] uppercase">
                        {t('exec_ed_eligibility.cert_acceptance_modal.recommended')}
                    </div>
                    <div className="line relative -right-[1px] h-[1px] flex-1 bg-white" />
                </div>
                {recommendedProgramType && (
                    <CertRecommendedProgram
                        category={recommendedProgramCategory as ExecEdCertCategory}
                        recommendedProgramType={recommendedProgramType}
                        onReviewRegisterClick={handleReviewRegisterClick}
                        onLearnMoreClick={handleLearnMoreClick}
                    />
                )}
            </div>

            {/* Other Available Programs */}
            <div className="w-full max-w-[560px]">
                <div className="mb-[10px] flex h-[1.5em] w-full items-center justify-center md:sticky md:top-[214px] md:z-20 md:-mt-[10px] rtl:flex-row-reverse">
                    <div className="line h-[1px] flex-1" style={{ ...linearWhiteGradientStyle('to left') }} />
                    <div className="relative max-w-[90%] whitespace-nowrap px-[10px] uppercase">
                        {t('exec_ed_eligibility.cert_acceptance_modal.also_available')}
                    </div>
                    <div className="line h-[1px] flex-1" style={{ ...linearWhiteGradientStyle('to right') }} />
                </div>
                {orderedCategories?.map(category => (
                    <CertEligibleProgramCategory
                        key={category}
                        category={category as ExecEdCertCategory}
                        eligibilityBundle={filteredEligibilityBundle!}
                        onReviewRegisterClick={handleReviewRegisterClick}
                        onLearnMoreClick={handleLearnMoreClick}
                    />
                ))}
            </div>
        </div>
    );
}
