import { createSelector } from '@reduxjs/toolkit';
import { type RootStateWithVoiceCoursePracticeSlice } from './VoiceCoursePractice.types';
import { voiceCoursePracticeSlice } from './voiceCoursePracticeSlice';

export const getVoiceCoursePracticeState = (rootState: RootStateWithVoiceCoursePracticeSlice) =>
    rootState[voiceCoursePracticeSlice.name];

export const getTopics = createSelector(
    getVoiceCoursePracticeState,
    voiceCoursePracticeState => voiceCoursePracticeState.topics,
);
