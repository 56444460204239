import {
    type TutorBotSystemMessage,
    type LessonFeedbackFormMessage,
    type ReviewLessonMessage,
    type ExplainScreenMessage,
    type TutorBotSystemMessageInputs,
    type OutgoingMessageInputs,
} from '../TutorBot.types';
import {
    createBaseMessageAttrs,
    isExplainScreenMessageInputs,
    isLessonFeedbackFormMessageInputs,
    isReviewLessonMessageInputs,
} from './ChatMessage';

type BaseMessage = Omit<ReturnType<typeof createBaseMessageAttrs>, 'hidden' | 'content'> &
    Pick<TutorBotSystemMessage, 'conversationId' | 'complete' | 'role' | 'isRetryOfMessageId' | 'hidden' | 'canceled'>;

export function createTutorBotSystemMessage({
    // see comment above OutgoingMessageInputs definition about the oddity of the `inputs` parameter
    inputs: partialInputs,
    conversationId,
    isRetryOfMessageId = null,
    isInitialMessage = false,
}: {
    inputs: { content: TutorBotSystemMessageInputs['content']; payload: TutorBotSystemMessageInputs['payload'] };
    conversationId: string;
    isRetryOfMessageId?: string | null;
    isInitialMessage?: boolean;
}) {
    const baseMessage: BaseMessage = {
        ...createBaseMessageAttrs(),
        conversationId,
        complete: true as const,
        role: 'tutor_bot_system' as const,
        isRetryOfMessageId,
        hidden: true as const,
        canceled: null,
    };

    const inputs = {
        ...partialInputs,
        role: 'tutor_bot_system',
    } as OutgoingMessageInputs;

    if (isExplainScreenMessageInputs(inputs)) {
        return createTypedMessage<ExplainScreenMessage>({ baseMessage, inputs, isInitialMessage });
    }

    if (isLessonFeedbackFormMessageInputs(inputs)) {
        return createTypedMessage<LessonFeedbackFormMessage>({ baseMessage, inputs, isInitialMessage });
    }

    if (isReviewLessonMessageInputs(inputs)) {
        return createTypedMessage<ReviewLessonMessage>({ baseMessage, inputs, isInitialMessage });
    }

    throw new Error('Unsupported content for TutorBotSystemMessage');
}

function createTypedMessage<T extends TutorBotSystemMessage>({
    baseMessage,
    inputs,
    isInitialMessage = false,
}: {
    inputs: {
        content: T['content'];
        payload: T['payload'];
    };
    baseMessage: BaseMessage;
    isInitialMessage: boolean;
}): T {
    const { content, payload } = inputs;

    // Explicitly construct the message object ensuring type safety
    const message = {
        ...baseMessage,
        content,
        payload,
        isInitialMessage,
    } as T;

    return message;
}
