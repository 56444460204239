import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { type AnyObject } from '@Types';
import deepEqual from 'deep-equal';
import { getAvailableUserIds } from '../selectors';
import { useCreateUserPromiseFromIds } from './useCreateUserPromiseFromIds';
import { setAvailableUsers } from '../actions';

const POLL_INTERVAL = 90 * 1000; // 1.5 minutes

// This hook ensures that we he have up to date user data stored in redux.
export const usePollForUsers = () => {
    const dispatch = useDispatch();
    const availableUserIds = useSelector(getAvailableUserIds, deepEqual);

    const createUserPromiseArray = useCreateUserPromiseFromIds();

    useEffect(() => {
        const functionToFetchUsers = async () => {
            await Promise.all(createUserPromiseArray(availableUserIds)).then((...userArraysArray) => {
                const updatedUsers = userArraysArray.flat().flatMap(a => a.map(u => u.serialize())) as AnyObject[];

                dispatch(setAvailableUsers(updatedUsers));
            });
        };

        functionToFetchUsers();
        // update the user immediately and then poll to keep up to date
        const intervalTimer = setInterval(async () => {
            await functionToFetchUsers();
        }, POLL_INTERVAL);

        return () => clearInterval(intervalTimer);
    }, [availableUserIds, createUserPromiseArray, dispatch]);
};
