/* eslint-disable max-lines-per-function */
import GroupChannelHandler from '@sendbird/uikit-react/handlers/GroupChannelHandler';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { type AnyObject } from '@Types';
import { careerProfilesApi } from 'CareerProfiles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAvailableUser, addSuggestedUser, setUserChannelMap } from '../actions';
import { getUserChannelMap, getSerializedUsersToMessage } from '../selectors';
import { useMessagingConfig } from './useMessagingConfig';

export const useAddChannelUsersUpdatedListener = () => {
    const { userId } = useMessagingConfig();
    const sbContext = useSendbirdStateContext();
    const sdk = sendbirdSelectors.getSdk(sbContext);

    const dispatch = useDispatch();

    const userChannelMap = useSelector(getUserChannelMap);
    const serializedUsersToMessage = useSelector(getSerializedUsersToMessage);

    useEffect(() => {
        sdk.groupChannel.addGroupChannelHandler(
            'addedToChannelHandler',
            new GroupChannelHandler({
                onUserJoined: async (c, u) => {
                    const isOtherUser = u.userId !== userId;
                    const existingProfile = serializedUsersToMessage?.profilesById[u.userId];
                    const user = u.serialize() as AnyObject;

                    if (isOtherUser) {
                        if (!existingProfile) {
                            const profile = await careerProfilesApi.makeRequest('getProfileForSendbirdById', u.userId);

                            if (profile) {
                                dispatch(addAvailableUser({ user, profile }));
                                dispatch(addSuggestedUser(user));
                            }
                        } else {
                            dispatch(addSuggestedUser(user));
                        }
                    }

                    if (!userChannelMap) return;

                    const [prevKey, existingChannelUrl] =
                        Object.entries(userChannelMap).find(([_, url]) => url === c.url) || [];

                    const updatedKey = JSON.stringify(
                        c.members
                            .filter(m => m.userId !== userId)
                            .map(m => m.userId)
                            .sort(),
                    );

                    // If we had a value for an existing channel but someone was added, update the key
                    if (prevKey && existingChannelUrl) {
                        const userChannelMapClone = { ...userChannelMap };
                        delete userChannelMapClone[prevKey];

                        dispatch(setUserChannelMap({ ...userChannelMapClone, [updatedKey]: c.url }));
                        return;
                    }

                    // add the new entry
                    if (!userChannelMap[updatedKey]) {
                        dispatch(setUserChannelMap({ ...userChannelMap, [updatedKey]: c.url }));
                    }
                },
                onUserLeft: (c, u) => {
                    if (!userChannelMap) return;

                    const [prevKey, existingChannelUrl] =
                        Object.entries(userChannelMap).find(([_, url]) => url === c.url) || [];
                    // If we had a value for an existing channel but someone left, update channelMap
                    if (existingChannelUrl && prevKey) {
                        const userChannelMapClone = { ...userChannelMap };
                        delete userChannelMapClone[prevKey];

                        if (u.userId !== userId) {
                            const updatedKey = JSON.stringify(
                                c.members
                                    .filter(m => m.userId !== userId)
                                    .map(m => m.userId)
                                    .sort(),
                            );

                            dispatch(setUserChannelMap({ ...userChannelMapClone, [updatedKey]: c.url }));
                        } else {
                            // If the current user left, just remove the entry
                            dispatch(setUserChannelMap({ ...userChannelMapClone }));
                        }
                    }
                },
            }),
        );

        return () => {
            sdk.groupChannel.removeGroupChannelHandler('addedToChannelHandler');
        };
    }, [dispatch, sdk, serializedUsersToMessage?.profilesById, userChannelMap, userId]);
};
