import cacheAngularTemplate from 'cacheAngularTemplate';

import angularModule from './front_royal_wrapper_module';
import template from '../views/app_shell.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('appShell', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $route = $injector.get('$route');
        let AppHeaderViewModel;

        return {
            restrict: 'E',
            scope: {
                resolvingRouteTakingSomeTime: '<',
                routeAnimationClasses: '&',
            },
            templateUrl,
            link(scope) {
                Object.defineProperty(scope, 'signingOut', {
                    get() {
                        return $rootScope.signingOut;
                    },
                });

                Object.defineProperty(scope, 'inPlayer', {
                    get() {
                        return AppHeaderViewModel && AppHeaderViewModel.playerViewModel !== undefined;
                    },
                });

                Object.defineProperty(scope, 'hideMobileMenu', {
                    get() {
                        if (!AppHeaderViewModel) return true;
                        return AppHeaderViewModel.hideMobileMenu;
                    },
                });

                // Both the angular and react wrapper elements are always on the screen, but one of them is
                // always empty. In some cases (but myseteriously not all), we've seen the angular element
                // block clicks on the react element, so we disable pointer events on the angular element
                // when react is handling the route
                Object.defineProperty(scope, 'reactHandlingRoute', {
                    get() {
                        return $route?.current?.$$route?.routeHandledBy === 'react';
                    },
                });

                // AppHeaderViewModel uses the config internally and I was seeing some odd behavior
                // where the appHeader directive's link function was getting run before the link
                // function for the appShell directive, which can result in some odd UI behavior
                // (e.g. the app-header being a different color on notch devices than the notch
                // portion of the screen).
                let cancelManageMobileMenuVisibility;
                ConfigFactory.getConfig().then(() => {
                    AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
                    AppHeaderViewModel.setBodyBackground();
                    cancelManageMobileMenuVisibility = AppHeaderViewModel.manageMobileMenuVisibility();
                    scope.showApp = true;
                });

                const listenerCancelers = [];

                listenerCancelers.push(
                    $rootScope.$watch('currentUser', () => {
                        scope.currentUser = $rootScope.currentUser;
                    }),
                );

                scope.$on('$destroy', () => {
                    listenerCancelers.forEach(func => {
                        func();
                    });
                });

                scope.$on('$destroy', () => cancelManageMobileMenuVisibility?.());
            },
        };
    },
]);
