import buildApiResponse from 'buildApiResponse';
import getVersionId from 'getVersionId';
import isPresent from 'isPresent';
import { type FrontRoyalStore, type LessonProgressRecord, type StreamProgressRecord } from 'FrontRoyalStore';
import { type IRequestConfig, type auto } from 'angular';
import { type AnyObject } from '@Types';

type HandleRequestParams = {
    lessonProgress: LessonProgressRecord;
    config: IRequestConfig;
    $injector: auto.IInjectorService;
};

async function handleRequest({ lessonProgress, config, $injector }: HandleRequestParams) {
    const frontRoyalStore = $injector.get<FrontRoyalStore>('frontRoyalStore');
    const [responseRecord, meta] = await saveLessonAndStreamProgress(frontRoyalStore, lessonProgress, config);

    return buildApiResponse(
        config,
        {
            lesson_progress: [responseRecord],
        },
        meta,
    );
}

// When a stream progress record is first created in the client code and saved, the meta object that
// gets here will only have a few properties set on it. StreamProgressRecordMeta defines the rest of the
// properties from StreamProgressRecord as optional using <Partial>
type PropIncludedInCreatedRecord =
    | 'user_id'
    | 'complete'
    | 'lesson_bookmark_id'
    | 'locale_pack_id'
    | 'time_runs_out_at';
type StreamProgressRecordMeta = Pick<StreamProgressRecord, PropIncludedInCreatedRecord> &
    Partial<Omit<StreamProgressRecord, PropIncludedInCreatedRecord>>;

async function saveLessonAndStreamProgress(
    frontRoyalStore: FrontRoyalStore,
    lessonProgress: LessonProgressRecord,
    config: IRequestConfig,
) {
    const now = Math.floor(new Date().getTime() / 1000);

    // We can do [0] because the client only ever sends up
    // one stream progress at a time.  The api supports sending
    // multiples for when the store is flushed.  See flushStoredLessonProgress
    const streamProgress = (config?.data?.meta?.stream_progress_records as StreamProgressRecordMeta[])?.[0];

    const record =
        (await frontRoyalStore.retryOnHandledError(db =>
            db.lessonProgress
                .where('[user_id+locale_pack_id]')
                .equals([lessonProgress.user_id, lessonProgress.locale_pack_id])
                .first(),
        )) || lessonProgress;

    // The logic here matches what is on the server in LessonProgress::create_or_update
    ['frame_bookmark_id', 'frame_history', 'frame_durations'].forEach(key => {
        // FIXME: TS knows that the value for these keys are different types, so it thinks you could be doing
        // something like record['frame_history'] = lessonProgress['frame_durations'] which have different types.
        record[key as 'frame_bookmark_id'] = lessonProgress[key as 'frame_bookmark_id'];
    });

    if (record) {
        mergeCompletedFrames(record, lessonProgress.completed_frames);
        mergeChallengeScores(record, lessonProgress.challenge_scores);
    }

    if (isPresent(lessonProgress.best_score) && lessonProgress.best_score >= (record.best_score || 0)) {
        record.best_score = lessonProgress.best_score;
    }

    record.created_at = record.created_at || now;
    record.started_at = record.started_at || now;
    record.last_progress_at = now;
    record.synced_to_server = 0;
    record.fr_version = getVersionId();

    // We want to replicate what the server does by making sure to never set
    // `complete` or `completed_at` from a truthy value back to a falsey value.
    record.complete = record.complete || lessonProgress.complete;
    record.completed_at = record.complete && !record.completed_at ? now : record.completed_at;

    // When we flush the lesson progress to the server, we have to bundle
    // the stream up with the lesson
    if (streamProgress?.locale_pack_id) {
        // In almost all cases, each lesson progress record should
        // be associated with one stream_locale_pack_id, but conceptually
        // there could be more.
        if (!record.linked_stream_locale_pack_ids) {
            record.linked_stream_locale_pack_ids = [];
        }
        if (!record.linked_stream_locale_pack_ids.includes(streamProgress.locale_pack_id)) {
            record.linked_stream_locale_pack_ids.push(streamProgress.locale_pack_id);
        }
    }

    // We used to save the stream progress to the store first and then the lesson progress, but we saw
    // an issue where API requests to save test lesson progress had the stream progress record marked
    // as `complete: true`, but the lesson progress was marked as `complete: false` because the request
    // fired between when the stream progress and lesson progress were independently saved to the store.
    // To fix this, we need to make sure to save the lesson progress to the store fist and then the stream
    // progress afterwards since it would be theoretically fine for the API request to have the lesson
    // progress marked as complete while the stream progress isn't.
    await frontRoyalStore.retryRequestOnHandledError('saveProgress', {
        table: 'lessonProgress',
        records: [record],
    });

    let streamMeta;
    if (streamProgress) {
        // Must come AFTER we save the lesson progress to store. See comment above
        // where we save the lesson progress to the store.
        streamMeta = await saveStreamProgress({ frontRoyalStore, streamProgress, now });
    }

    // FIXME: we should just return modifications (this is post-MVP, when we switch to
    // optimistic locking.  For now
    // we should be able to avoid changing the logic in lesson_progress.js#_save)
    return [record, streamMeta];
}

// This is the same logic as the server-side method of the same name.
function mergeCompletedFrames(existingRecord: LessonProgressRecord, incomingCompletedFrames: AnyObject) {
    if (!incomingCompletedFrames) return;

    if (!Object.keys(incomingCompletedFrames).length && !existingRecord.for_test_lesson) {
        existingRecord.completed_frames = {};
        return;
    }

    existingRecord.completed_frames = {
        ...existingRecord.completed_frames,
        ...incomingCompletedFrames,
    };
}

// This is the same logic as the server-side method of the same name.
function mergeChallengeScores(existingRecord: LessonProgressRecord, incomingChallengeScores: AnyObject) {
    if (!incomingChallengeScores) return;

    if (!Object.keys(incomingChallengeScores).length && !existingRecord.for_test_lesson) {
        existingRecord.challenge_scores = {};
    }

    Object.keys(incomingChallengeScores).forEach(key => {
        const score = incomingChallengeScores[key];
        const currentScore = existingRecord.challenge_scores[key];
        const hasCurrentScore = key in existingRecord.challenge_scores;

        const forTestOrAssessmentLesson = existingRecord.for_test_lesson || existingRecord.for_assessment_lesson;
        const shouldUpdateScore =
            (forTestOrAssessmentLesson && !hasCurrentScore) || (!forTestOrAssessmentLesson && score !== currentScore);

        if (shouldUpdateScore) {
            existingRecord.challenge_scores[key] = score;
        }
    });
}

// eslint-disable-next-line max-lines-per-function
async function saveStreamProgress({
    frontRoyalStore,
    streamProgress,
    now,
}: {
    frontRoyalStore: FrontRoyalStore;
    streamProgress: StreamProgressRecordMeta;
    now: number;
}): Promise<{
    lesson_streams_progress: StreamProgressRecord;
    append_to_favorite_lesson_stream_locale_packs?: { id: string }[];
}> {
    let appendToFavoriteLessonStreamLocalePacks: { id: string }[] | undefined;

    const existingRecord = await frontRoyalStore.retryOnHandledError(db =>
        db.streamProgress
            .where('[user_id+locale_pack_id]')
            .equals([streamProgress.user_id, streamProgress.locale_pack_id])
            .first(),
    );

    let record: StreamProgressRecord;
    if (existingRecord) {
        record = {
            ...existingRecord,
            fr_version: getVersionId(),
            synced_to_server: 0,
        };

        (['official_test_score', 'lesson_bookmark_id'] as const).forEach(key => {
            // FIXME: see related comment above in saveLessonAndStreamProgress
            record[key as 'lesson_bookmark_id'] = streamProgress[key as 'lesson_bookmark_id'];
        });

        // We want to replicate what the server does by making sure to never set
        // `complete` or `completed_at` from a truthy value back to a falsey value.
        record.complete = record.complete || streamProgress.complete;

        // When the record is just marked as complete, streamProgress.completed_at should be present
        // (see stream.js#setCompleteIfAllLessonsComplete). If for some unexpected reason it's not,
        // fallback to now to ensure data integrity.
        if (record.complete && !record.completed_at) {
            record.completed_at = streamProgress.completed_at || now;
        }
    } else {
        record = {
            ...streamProgress,
            locale_pack_id: streamProgress.locale_pack_id,
            user_id: streamProgress.user_id,
            created_at: now,
            updated_at: now,
            started_at: now,
            last_progress_at: now,
            completed_at: null,
            synced_to_server: 0,
            waiver: null,
            fr_version: getVersionId(),
            official_test_score: null,
            certificate_image: null,
            time_runs_out_at: null,
        };

        appendToFavoriteLessonStreamLocalePacks = [
            {
                id: record.locale_pack_id,
            },
        ];
    }

    await frontRoyalStore.retryRequestOnHandledError('saveProgress', {
        table: 'streamProgress',
        records: [record],
    });

    return {
        lesson_streams_progress: record,
        append_to_favorite_lesson_stream_locale_packs: appendToFavoriteLessonStreamLocalePacks,
    };
}

export default async function lessonProgressRequestInterceptor(
    config: IRequestConfig,
    $injector: auto.IInjectorService,
) {
    const isSave = ['post', 'put'].includes(config.method.toLowerCase());
    if (!isSave) return null;

    // Don't intercept when we're trying to send results to the server
    if (config?.data?.get && JSON.parse(config.data.get('meta'))?.flushing_front_royal_store) {
        return null;
    }

    const isLessonProgressCall = config.url.match('api/lesson_progress.json');
    if (isLessonProgressCall) {
        return () =>
            handleRequest({
                lessonProgress: config.data.record,
                config,
                $injector,
            });
    }

    return null;
}
