import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useErrorLogService } from 'ErrorLogging';
import { modifyHref, modifyTarget } from './linkHelpers';
import { type LinkTarget } from './Navigation.types';

const useSafeLocation = () => {
    try {
        return useLocation();
    } catch {
        // no-op
    }

    return null;
};

// This hook takes an href and target and returns the modified versions of them.
// If we ever succeed in removing AnchorLink and completely unifying our link components then
// this logic can move to the ClickableText component (which might have been renamed to Link by then)
export function useLinkParams({ href, target }: { href?: string | null; target?: LinkTarget | null }) {
    const location = useSafeLocation();
    const ErrorLogService = useErrorLogService();

    // the react-router-dom <Link> component that pixelmatters previously used treats "." as the current page,
    // so we're re-implementing that behavior here
    if (href === '.') {
        href = location?.pathname || null;

        if (href === null) {
            ErrorLogService.notify('Cannot use href="." outside of react router', null);
        }
    }

    href = useMemo(() => (href ? modifyHref(href) : href), [href]);
    target = useMemo(() => modifyTarget(href, target), [href, target]);

    return { href, target };
}
