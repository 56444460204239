import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/application_questions_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { Brand } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

const MIN_CHAR_COUNT = 400;

angularModule.directive('applicationQuestionsForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const answersTooShortHelper = $injector.get('answersTooShortHelper');
        const TranslationHelper = $injector.get('TranslationHelper');
        const Cohort = $injector.get('Cohort');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                const branding = Cohort.branding(scope.programType);
                scope.brandName = branding === Brand.valar ? scope.brandNameStandard : scope.brandNameLong;

                const translationHelper = new TranslationHelper(
                    'careers.edit_career_profile.application_questions_form',
                );

                EditCareerProfileHelper.logEventForApplication('viewed-application-questions');
                FormHelper.supportForm(scope, scope.application_questions);

                scope.howHearPlaceholder = translationHelper.get('how_hear_placeholder');
                scope.howHearOptionKeys = [
                    'economist',
                    'facebook',
                    'instagram',
                    'linkedin',
                    'friend',
                    'colleague_manager_hr',
                    'search_engine',
                    'poets_quants',
                    'quantic_blog',
                    'google_or_apple_app_store',
                    'wsj',
                    'tldr_newsletter',
                ];

                const applicationQuestionsForm = scope.currentUser.relevantCohort.applicationFormConfigs.find(
                    formConfig => formConfig.stepName === 'application_questions',
                );

                scope.shortAnswerFieldConfigs = Object.entries(applicationQuestionsForm.inputs).map(
                    ([key, config]) => ({
                        key,
                        ...config,
                    }),
                );

                scope.$on('beforeSaveForm', (_e, shouldSaveForm) => {
                    const modalNotShown = !answersTooShortHelper.maybeShowModal(
                        scope.programFamilyFormDatum,
                        scope.shortAnswerFieldConfigs.map(({ key }) => key).filter(v => v !== 'anythingElseToTellUs'),
                        MIN_CHAR_COUNT,
                    );

                    shouldSaveForm(modalNotShown);
                });
            },
        };
    },
]);
