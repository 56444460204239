import { Route, Navigate, useParams, Outlet } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';
import { useStudentDashboardVersion } from './useStudentDashboardVersion';
import { StudentDashboard } from './StudentDashboard';

export const ROUTES = {
    STUDENT_DASHBOARD: route('dashboard'),
};

export function getStudentDashboardRoutes() {
    return (
        <Route path={ROUTES.STUDENT_DASHBOARD.relativePath} element={<StudentDashboardV2 />}>
            <Route index element={<EnsureValidStudentDashboardTab />} />
            <Route path=":studyTab" element={<EnsureValidStudentDashboardTab />} />
        </Route>
    );
}

function StudentDashboardV2() {
    const studentDashboardVersion = useStudentDashboardVersion();

    return studentDashboardVersion === 2 ? <Outlet /> : null;
}

function EnsureValidStudentDashboardTab() {
    const validTabs = ['currently-studying', 'curriculum']; // Valid tabs
    const { studyTab } = useParams();
    const defaultPath = '/dashboard/currently-studying';

    // Redirect invalid or undefined studyTab
    if (!studyTab || !validTabs.includes(studyTab)) {
        return <Navigate to={defaultPath} replace />;
    }

    // Render StudentDashboard if studyTab is valid
    return <StudentDashboard />;
}
