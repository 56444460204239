import { beforeUnsettingCurrentUser } from 'Authentication';
import { storeProvider } from 'ReduxHelpers';
import { reset as resetMessagingSlice } from 'Messaging';
import angularModule from './admin_module';

angularModule.factory('LogInAs', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const $location = $injector.get('$location');
        const EventLogger = $injector.get('EventLogger');
        const ngToast = $injector.get('ngToast');
        const User = $injector.get('User');
        const DialogModal = $injector.get('DialogModal');
        const HttpQueue = $injector.get('HttpQueue');
        const ClientStorage = $injector.get('ClientStorage');
        const safeApply = $injector.get('safeApply');

        return {
            logInAsUser(user) {
                if (!window.confirm(`Are you sure you want to log in as user ${user.email}?`)) {
                    return undefined;
                }

                // Hide any dialog modals that may be open when this function is called so they don't
                // stick around on the home page when logging in as the target user
                DialogModal.hideAlerts();

                // NOTE: FrontRoyalStore#requestInterceptor is relying on 'logged_in_as'
                // being set the way it is now, so if you're moving this around,
                // read the code over there first
                ClientStorage.setItem('logged_in_as', true);
                ClientStorage.setItem('last_visited_route', $location.url());

                // Reset the HTTPQueue
                HttpQueue.reset();

                return this.beforeUnsettingCurrentUser()
                    .then(() =>
                        // Fire off view-as login request and toggle UI
                        $http.post(`${window.ENDPOINT_ROOT}/api/users/log_in_as.json`, {
                            record: {
                                logged_in_as_user_id: user.id,
                            },
                        }),
                    )
                    .then(response => {
                        $rootScope.currentUser = User.new(response.data.data);
                        // Reset messaging so that the cached data matches the current user
                        storeProvider.dispatch(resetMessagingSlice());
                        ClientStorage.setItem('logged_in_as_user_id', $rootScope.currentUser.id);

                        // disable logging of progress in lessons when logged in as another person
                        $rootScope.currentUser.ghostMode = true;
                        ClientStorage.setItem('lastLoggedInAsUserId', user.id);

                        // disable event logging when logged in as another person
                        EventLogger.disabled = true;

                        ngToast.create({
                            content: `Successfully logged in as ${user.email} Be careful!`,
                            className: 'success',
                        });
                        $rootScope.goHome();
                        safeApply($rootScope);
                    });
            },

            // local function to mock in specs
            beforeUnsettingCurrentUser: () => beforeUnsettingCurrentUser($injector),
        };
    },
]);
