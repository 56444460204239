/* eslint-disable operator-linebreak */
import { useState, useEffect, useMemo } from 'react';
import { AngularContext, useAngularContext } from 'AngularContext';
import { angularDirectiveToReact } from 'Angular2reactHelper';
import { isEqual } from 'lodash/fp';
import { getRelevantCohorts } from 'Users';
import useAMap from './useAmap';
import useStudentNetworkMap from './useStudentNetworkMap';
import StudentMapGroup from './StudentMapGroup';
import useEventMapGroup from './useEventMapGroup';
import useIsMobileMixin from './useIsMobileMixin';
import AMapContext from './AMapContext';
import PartialAccessModal from './PartialAccessModal';

const NetworkMapFilterBox = angularDirectiveToReact('FrontRoyal.StudentNetwork', 'networkMapFilterBox', {
    studentNetworkMapViewModel: '<',
});
const StudentNetworkRecommendedEventList = angularDirectiveToReact(
    'FrontRoyal.StudentNetwork',
    'studentNetworkRecommendedEventList',
    {
        eventsMapLayer: '<',
    },
);
const StudentNetworkEventTypeFilters = angularDirectiveToReact(
    'FrontRoyal.StudentNetwork',
    'studentNetworkEventTypeFilters',
    {
        eventsMapLayer: '<',
    },
);
const FrontRoyalSpinner = angularDirectiveToReact('FrontRoyalSpinner', 'frontRoyalSpinner', {});

// eslint-disable-next-line max-lines-per-function
export default function NetworkAmap(props) {
    const container = 'amap-container';
    const [style, setMapStyle] = useState({ height: '100%' });
    const filterStyle = { position: 'absolute', top: 0, left: 0 };

    const $injector = useAngularContext();

    const { eventId, showEvents } = props;
    const $rootScope = $injector.get('$rootScope');
    const [currentUser] = useState($rootScope.currentUser);

    const AMap = useAMap({ $injector });
    const [map, mapInfo] = useStudentNetworkMap(AMap, container, currentUser);
    const { isMobile } = useIsMobileMixin();

    const [viewModel, setViewModel] = useState();
    const [activeLayer, setActiveLayer] = useState();
    const [filters, setFilters] = useState({
        program_type: ['mba', 'emba'], // FIXME: Should this include emba_strategic_leadership and mba_leadership? (https://trello.com/c/0qkpPMus)
        only_local: true,
    });
    const [advancedSearchModel, setAdvancedSearchModel] = useState({
        focusedFeature: null,
        inAdvancedSearchMode: false,
    });
    const [showRecommendedEventsList, setShowRecommendedEventsList] = useState(false);
    const [showingEventTypeFiltersModal, setShowingEventTypeFiltersModal] = useState(false);

    const [showPartialAccessModal, setShowPartialAccessModal] = useState(
        !currentUser?.hasAccessToFullStudentNetworkProfiles,
    );
    const userCohortIds = useMemo(() => getRelevantCohorts(currentUser).map(cohort => cohort.id), [currentUser]);
    const [eventsMapLayer, eventMarkers] = useEventMapGroup(AMap, map, userCohortIds, activeLayer, $injector);
    const StudentNetworkMapViewModel = $injector.get('StudentNetworkMapViewModel');

    useEffect(() => {
        if (!map || !eventsMapLayer) {
            return undefined;
        }

        eventsMapLayer.handlers.setShowRecommendedEventsList = setShowRecommendedEventsList;
        eventsMapLayer.handlers.setShowingEventTypeFiltersModal = setShowingEventTypeFiltersModal;

        const model = new StudentNetworkMapViewModel(
            map,
            userCohortIds,
            eventId,
            showEvents,
            { setActiveLayer },
            { setFilters, setAdvancedSearchModel },
            eventsMapLayer,
        );
        setViewModel(model);

        return () => {
            if (model) {
                model.destroy();
            }
        };
    }, [map, $rootScope.currentUser, eventId, showEvents, StudentNetworkMapViewModel, eventsMapLayer, userCohortIds]);

    useEffect(() => {
        if (
            viewModel &&
            viewModel.studentsMapLayer &&
            !isEqual(viewModel.studentsMapLayer.focusedClusterFeature, advancedSearchModel.focusedFeature)
        ) {
            viewModel.studentsMapLayer.focusedClusterFeature = advancedSearchModel.focusedFeature;
        }
    }, [viewModel, advancedSearchModel]);

    useEffect(() => {
        if (!eventsMapLayer || isMobile) {
            return;
        }

        setMapStyle({ height: '100%', width: showRecommendedEventsList ? 'calc(100% - 478px)' : '100%' });
    }, [eventsMapLayer, isMobile, showRecommendedEventsList]);

    return (
        <div className="map-wrapper h-full">
            <div id={container} style={style}>
                <div className={showRecommendedEventsList ? 'showing-recommended-events' : ''}>
                    <div
                        id="filter-box"
                        className={advancedSearchModel.inAdvancedSearchMode ? 'candidate-list-visible' : null}
                        style={filterStyle}
                    >
                        {viewModel && <NetworkMapFilterBox studentNetworkMapViewModel={viewModel} />}
                    </div>
                    {!viewModel && <FrontRoyalSpinner className="centered" />}
                    {viewModel && showingEventTypeFiltersModal && (
                        <StudentNetworkEventTypeFilters eventsMapLayer={viewModel.eventsMapLayer} />
                    )}
                </div>
            </div>
            {showRecommendedEventsList && viewModel && (
                <StudentNetworkRecommendedEventList eventsMapLayer={viewModel.eventsMapLayer} />
            )}
            {eventMarkers?.length > 0 && <div style={{ height: '0px' }}>{eventMarkers}</div>}
            {showPartialAccessModal && !viewModel?.eventsMapLayer?.showingEventTypeFiltersModal && (
                <PartialAccessModal dismissPartialAccessModal={{ setShowPartialAccessModal }} $injector={$injector} />
            )}

            <AngularContext.Provider value={$injector}>
                {AMap && map && activeLayer && (
                    <AMapContext.Provider value={AMap}>
                        {activeLayer.name === 'students' && (
                            <StudentMapGroup
                                map={map}
                                mapInfo={mapInfo}
                                filters={filters}
                                advancedSearchModel={advancedSearchModel}
                                setAdvancedSearchModel={setAdvancedSearchModel}
                                studentsMapLayer={viewModel.studentsMapLayer}
                                isMobile={isMobile}
                            />
                        )}
                    </AMapContext.Provider>
                )}
            </AngularContext.Provider>
        </div>
    );
}
