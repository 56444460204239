import { navigationHelper } from 'Navigation';
import { useMemo } from 'react';

// We should prefer using the `ClickableText` component for linking rather than using this hook. If we
// run into a new sitation where we want `loadUrl` or `loadRoute` in react, we should reconsider
// creating a new helper that follows the logic in `ClickableText` and `useLinkParams` rather than
// the older logic here.
export function useNavigationHelper() {
    return useMemo(() => navigationHelper(), []);
}
