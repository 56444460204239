import angularModule from 'AdmissionsGuidance/angularModule/scripts/admissions_guidance_module';
import template from 'AdmissionsGuidance/angularModule/views/past_due_projects_list.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import projectResource from 'vectors/project_resource.svg';
import { navigationHelper } from 'Navigation';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('pastDueProjectsList', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                pastDueProjects: '<',
                alternateStyling: '<',
            },
            link(scope) {
                scope.projectResource = projectResource;
                const { loadUrl } = navigationHelper();
                scope.loadUrl = loadUrl; // on scope for specs

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.showAllPastDueProjects = false;
                scope.toggleShowAllPastDueProjects = () => {
                    scope.showAllPastDueProjects = !scope.showAllPastDueProjects;
                };

                scope.submitProject = () => {
                    scope.loadUrl(`/projects/upload?cohort_name=${scope.currentUser.relevantCohort.name}`, '_blank');
                };
            },
        };
    },
]);
