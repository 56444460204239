import { memo, type ReactNode } from 'react';
import { Link as NextUiLink } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import clsx from 'clsx';
import { type LinkTarget, useLinkParams } from 'Navigation';

type IconName = 'none' | 'right-arrow' | 'chevron-down' | 'chevron-up';
type Props = {
    children: ReactNode;
    color?: 'primary' | 'slate-grey';
    weight?: 'normal' | 'medium' | 'semibold';
    anchorIcon?: IconName;
    href?: string | null;
    target?: LinkTarget | null;
    allowWrap?: boolean;
};

function AnchorIcon({ iconName }: { iconName: IconName }) {
    switch (iconName) {
        case 'right-arrow':
            return <FontAwesomeIcon className="ms-1 rtl:rotate-180" icon={faArrowRight} />;
        case 'chevron-down':
            return <FontAwesomeIcon className="ltr:ml-1 rtl:mr-1" icon={faChevronDown} />;
        case 'chevron-up':
            return <FontAwesomeIcon className="ltr:ml-1 rtl:mr-1" icon={faChevronUp} />;
        default:
            return null;
    }
}

function ClickableTextComponent({
    color = 'primary',
    weight = 'normal',
    anchorIcon = 'none',
    target,
    href,
    allowWrap = false,
    children,
}: Props) {
    // NextUiLink includes the `inline-flex` class. This is necessary for icons to be properly positioned,
    // but it will force long links in a paragraph to be pushed down to the next line rather than
    // wrapping in the middle.
    if (allowWrap && anchorIcon !== 'none') {
        throw new Error('Links with icons cannot be configured to wrap');
    }

    const className = clsx(color === 'slate-grey' ? 'text-slate-grey' : '', allowWrap ? 'inline' : '', {
        'font-normal': weight === 'normal',
        'font-medium': weight === 'medium',
        'font-semibold': weight === 'semibold',
    });

    ({ href, target } = useLinkParams({ href, target }));

    // FIXME: special offlineMode handling like loadUrl has. https://trello.com/c/rpUhP05h

    // NextUiLink will handle the link according to the rules defined in the navigate and useHref properties
    // passed to NextUIProvider in the FrontRoyalRouter file
    return (
        <NextUiLink
            className={className}
            underline="always"
            showAnchorIcon={anchorIcon !== 'none'}
            anchorIcon={<AnchorIcon iconName={anchorIcon} />}
            href={href || undefined}
            target={target || undefined}
        >
            {children}
        </NextUiLink>
    );
}

export const ClickableText = memo(ClickableTextComponent) as typeof ClickableTextComponent;
