import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { type navigationHelper } from 'Navigation';
import nakeemaSrc from 'images/photos/nakeema-stefflbauer.png';
import andrewSrc from 'images/photos/andrew-chan.jpg';
import tomSrc from 'images/photos/tom-garvey.jpg';
import { buttonClasses } from './classes';

type StudentSpotlightProps = {
    loadRoute: ReturnType<typeof navigationHelper>['loadRoute'];
};

const SpotlightedStudents = [
    {
        name: 'Andrew Chan',
        company: 'VinFast',
        title: 'Global Charging Manager',
        education: 'Chinese University of Hong Kong',
        imageSrc: andrewSrc,
    },
    {
        name: 'Nakeema Stefflbauer, PhD',
        company: 'Frauenloop',
        title: 'Founder and CEO',
        education: 'Harvard University',
        imageSrc: nakeemaSrc,
    },
    {
        name: 'Tom Garvey',
        company: 'Google',
        title: 'Manager, Strategy and Operations',
        education: 'University of Oxford',
        imageSrc: tomSrc,
    },
] as const;

export const StudentSpotlight: FC<StudentSpotlightProps> = ({ loadRoute }) => {
    const { t } = useTranslation('back_royal');

    return (
        <>
            <h2 className="mb-3.5 ml-2.5 mr-0 mt-0 uppercase text-beige-beyond-dark">
                {t('featured_students.featured_students.student_spotlight')}
            </h2>
            <div className="relative flex h-[425px] flex-col rounded bg-white pt-3.5">
                {SpotlightedStudents.map(({ imageSrc, name, company, title, education }) => (
                    <div className="mb-4 flex lg:mb-8 ltr:ml-3.5 ltr:mr-5 rtl:ml-5 rtl:mr-3.5" key={name}>
                        <img src={imageSrc} alt={name} className="h-20 w-20 rounded-full" />
                        <div className="basis-3/4 pt-0.5 ltr:ml-3.5 rtl:mr-3.5">
                            <p className="mb-1 text-md font-semibold leading-4.5 text-beige-for-text">{name}</p>
                            <p className="mb-1 text-sm font-semibold leading-4.5 text-beige-beyond-dark">
                                {company}, {title}
                            </p>
                            <p className="mb-1 text-sm font-normal leading-4.5 text-beige-beyond-dark">{education}</p>
                        </div>
                    </div>
                ))}
                <button
                    type="button"
                    className={clsx(buttonClasses, 'absolute', 'bottom-0')}
                    onClick={() => {
                        loadRoute('/student-network');
                    }}
                >
                    {t('featured_students.featured_students.preview_network')}
                </button>
            </div>
        </>
    );
};
