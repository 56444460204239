import { type CurrentUserIguanaObject } from 'Users';
import { type navigationHelper } from 'Navigation';
import { type ProgramTypeConfig } from 'Program';
import { linearBlueGradientStyle, linearMaskImageGradient } from '../../helpers/gradients';
import { type ExecEdEligibilityBundle } from '../../ExecEdEligibility.types';
import { CertModalContent } from './CertModalContent';
import { CertModalHeader } from './CertModalHeader';

type Props = {
    currentUser: CurrentUserIguanaObject;
    filteredEligibilityBundle: ExecEdEligibilityBundle;
    programTypeConfigs: { [programTypeKey: string]: ProgramTypeConfig };
    loadUrl: ReturnType<typeof navigationHelper>['loadUrl'];
};

export function CertCongratsModal({ currentUser, filteredEligibilityBundle, programTypeConfigs, loadUrl }: Props) {
    return (
        <div
            className="cert-accepted-modal flex h-screen max-h-screen w-full flex-col overflow-y-auto font-apercu text-white"
            style={{ ...linearBlueGradientStyle('150deg') }}
            data-testid="cert-accepted-modal"
        >
            {/* Modal Header */}
            <div
                // This and some other divs are sticky at md+ widths so that the whole modal container may scroll but only the 'Additional Programs' actually moves
                className="mb-[30px] flex flex-col items-center justify-start p-5 md:sticky md:top-0 md:z-10 md:mb-0 md:pb-[80px]"
                style={{
                    // Masks the header so that the 'Additional Programs' fade out at edge when scrolled
                    ...linearMaskImageGradient('to top'),
                    // Adds the same bg as the general modal bg (and at same size),
                    // so that at desktop width we can scroll the content behind it without it showing through.
                    ...linearBlueGradientStyle('150deg'),
                    backgroundSize: '100vw 100vh',
                }}
            >
                <CertModalHeader name={currentUser.name} />
            </div>

            {/* Modal Content */}
            <div className="relative mx-auto w-full md:-top-[25px] md:flex md:max-w-[85%] md:flex-1 md:flex-col md:pt-[40px]">
                <CertModalContent
                    filteredEligibilityBundle={filteredEligibilityBundle}
                    programTypeConfigs={programTypeConfigs}
                    loadUrl={loadUrl}
                    currentUser={currentUser}
                />
            </div>
        </div>
    );
}
