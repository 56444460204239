import { targetBrandConfig } from 'AppBranding';
import { BROWSER_AND_BUILD_INFO } from 'TinyEventLogger';
import cacheAngularTemplate from 'cacheAngularTemplate';
import template from '../views/feedback_form.html';
import angularModule from './feedback_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('feedbackForm', [
    '$injector',
    '$location',

    function factory($injector, $location) {
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const TranslationHelper = $injector.get('TranslationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const AuthFormHelperMixin = $injector.get('AuthFormHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                const translationHelper = new TranslationHelper('feedback.feedback_form');
                AuthFormHelperMixin.onLink(scope);

                scope.form = {};
                scope.form_errors = {};

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'brandFeedbackEmail', {
                    get() {
                        return targetBrandConfig(scope.currentUser, undefined).emailAddressForUsername('feedback');
                    },
                });

                scope.submitFeedback = () => {
                    scope.preventSubmit = true;

                    // Gather notes about the user to attach to the message
                    const notesProperties = angular.extend({}, BROWSER_AND_BUILD_INFO, {
                        url: $location.absUrl(),
                        user_groups: $rootScope.currentUser.userGroupNames().join(','),
                        user_institutions: $rootScope.currentUser.institutionNames().join(','),
                        full_name: $rootScope.currentUser.name,
                    });

                    // if in a lesson, include extra links for editor, etc.
                    if (AppHeaderViewModel.playerViewModel && AppHeaderViewModel.playerViewModel.lesson) {
                        notesProperties.lesson_title = AppHeaderViewModel.playerViewModel.lesson.title;
                        notesProperties.course_title = AppHeaderViewModel.playerViewModel.stream.title;
                        notesProperties.frame_index = AppHeaderViewModel.playerViewModel.activeFrameIndex + 1;
                        notesProperties.lesson_editor = `${
                            $window.ENDPOINT_ROOT + AppHeaderViewModel.playerViewModel.lesson.editorUrl
                        }?frame=${AppHeaderViewModel.playerViewModel.activeFrameId}`;
                        notesProperties.course_editor =
                            $window.ENDPOINT_ROOT + AppHeaderViewModel.playerViewModel.stream.editorUrl;
                        notesProperties.frame_complete = AppHeaderViewModel.playerViewModel.activeFrameComplete;
                        notesProperties.current_challenge_index =
                            AppHeaderViewModel.playerViewModel.currentChallengeIndexInModel;
                        notesProperties.current_challenge_id =
                            AppHeaderViewModel.playerViewModel.currentChallenge?.id || null;
                    }

                    const notes = [];
                    angular.forEach(notesProperties, (value, key) => {
                        notes.push(`${key}: ${value}`);
                    });

                    // add default params
                    scope.form.email = scope.currentUser.email;
                    scope.form.notes = notes.join('\n<br>\n');

                    // Configure to post as a form to /feedback/new
                    $http({
                        method: 'POST',
                        url: `${$window.ENDPOINT_ROOT}/feedback/new`,
                        data: $.param(scope.form), // pass in data as strings
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }, // set the headers so angular passing info as form data (not request payload)
                    }).then(
                        () => {
                            scope.form_errors = {};
                            scope.showFinishedMessage = true;
                        },
                        () => {
                            scope.preventSubmit = false;
                            scope.form_errors.general = translationHelper.get('submit_feedback_failure');
                        },
                    );
                };

                // TODO: share properly with other forms like join and sign in
                scope.formErrorClasses = () => {
                    if (scope.form_errors.general) {
                        return ['form-error', 'active'];
                    }
                    return ['form-error'];
                };

                scope.formValidationClasses = () => {
                    if (scope.form_errors.general) {
                        return ['form-control', 'ng-invalid'];
                    }
                    return ['form-control'];
                };

                scope.openFAQ = () => {
                    scope.loadFaqUrl('/help', '_blank');
                };
            },
        };
    },
]);
