import ClientStorage from 'ClientStorage';
import { SegmentioHelper } from 'Analytics';
import {
    logTinyEvent,
    initializeEvent,
    processForThirdParties,
    preSignupValues,
    getUtmParamsEventIfPresent,
    BROWSER_AND_BUILD_INFO,
} from 'TinyEventLogger';
import { ensureServerClientTimeOffsetOnWindow } from 'ServerTime';
import { OnetrustCookieHelper } from 'Onetrust';
import Auid from 'Auid';

ensureServerClientTimeOffsetOnWindow();

// Some html.erb files need localStorageEnabled
// to be on the window, since they can't import it (We could probably
// refactor those into .js files, but haven't now)
window.localStorageEnabled = ClientStorage.localStorageEnabled;

// Try window.location.host fallback vector to support things like IE compatibility mode,
// where origin may be undefined, in an intranet zone, despite being officially supported.
// Note that we use the ||= operator because in Gatsby the ENDPOINT_ROOT value can be overridden.
// Also note that inline-head-scripts is only used within the web application - our mobile apps
// receive window.ENDPOINT_ROOT from the build pipeline.
if (window.location.origin) {
    window.ENDPOINT_ROOT ||= window.location.origin;
} else if (window.location.host) {
    window.ENDPOINT_ROOT ||= `${window.location.protocol || 'https:'}//${window.location.host}`;
}

// Scrape the UTM params from the query string, store in a domain cookie, and internally log
const utmParamsEvent = getUtmParamsEventIfPresent();
logTinyEvent(
    'loading_page',
    { value: window.performance.now(), ...BROWSER_AND_BUILD_INFO, ...window.cf_geo },
    { bundledEvents: [utmParamsEvent].filter(Boolean) },
);

// Put UTM params scraped from marketing on the window for easy use as GTM Variables.
// For example, overriding the default UTM params in the GA4 Config -- https://support.google.com/analytics/answer/11259997
window.utmMap = ClientStorage.getCookie('utmMap');

// This is used as a fallback in EventLogger.js#log for when the Pixel / Insight Tag may be blocked and
// thus isn't automatically setting the first-party cookies. In that case we can scrape fbclid / li_fat_id
// from the ad link.
const url = new URL(window.location.href);
const fbclid = url.searchParams.get('fbclid');
const li_fat_id = url.searchParams.get('li_fat_id');
if (fbclid) {
    // See https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc#fbc
    ClientStorage.setClientDomainCookie('scraped_fbc', `fb.1.${Date.now()}.${fbclid}`, { expires: 90 }); // Pixel does 90 days
}
if (li_fat_id) {
    ClientStorage.setClientDomainCookie('scraped_li_fat_id', li_fat_id, { expires: 30 }); // Insight Tag does 30 days
}

ClientStorage.forceOnetrustReconsentIfLegacy();
ClientStorage.cleanUpLegacyLocalStorageAuthHeadersInWeb();

// We initialize the Segment snippet immediately. It creates stub methods that can
// start capturing events immediately. But we don't actually load in the real Analytics.js
// until we have a chance to initialize Onetrust. The real Analytics.js script will actually send
// our events to Segment after passing a Onetrust middleware check.
// Note that in CORDOVA we aren't using this code, so we initialize the snippet when initializing
// the AngularJS app.
window.dataLayer ||= []; // https://developers.google.com/tag-platform/tag-manager/datalayer#installation
SegmentioHelper.snippet();

// FIXME: Stop putting this on the window once we have a code sharing strategy -- see https://trello.com/c/GPacEAyZ
window.Auid = Auid;
window.ClientStorage = ClientStorage;
window.initializeEvent = initializeEvent;
window.logTinyEvent = logTinyEvent;
window.OnetrustCookieHelper = OnetrustCookieHelper;
window.preSignupValues = preSignupValues;
window.processForThirdParties = processForThirdParties;
