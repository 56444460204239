export default function storeCurrentUser(injector) {
    const $rootScope = injector.get('$rootScope');
    const frontRoyalStore = injector.get('frontRoyalStore');

    $rootScope.$watch('currentUser', () => {
        const user = $rootScope.currentUser;
        if (!user) return;

        // The currentUserInterceptor also watches for changes to
        // the favorite_lesson_stream_locale_packs list and calls setStreamBookmarks
        // when necessary.
        frontRoyalStore.setStreamBookmarks(user);

        frontRoyalStore.storeCurrentUser(user.asJson());
    });
}
