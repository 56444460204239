import { type ProgramType } from 'Program';

export enum ArticleSiteId {
    quantic = '54ebeb13e4b034c37ea91448',
    valar = '6166fe91ca9e0011a44348f2',
    execEd = '64b97ad8114ed272f0d4904f',
}

export type ArticleRef = {
    id: string;
    number: number;
    collectionId: string;
    slug: string;
    status: 'published' | 'notpublished';
    hasDraft: boolean;
    name: string;
    publicUrl: string;
    popularity: number;
    viewCount: number;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
    lastPublishedAt: string;
};

export type ResourcesAPIResponse<T> = {
    articles: { items: T[]; page: number; pages: number; count: number };
};

export type ArticleSearchParams = {
    query: string;
    programType: ProgramType;
};

export type ArticleSearchResult = {
    id: string;
    collectionId: string;
    siteId: string;
    categoryIds: string[];
    categorySlugs: string[] | null;
    slug: string;
    name: string;
    preview: string;
    url: string;
    docsUrl: string;
    number: number;
    status: 'published' | 'notpublished';
    visibility: 'public' | 'private';
    updatedAt: string;
    hasDraft: boolean;
    lastPublishedAt: string;
};

export type LibraryDatabaseAccessResponse = {
    hasLibraryDatabaseAccess: boolean;
};
