import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useCurrentUser } from 'FrontRoyalAngular';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fade } from 'Fade/Fade';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingBoundary } from 'LoadingBoundary';
import { type ErrorHandlingComponentParams } from 'FrontRoyalErrorBoundary';
import { MessageWidget } from './MessageWidget';
import { useMessagingConfig } from '../utils/useMessagingConfig';
import { MessagingProvider } from './MessagingProvider';
import { UnreadCount } from './UnreadCount';
import { getOpenChat } from '../selectors';
import { setOpenChat, setShowNewMessage } from '../actions';
import { ErrorMessage } from './ErrorMessage';
import { HandleDisconnection } from './HandleDisconnection';
import { ChannelListeners } from './ChannelListeners';

const classes = {
    container: (open: boolean, mouseDown: boolean) =>
        clsx(
            'absolute',
            'ltr:right-[86px]',
            'rtl:right-[65px]',
            'rtl:sm:left-[86px]',
            'rtl:sm:right-auto',
            'top-[calc(50%-13px)]',
            'sm:top-[calc(50%-18px)]',
            'w-[25px]',
            'h-[25px]',
            'sm:w-[37px]',
            'sm:h-[37px]',
            'md:w-[125px]',
            'border-none',
            'sm:border-solid',
            'sm:border',
            'border-white',
            'border-opacity-50',
            { 'border-opacity-100': open },
            { 'bg-coral-dark/[0.6] sm:bg-coral valar:bg-blue-dark/[0.6] sm:valar:bg-blue': !open },
            { 'bg-coral-dark sm:bg-coral-dark/[.65] valar:bg-blue-dark sm:valar:bg-blue-dark/[.65]': open },
            { 'sm:bg-coral-dark/[.25] sm:valar:bg-blue-dark/[.25]': mouseDown },
            'active:border-opacity-100',
            'hover:border-opacity-100',
            'rounded-[4px]',
            'sm:rounded-full',
            'md:rounded-[18px]',
            'p-[5px]',
            'sm:p-[10px]',
            'md:p-[11px_15px_9px_15px]',
            'z-[101]',
            'cursor-pointer',
            'disabled:hover:border-opacity-50',
            'disabled:opacity-70',
            'disabled:pointer-events-none',
        ),
    content: clsx('h-full', 'flex', 'items-center', 'text-white'),
    text: clsx('hidden', 'md:block', 'ms-[7px]'),
    sendbirdProviderWrapper: (open: boolean) => clsx('sendbirdProviderWrapper', open ? 'open' : 'closed'),
};

// This component exists to catch any errors at the top level of the MessagesButton
// Right now there is no UX to handle such a scenario, but we will catch and log the error
// and hide the button altogether
const TopLevelErrorMessage = (props: ErrorHandlingComponentParams) => <ErrorMessage show={false} {...props} />;

export const MessagesButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const user = useCurrentUser();
    const { appId, accessToken, userId } = useMessagingConfig();
    const open = useSelector(getOpenChat);
    const [mouseDown, setMouseDown] = useState(false);
    const [connected, setConnected] = useState(true);

    if (!user?.messagingEnabled || !appId || !accessToken || !userId) return null;

    return (
        <LoadingBoundary errorActions={{ other: TopLevelErrorMessage }}>
            <button
                type="button"
                className={classes.container(open, mouseDown)}
                onMouseDown={() => setMouseDown(true)}
                onMouseUp={() => setMouseDown(false)}
                onMouseOut={() => setMouseDown(false)}
                onBlur={() => setMouseDown(false)}
                disabled={!connected}
                onClick={() => {
                    dispatch(setOpenChat(!open));
                    if (open) {
                        setTimeout(() => {
                            dispatch(setShowNewMessage(false));
                        }, 150);
                    }
                }}
            >
                <div className={classes.content}>
                    <FontAwesomeIcon icon={faCommentDots} color="white" />
                    <p className={classes.text}>{t('messaging.messages')}</p>
                </div>
            </button>
            <div className={classes.sendbirdProviderWrapper(open)}>
                <MessagingProvider>
                    <ChannelListeners />
                    <HandleDisconnection setConnected={setConnected} connected={connected} />
                    <UnreadCount />
                    <Fade open={open} onClick={() => dispatch(setOpenChat(false))}>
                        <MessageWidget />
                    </Fade>
                </MessagingProvider>
            </div>
        </LoadingBoundary>
    );
};
