import { RoomContext } from '@livekit/components-react';
import { type AnyObject } from '@Types';
import { memo, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RoomEvent } from 'livekit-client';
import { voiceCoursePracticeSlice } from './voiceCoursePracticeSlice';
import { type DataMessage } from './VoiceCoursePractice.types';
import { isDataMessage, isTopicsUpdatedMessage } from './DataMessage';

const {
    actions: { setTopics },
} = voiceCoursePracticeSlice;

function parseMessage(payload: Uint8Array): DataMessage | null {
    let messageText: string | undefined;
    try {
        messageText = new TextDecoder().decode(payload); // Convert
        const messageObject = JSON.parse(messageText) as AnyObject;
        if (isDataMessage(messageObject)) return messageObject;
    } catch (e) {
        // FIXME: log error
        console.error(`Error parsing data message: ${messageText || payload}`);
    }

    return null;
}

function onDataReceived(payload: Uint8Array, dispatch: ReturnType<typeof useDispatch>) {
    const message = parseMessage(payload);
    if (!message) {
        console.log('Could not parse message ', payload);
        return;
    }

    if (isTopicsUpdatedMessage(message)) {
        dispatch(setTopics(message.payload.topics));
        return;
    }

    console.log('Unknown data message', message);
}

const RoomEventListenerComponent: React.FC = () => {
    const room = useContext(RoomContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!room) return undefined;

        room.on(RoomEvent.DataReceived, (payload: Uint8Array) => {
            onDataReceived(payload, dispatch);
        });

        return () => {
            room?.removeAllListeners();
        };
    }, [room, dispatch]);

    return null;
};

export const RoomEventListener = memo(RoomEventListenerComponent) as typeof RoomEventListenerComponent;
