import { getStudentDashboardVersion } from 'StudentDashboard/getStudentDashboadVersion';
import { convertCurrentUserFromIguana } from 'Users';
import angularModule from '../lessons_module';

// All this directive does is to hide the old, angular student-dashboard if we are configured to use the new, react, one.
angularModule.directive('studentDashboardV1', [
    '$injector',

    function factory($injector) {
        return {
            template: '<student-dashboard ng-if="showV1"></student-dashboard>',

            link(scope) {
                const $rootScope = $injector.get('$rootScope');
                const ConfigFactory = $injector.get('ConfigFactory');

                function setShowV1() {
                    const targetVersion = getStudentDashboardVersion(
                        convertCurrentUserFromIguana(scope.currentUser),
                        scope.config,
                    );
                    scope.showV1 = targetVersion === 1;
                }

                scope.$watch(
                    () => $rootScope.currentUser,
                    currentUser => {
                        scope.currentUser = currentUser;
                    },
                );

                ConfigFactory.getConfig().then(config => {
                    scope.config = config;
                });

                scope.$watchGroup(['currentUser.student_dashboard_version_override', 'config'], setShowV1);
            },
        };
    },
]);
