
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.tuition_and_registration = window.Smartly.locales.modules.en.back_royal.settings.tuition_and_registration || {};
window.Smartly.locales.modules.en.back_royal.settings.tuition_and_registration = {...window.Smartly.locales.modules.en.back_royal.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "Allow classmates to see my profile",
    "notify_nominator": "Let {{nominatorName}} know the good news!",
    "header_choose_your_tuition_plan": "Choose Your Tuition Plan",
    "header_register": "Register for the {{programTitle}}",
    "narrative_all_paid": "You have paid your tuition in full. Thank you!",
    "new_browser_register": "To register, you will need to use your system web browser.",
    "new_browser_payment_details": "To modify your payment details, you will need to use your system web browser.",
    "confirm_message": "To confirm your place in the class, please register by <strong>{{registrationDeadline}}</strong>.",
    "confirm_message_past_deadline": "To confirm your place in the class, please register now.",

                     
    "narrative_no_scholarship": "Welcome to the program! Please review the payment options and register below.",
    "graphic_no_scholarship_title": "<b>Congratulations</b>",
    "graphic_no_scholarship_subtitle": "Register to enroll",

                             
    "narrative_partial_scholarship": "You have been awarded the following {num, plural, =1{scholarship} other{scholarships}}, reducing your total tuition. Congratulations!",
    "graphic_partial_scholarship_title": "<strong>${{totalScholarship}}</strong> USD",
    "graphic_partial_scholarship_subtitle": "Total {num, plural, =1{Scholarship} other{Scholarships}} Awarded",

                       
    "narrative_full_scholarship": "You have been awarded a full scholarship to the program. Congratulations on this achievement!",
    "graphic_full_scholarship_title": "<strong>${{totalScholarship}}</strong> USD",
    "graphic_full_scholarship_subtitle": "Total Scholarship Awarded",

    "register": "Register",
    "review_and_register": "Review &amp; Register",
    "contact_bursar": "Contact Bursar",
    "email_our_bursar": "Email our Bursar for instructions on how to register and pay by wire transfer.",
    "launch_browser": "Launch Web Browser",
    "registered_contact_support": "Please contact <a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a> for account information and payment receipts.",
    "registered_manage_billing": "Manage Billing",
    "registered_manage_billing_payment_trouble": "Fix Payment Details",
    "registered_manage_billing_view_payment_history": "View Payment History",
    "registered_processing_payment": "We are processing your payment.",
    "registered_payment_smallprint_recurring": "Your next payment of <strong>${{paymentPerInterval}}</strong> is scheduled for <strong>{{nextPaymentDate}}</strong>.",
    "registered_payment_smallprint_once": "You will be charged <strong>${{paymentPerInterval}}</strong> once during the course of study.",
    "registered_payment_smallprint_with_fee_recurring": "Your next payment of <strong>${{paymentPerInterval}}</strong> is scheduled for <strong>{{nextPaymentDate}}</strong>. Additional fee may apply to card transactions.",
    "registered_payment_smallprint_with_fee_once": "You will be charged <strong>${{paymentPerInterval}}</strong> once during the course of study. Additional fee may apply to card transactions.",
    "stripe_name": "{{brandName}} {{fullTitle}}",
    "stripe_description_monthly_tuition": "Monthly Tuition Plan",
    "stripe_description_annual_tuition": "Annual Tuition Plan",
    "stripe_description_once_tuition": "Single Payment",
    "smallprint": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "smallprint_no_reapplication_date": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "smallprint_scholarship": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission and scholarship by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "smallprint_scholarship_no_reapplication_date": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission and scholarship by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "stripe_panel_label_pay_tuition": "Pay Tuition – ",
    "processing": "Processing ...",
    "scholarship_award": "Scholarship Award",
    "scholarship_award_with_label": "{{scholarshipLabel}} Scholarship Award",
    "trouble_processing_payment": "We're having trouble processing your payment.",
    "please_re_enter_payment_details": "Please re-enter the payment details to resolve the issue.",
    "action_required": "Action Required",
    "confirm_payment": "We've sent you an email to confirm your recent ${{paymentPerInterval}} USD payment.",
    "additional_steps_required": "To protect you from fraud, your bank requires an additional step to ensure online transactions are authorized. Please confirm your payment to Quantic to complete your registration.",

    "currently_enrolled_in_x_billed_at_x_once": "",
    "currently_enrolled_in_x_billed_at_x_monthly_during": "You are currently enrolled in the Monthly Tuition Plan billed at <b>${{paymentPerInterval}} USD per month</b>.",
    "currently_enrolled_in_x_billed_at_x_monthly": "You are currently enrolled in the Monthly Tuition Plan billed at <b>${{paymentPerInterval}} USD per month</b>.",
    "currently_enrolled_in_x_billed_at_x_annual_during": "You are currently enrolled in the Annual Tuition Plan billed <b>twice at ${{paymentPerInterval}} USD</b> during the course of study.",
    "currently_enrolled_in_x_billed_at_x_annual": "You are currently enrolled in the Annual Tuition Plan billed <b>twice at ${{paymentPerInterval}} USD</b>.",
    "paid_x_of_x": "You have paid ${{netAmountPaid}} out of ${{netRequiredPayment}} USD.",

                
    "confirm_message_before_deadline": "Registration opens on<br class=\"visible-xs\" /> <strong>{{registrationOpen}}</strong>.",
    "registration_deadline": "The deadline to register is {{registrationDeadline}}. Class starts on {{classStartDate}}.",
    "registration_deadline_no_start_date": "The deadline to register is {{registrationDeadline}}.",
    "registration_deadline_mobile": "Registration Deadline: {{registrationDeadline}}.<div style=\"padding-top: 7px;\">Class starts on {{classStartDate}}.</div>",
    "registration_deadline_mobile_no_start_date": "Registration Deadline: {{registrationDeadline}}.",
    "prefer_later_start_date": "Register and contact <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> if you prefer the {{upcomingClassStartDate}} start date.",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;\">Register and email us if you prefer the<br /> {{upcomingClassStartDate}} start date.</div>",

                           
    "choose_start_date": "Choose your class start date:",
    "choose_either_cohort": "As an Early Admission benefit,<br /> you may choose either cohort.",

                        
    "choose_your_plan": "Choose your tuition plan:",
    "available_plans": "Available Tuition Plans:",
    "single_payment_plan_desc": "${{paymentPerInterval}} USD billed once",
    "annual_plan_desc": "${{paymentPerInterval}} USD billed twice",
    "monthly_plan_desc": "${{paymentPerInterval}} USD per month",

                              
    "plan_description_once": "Includes ${{additionalSavings}} additional savings.",
    "plan_description_annual": "Includes ${{additionalSavings}} additional savings.",

                                 
      
    "plan_description_monthly": "Most convenient billing option.",

                               
    "plan_description_once_partial_scholarship": "Reduced from ${{paymentPerIntervalForFullTuition}}. Includes ${{additionalSavings}} additional savings.",
    "plan_description_annual_partial_scholarship": "Reduced from ${{paymentPerIntervalForFullTuition}}. Includes ${{additionalSavings}} additional savings.",

                              
      
    "plan_description_monthly_partial_scholarship": "Reduced from ${{paymentPerIntervalForFullTuition}} per month.",

                                
    "explain_the_options": "explain the options",
    "choose_tuition": "Choose the tuition option that is right for you",
    "payment_options": "Payment Options",
    "option_one": "Option 1",
    "option_two": "Option 2",
    "option_three": "Option 3",
    "monthly_option": "Monthly Option",
    "annual_option": "2-Pay Option",
    "upfront_option": "1-Pay Option",
    "make_single_payment": "Make a single upfront payment and save ${{additionalSavings}} off your total tuition.",
    "make_single_payment_partial_scholarship": "Make a single upfront payment and save ${{additionalSavings}} off your total tuition.",
    "make_two_payments": "Pay half now and half one year later and save ${{additionalSavings}} off your total tuition. Payments in 2 calendar years can help students maximize tuition benefits from their company.",
    "make_two_payments_partial_scholarship": "Pay half now and half one year later and save ${{additionalSavings}} off your total tuition. Payments in 2 calendar years can help students maximize tuition benefits from their company.",
                                                                                                                          
                                                     
      
    "make_monthly_payments": "Make {{numIntervals}} convenient monthly payments over the duration of the program.",

    "refund_policy": "Our generous <a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">refund policy</a> includes a full refund of any payments prior to the start of classes.",

                            
    "which_section_is_right": "Which Section is Right for You?",
    "two_different_cohort_sections": "You live in a region where we offer two different cohort sections.",
    "global_section_explanation": "Our <b>Global Section</b> has students from over 30 countries, with more international perspectives in group projects and exercises. Over half the class lives in North America and Western Europe.",
    "regional_section_explanation": "Our <b>{{regionalSectionName}}</b> has only students from this region, enabling greater local networking while delivering lower tuition cost through corporate sponsorship and increased scholarship awards.",
    "both_sections_same_explanation": "Both sections use the same curriculum materials, projects, and case studies, while earning the same Quantic degree. Both have access to our optional in-person events as well as the full global alumni network.",

                     
    "deferring_admission": "Deferring Admission",
    "choose_different_start_date": "If you are unable to join the current cohort that begins on {{startDate}}, you can preserve your positive admission by registering now and choosing one of the following cohort start dates:",
    "choose_different_start_date_scholarship": "If you are unable to join the current cohort that begins on {{startDate}}, you can preserve your positive admission and scholarship award by registering now and choosing one of the following cohort start dates:",
    "contact_us_with_preferred_start_date": "Once you have registered, simply reach out to us with your preferred date at {{admissionsEmail}}.",
    "reapply_at_different_time": "If you are unable to register, you may re-apply in {{reapplicationMonthYear}}. We are unable to guarantee future admission in this case.",
    "reapply_at_different_time_scholarship": "If you are unable to register, you may re-apply in {{reapplicationMonthYear}}. We are unable to guarantee future admission or the same scholarship in this case.",
    "reapply_at_different_time_no_date": "If you are unable to register, you may re-apply at a later time. We are unable to guarantee future admission in this case.",
    "reapply_at_different_time_scholarship_no_date": "If you are unable to register, you may re-apply at a later time. We are unable to guarantee future admission or the same scholarship in this case.",

                         
    "wiring_funds": "Wiring Funds",
    "bank_details_intro": "Provide your bank or wire transfer service the following details if choosing the 1-pay or 2-pay options. We do not accept wires for the monthly billing option.",
    "bank_details_intro_1_pay_only": "Provide your bank or wire transfer service with the following details:",
    "our_bank_heading": "Our Bank",
    "our_account_name_heading": "Our Account Name",
    "our_account_number_heading": "Our Account Number",
    "for_us_wires_heading": "For US Wires",
    "for_us_wires_content": "Routing &amp; Transit number: {{transitNumber}}",
    "for_international_wires_heading": "For International Wires",
    "quantic_mailing_address_heading": "{{institutionName}} Mailing Address",
    "identifier": "Identifier: Full name of student and sender's name and address",
    "contact_billing_with_questions": "After initiating payment, contact {{billingEmail}} to confirm your registration.",

                                             
    "choose_your_section": "Choose your cohort section to review scholarship and tuition options.",
    "whats_the_difference": "What's the difference?",
    "global_section": "Global Section",
    "regional_section": "S / SE Asia Section",
    "global_section_subtext": "Study and collaborate with students from over 30 countries.",
    "regional_section_subtext": "Study and collaborate with regional classmates only. Greater scholarship reduces tuition.",
    "regional_section_subtext_full_scholarship": "Study and collaborate with regional classmates only.",

    "tuition_details": "Tuition Details",
    "tuition_and_scholarship_details": "Tuition & Scholarship Details",

                             
    "base_tuition_no_scholarship": "{{programTitle}} Tuition",
    "base_tuition": "Base {{programTitle}} Tuition",
    "base_tuition_no_program_title": "Base Tuition",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} USD</span>",
    "discount_of_ledger_item": "– $ <span class=\"value\">{{value}} USD</span>",
    "total_amount": "(for {{numIntervals}} months = ${{totalAmount}} USD)",
    "total_tuition": "Total Tuition",
    "full_scholarship": "(Full Scholarship)",

                            
    "select_payment_method": "Select Payment Method",
    "continue_to_register": "Continue to Register",
    "back_to_plan_selection": "Back to Plan Selection",
    "back": "Back",
    "button_view_wire_transfer_instructions": "View Instructions",
    "us_bank_account": "US Bank Account",
    "card": "Card",
    "buy_now_pay_later": "Pay Later",
    "wire_transfer": "Wire Transfer",
    "transaction_fee": "{{creditCardPaymentFeeRate}}% transaction fee",
    "smallprint_transaction_fee": "Fee not applicable for debit cards or prepaid cards. Payments using debit/prepaid cards will have the fee refunded immediately after checkout.",
    "selected_tuition_plan_once": "Selected Tuition Plan: ${{paymentAmountPerInterval}} USD charged once",
    "selected_tuition_plan_annual": "Selected Tuition Plan: ${{paymentAmountPerInterval}} USD charged twice",
    "selected_tuition_plan_monthly": "Selected Tuition Plan: ${{paymentAmountPerInterval}} USD per month",
    "tuition_plan_detail_once": "${{netDiscountAmount}} savings for a single upfront payment.<br />(lowest tuition option)",
    "tuition_plan_detail_annual": "${{netDiscountAmount}} savings for 2 payments, one year apart.<br />(more flexible option)",
    "tuition_plan_detail_monthly": "{{numIntervals}} payments over the course of the program.<br />(most convenient option)",

                           
    "upfront_and_save": "Pay upfront and <span class=\"green-text\">save an additional ${{netDiscountAmount}}<span/>",
    "twice_and_save": "Pay twice and <span class=\"green-text\">save an additional ${{netDiscountAmount}}<span/>",
    "pay_monthly": "Pay monthly for your convenience",
    "tuition_plan_detail_b_once": "Reduces total tuition from ${{baseTuition}} to ${{paymentPerInterval}} USD <br />including scholarships and plan discount",
    "tuition_plan_detail_b_annual": "${{paymentPerInterval}} USD billed twice, a year apart",
    "tuition_plan_detail_b_monthly": "${{paymentPerInterval}} USD per month for {{numIntervals}} months",
    "student_preferred_plan": "Student preferred plan"
}
}
    